* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    -webkit-tap-highlight-color: transparent;
    position: relative;
    min-height: 100%;
}

body {
    font-weight: $fw-normal;
    font-family: $main-font;
    font-size: $fz-normal;
    line-height: 1.75rem;
    color: $gray-dark;
    background: $gray-background;
}

input, button, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}



/* Change Autocomplete styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill {
    border-top: none;
    -webkit-text-fill-color: inherit;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}
input[type=text]::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-style: normal;
}
input[type=text]::-moz-placeholder { /* Firefox 19+ */
    font-style: normal;
}
input[type=text]:-ms-input-placeholder { /* IE 10+ */
    font-style: normal;
}
input[type=text]:-moz-placeholder { /* Firefox 18- */
    font-style: normal;
}


@media (min-width: 1100px) {
    .help-popup .modal-dialog {
        width: 740px;
    }
}

figure {
    margin: 0;
}

img {
    vertical-align: middle;
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

.img-rounded {
    border-radius: 6px;
}

.img-thumbnail {
    padding: 4px;
    line-height: 1.42857;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    max-width: 100%;
    height: auto;
}

.img-circle {
    border-radius: 50%;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eeeeee;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
}

[role="button"] {
    cursor: pointer;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center, .existing-appointments .icon-circle {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-nowrap {
    white-space: nowrap;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase, .initialism {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-muted {
    color: #777777;
}

.text-primary {
    color: #337ab7;
}

a.text-primary:hover, a.text-primary:focus {
    color: #286090;
}

.text-success {
    color: #3c763d;
}

a.text-success:hover, a.text-success:focus {
    color: #2b542c;
}

.text-info {
    color: #31708f;
}

a.text-info:hover, a.text-info:focus {
    color: #245269;
}

.text-warning {
    color: #8a6d3b;
}

a.text-warning:hover, a.text-warning:focus {
    color: #66512c;
}

.text-danger {
    color: #E73323;
}

.text-grey {
    color: #CACED2;
}

a.text-danger:hover, a.text-danger:focus {
    color: #843534;
}

.bg-primary {
    color: #fff;
}

.bg-primary {
    background-color: #1774cc;
}

a.bg-primary:hover, a.bg-primary:focus {
    background-color: #3a89d4;
}

.bg-success {
    background-color: #dff0d8;
}

a.bg-success:hover, a.bg-success:focus {
    background-color: #c1e2b3;
}

.bg-info {
    background-color: #d9edf7;
}

a.bg-info:hover, a.bg-info:focus {
    background-color: #afd9ee;
}

.bg-warning {
    background-color: #fcf8e3;
}

a.bg-warning:hover, a.bg-warning:focus {
    background-color: #f7ecb5;
}

.bg-danger {
    background-color: #f2dede;
}

a.bg-danger:hover, a.bg-danger:focus {
    background-color: #e4b9b9;
}


.page-header {
    padding-bottom: 9px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #eeeeee;
}

ul, ol {
    margin-top: 0;
    margin-bottom: 10px;
}

ul ul, ul ol, ol ul, ol ol {
    margin-bottom: 0;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
    margin-left: -5px;
}

.list-inline>li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

dl {
    margin-top: 0;
    margin-bottom: 20px;
}

dt, dd {
    line-height: 1.42857;
}

dt {
    font-weight: bold;
}

dd {
    margin-left: 0;
}

.dl-horizontal dd:before, .dl-horizontal dd:after {
    content: " ";
    display: table;
}

.dl-horizontal dd:after {
    clear: both;
}

@media (min-width: 768px) {
    .dl-horizontal dt {
        float: left;
        width: 160px;
        clear: left;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .dl-horizontal dd {
        margin-left: 180px;
    }
}

abbr[title], abbr[data-original-title] {
    cursor: help;
    border-bottom: 1px dotted #777777;
}

.initialism {
    font-size: 90%;
}

blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #eeeeee;
}

blockquote p:last-child, blockquote ul:last-child, blockquote ol:last-child {
    margin-bottom: 0;
}

blockquote footer, blockquote small, blockquote .small {
    display: block;
    font-size: 80%;
    line-height: 1.42857;
    color: #777777;
}

blockquote footer:before, blockquote small:before, blockquote .small:before {
    content: '\2014 \00A0';
}

.blockquote-reverse, blockquote.pull-right {
    padding-right: 15px;
    padding-left: 0;
    border-right: 5px solid #eeeeee;
    border-left: 0;
    text-align: right;
}

.blockquote-reverse footer:before, .blockquote-reverse small:before, .blockquote-reverse .small:before, blockquote.pull-right footer:before, blockquote.pull-right small:before, blockquote.pull-right .small:before {
    content: '';
}

.blockquote-reverse footer:after, .blockquote-reverse small:after, .blockquote-reverse .small:after, blockquote.pull-right footer:after, blockquote.pull-right small:after, blockquote.pull-right .small:after {
    content: '\00A0 \2014';
}

address {
    margin-bottom: 20px;
    font-style: normal;
    line-height: 1.42857;
}

code, kbd, pre, samp {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

code {
    padding: 2px 4px;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 4px;
}

kbd {
    padding: 2px 4px;
    font-size: 90%;
    color: #fff;
    background-color: #333;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold;
    box-shadow: none;
}

pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857;
    word-break: break-all;
    word-wrap: break-word;
    color: #333333;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
}

pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0;
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}



table {
    background-color: transparent;
}

caption {
    padding-top: 8px;
    padding-bottom: 8px;
    color: #777777;
    text-align: left;
}

th {
    text-align: left;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.table>thead>tr>th, .table>thead>tr>td, .table>tbody>tr>th, .table>tbody>tr>td, .table>tfoot>tr>th, .table>tfoot>tr>td {
    padding: 8px;
    line-height: 1.42857;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
}

.table>caption+thead>tr:first-child>th, .table>caption+thead>tr:first-child>td, .table>colgroup+thead>tr:first-child>th, .table>colgroup+thead>tr:first-child>td, .table>thead:first-child>tr:first-child>th, .table>thead:first-child>tr:first-child>td {
    border-top: 0;
}

.table>tbody+tbody {
    border-top: 2px solid #ddd;
}

.table .table {
    background-color: #fff;
}

.table-condensed>thead>tr>th, .table-condensed>thead>tr>td, .table-condensed>tbody>tr>th, .table-condensed>tbody>tr>td, .table-condensed>tfoot>tr>th, .table-condensed>tfoot>tr>td {
    padding: 5px;
}

.table-bordered {
    border: 1px solid #ddd;
}

.table-bordered>thead>tr>th, .table-bordered>thead>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tbody>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>tfoot>tr>td {
    border: 1px solid #ddd;
}

.table-bordered>thead>tr>th, .table-bordered>thead>tr>td {
    border-bottom-width: 2px;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}

.table-hover>tbody>tr:hover {
    background-color: #f5f5f5;
}

table col[class*="col-"] {
    position: static;
    float: none;
    display: table-column;
}

table td[class*="col-"], table th[class*="col-"] {
    position: static;
    float: none;
    display: table-cell;
}

.table>thead>tr>td.active, .table>thead>tr>th.active, .table>thead>tr.active>td, .table>thead>tr.active>th, .table>tbody>tr>td.active, .table>tbody>tr>th.active, .table>tbody>tr.active>td, .table>tbody>tr.active>th, .table>tfoot>tr>td.active, .table>tfoot>tr>th.active, .table>tfoot>tr.active>td, .table>tfoot>tr.active>th {
    background-color: #f5f5f5;
}

.table-hover>tbody>tr>td.active:hover, .table-hover>tbody>tr>th.active:hover, .table-hover>tbody>tr.active:hover>td, .table-hover>tbody>tr:hover>.active, .table-hover>tbody>tr.active:hover>th {
    background-color: #e8e8e8;
}

.table>thead>tr>td.success, .table>thead>tr>th.success, .table>thead>tr.success>td, .table>thead>tr.success>th, .table>tbody>tr>td.success, .table>tbody>tr>th.success, .table>tbody>tr.success>td, .table>tbody>tr.success>th, .table>tfoot>tr>td.success, .table>tfoot>tr>th.success, .table>tfoot>tr.success>td, .table>tfoot>tr.success>th {
    background-color: #dff0d8;
}

.table-hover>tbody>tr>td.success:hover, .table-hover>tbody>tr>th.success:hover, .table-hover>tbody>tr.success:hover>td, .table-hover>tbody>tr:hover>.success, .table-hover>tbody>tr.success:hover>th {
    background-color: #d0e9c6;
}

.table>thead>tr>td.info, .table>thead>tr>th.info, .table>thead>tr.info>td, .table>thead>tr.info>th, .table>tbody>tr>td.info, .table>tbody>tr>th.info, .table>tbody>tr.info>td, .table>tbody>tr.info>th, .table>tfoot>tr>td.info, .table>tfoot>tr>th.info, .table>tfoot>tr.info>td, .table>tfoot>tr.info>th {
    background-color: #d9edf7;
}

.table-hover>tbody>tr>td.info:hover, .table-hover>tbody>tr>th.info:hover, .table-hover>tbody>tr.info:hover>td, .table-hover>tbody>tr:hover>.info, .table-hover>tbody>tr.info:hover>th {
    background-color: #c4e3f3;
}

.table>thead>tr>td.warning, .table>thead>tr>th.warning, .table>thead>tr.warning>td, .table>thead>tr.warning>th, .table>tbody>tr>td.warning, .table>tbody>tr>th.warning, .table>tbody>tr.warning>td, .table>tbody>tr.warning>th, .table>tfoot>tr>td.warning, .table>tfoot>tr>th.warning, .table>tfoot>tr.warning>td, .table>tfoot>tr.warning>th {
    background-color: #fcf8e3;
}

.table-hover>tbody>tr>td.warning:hover, .table-hover>tbody>tr>th.warning:hover, .table-hover>tbody>tr.warning:hover>td, .table-hover>tbody>tr:hover>.warning, .table-hover>tbody>tr.warning:hover>th {
    background-color: #faf2cc;
}

.table>thead>tr>td.danger, .table>thead>tr>th.danger, .table>thead>tr.danger>td, .table>thead>tr.danger>th, .table>tbody>tr>td.danger, .table>tbody>tr>th.danger, .table>tbody>tr.danger>td, .table>tbody>tr.danger>th, .table>tfoot>tr>td.danger, .table>tfoot>tr>th.danger, .table>tfoot>tr.danger>td, .table>tfoot>tr.danger>th {
    background-color: #f2dede;
}

.table-hover>tbody>tr>td.danger:hover, .table-hover>tbody>tr>th.danger:hover, .table-hover>tbody>tr.danger:hover>td, .table-hover>tbody>tr:hover>.danger, .table-hover>tbody>tr.danger:hover>th {
    background-color: #ebcccc;
}

.table-responsive {
    overflow-x: auto;
    min-height: 0.01%;
}

@media screen and (max-width: 767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #ddd;
    }
    .left-pad-50.schdl-apnt-btn {
        padding-left: 0;
    }
    .pagination>li.page-navigation {
        display: block;
        clear: both;
    }
    .ma-vh-pagination {
        text-align: center;
    }
    .ma-vh-pagination.pagination>li {
        display: inline-block;
    }
    .table-responsive>.table {
        margin-bottom: 0;
    }
    .table-responsive>.table>thead>tr>th, .table-responsive>.table>thead>tr>td, .table-responsive>.table>tbody>tr>th, .table-responsive>.table>tbody>tr>td, .table-responsive>.table>tfoot>tr>th, .table-responsive>.table>tfoot>tr>td {
        white-space: nowrap;
    }
    .table-responsive>.table-bordered {
        border: 0;
    }
    .table-responsive>.table-bordered>thead>tr>th:first-child, .table-responsive>.table-bordered>thead>tr>td:first-child, .table-responsive>.table-bordered>tbody>tr>th:first-child, .table-responsive>.table-bordered>tbody>tr>td:first-child, .table-responsive>.table-bordered>tfoot>tr>th:first-child, .table-responsive>.table-bordered>tfoot>tr>td:first-child {
        border-left: 0;
    }
    .table-responsive>.table-bordered>thead>tr>th:last-child, .table-responsive>.table-bordered>thead>tr>td:last-child, .table-responsive>.table-bordered>tbody>tr>th:last-child, .table-responsive>.table-bordered>tbody>tr>td:last-child, .table-responsive>.table-bordered>tfoot>tr>th:last-child, .table-responsive>.table-bordered>tfoot>tr>td:last-child {
        border-right: 0;
    }
    .table-responsive>.table-bordered>tbody>tr:last-child>th, .table-responsive>.table-bordered>tbody>tr:last-child>td, .table-responsive>.table-bordered>tfoot>tr:last-child>th, .table-responsive>.table-bordered>tfoot>tr:last-child>td {
        border-bottom: 0;
    }
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: inherit;
    color: #333333;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="search"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input[type="radio"], input[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    line-height: normal;
}

input[type="file"] {
    display: block;
}

input[type="range"] {
    display: block;
    width: 100%;
}

select[multiple], select[size] {
    height: auto;
}

input[type="file"]:focus, input[type="radio"]:focus, input[type="checkbox"]:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

output {
    display: block;
    padding-top: 7px;
    font-size: 14px;
    line-height: 1.42857;
    color: #555555;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-moz-placeholder {
    color: #999;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #999;
}

.form-control::-webkit-input-placeholder {
    color: #999;
}

.form-control::-ms-expand {
    border: 0;
    background-color: transparent;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: #eeeeee;
    opacity: 1;
}

.form-control[disabled], fieldset[disabled] .form-control {
    cursor: not-allowed;
}

textarea.form-control {
    height: 40px;
}

input[type="search"] {
    -webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control {
        line-height: 34px;
    }
    input[type="date"].input-sm, .input-group-sm>input[type="date"].form-control, .input-group-sm>input[type="date"].input-group-addon, .input-group-sm>.input-group-btn>input[type="date"].btn, .input-group-sm input[type="date"], input[type="time"].input-sm, .input-group-sm>input[type="time"].form-control, .input-group-sm>input[type="time"].input-group-addon, .input-group-sm>.input-group-btn>input[type="time"].btn, .input-group-sm input[type="time"], input[type="datetime-local"].input-sm, .input-group-sm>input[type="datetime-local"].form-control, .input-group-sm>input[type="datetime-local"].input-group-addon, .input-group-sm>.input-group-btn>input[type="datetime-local"].btn, .input-group-sm input[type="datetime-local"], input[type="month"].input-sm, .input-group-sm>input[type="month"].form-control, .input-group-sm>input[type="month"].input-group-addon, .input-group-sm>.input-group-btn>input[type="month"].btn, .input-group-sm input[type="month"] {
        line-height: 30px;
    }
    input[type="date"].input-lg, .input-group-lg>input[type="date"].form-control, .input-group-lg>input[type="date"].input-group-addon, .input-group-lg>.input-group-btn>input[type="date"].btn, .input-group-lg input[type="date"], input[type="time"].input-lg, .input-group-lg>input[type="time"].form-control, .input-group-lg>input[type="time"].input-group-addon, .input-group-lg>.input-group-btn>input[type="time"].btn, .input-group-lg input[type="time"], input[type="datetime-local"].input-lg, .input-group-lg>input[type="datetime-local"].form-control, .input-group-lg>input[type="datetime-local"].input-group-addon, .input-group-lg>.input-group-btn>input[type="datetime-local"].btn, .input-group-lg input[type="datetime-local"], input[type="month"].input-lg, .input-group-lg>input[type="month"].form-control, .input-group-lg>input[type="month"].input-group-addon, .input-group-lg>.input-group-btn>input[type="month"].btn, .input-group-lg input[type="month"] {
        line-height: 46px;
    }
}

.form-group {
    margin-bottom: 15px;
}

.radio, .checkbox {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}

.radio label, .checkbox label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
}

.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
    position: absolute;
    margin-left: -20px;
    margin-top: 4px \9;
}

.radio+.radio, .checkbox+.checkbox {
    margin-top: -5px;
}

.radio-inline, .checkbox-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: normal;
    cursor: pointer;
}

.radio-inline+.radio-inline, .checkbox-inline+.checkbox-inline {
    margin-top: 0;
    margin-left: 10px;
}

input[type="radio"][disabled], input[type="radio"].disabled, fieldset[disabled] input[type="radio"], input[type="checkbox"][disabled], input[type="checkbox"].disabled, fieldset[disabled] input[type="checkbox"] {
    cursor: not-allowed;
}

.radio-inline.disabled, fieldset[disabled] .radio-inline, .checkbox-inline.disabled, fieldset[disabled] .checkbox-inline {
    cursor: not-allowed;
}

.radio.disabled label, fieldset[disabled] .radio label, .checkbox.disabled label, fieldset[disabled] .checkbox label {
    cursor: not-allowed;
}

.form-control-static {
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 0;
    min-height: 34px;
}

.form-control-static.input-lg, .input-group-lg>.form-control-static.form-control, .input-group-lg>.form-control-static.input-group-addon, .input-group-lg>.input-group-btn>.form-control-static.btn, .form-control-static.input-sm, .input-group-sm>.form-control-static.form-control, .input-group-sm>.form-control-static.input-group-addon, .input-group-sm>.input-group-btn>.form-control-static.btn {
    padding-left: 0;
    padding-right: 0;
}

.input-sm, .input-group-sm>.form-control, .input-group-sm>.input-group-addon, .input-group-sm>.input-group-btn>.btn {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

select.input-sm, .input-group-sm>select.form-control, .input-group-sm>select.input-group-addon, .input-group-sm>.input-group-btn>select.btn {
    height: 30px;
    line-height: 30px;
}

textarea.input-sm, .input-group-sm>textarea.form-control, .input-group-sm>textarea.input-group-addon, .input-group-sm>.input-group-btn>textarea.btn, select[multiple].input-sm, .input-group-sm>select[multiple].form-control, .input-group-sm>select[multiple].input-group-addon, .input-group-sm>.input-group-btn>select[multiple].btn {
    height: auto;
}

.form-group-sm .form-control {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.form-group-sm select.form-control {
    height: 30px;
    line-height: 30px;
}

.form-group-sm textarea.form-control, .form-group-sm select[multiple].form-control {
    height: auto;
}

.form-group-sm .form-control-static {
    height: 30px;
    min-height: 32px;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.input-lg, .input-group-lg>.form-control, .input-group-lg>.input-group-addon, .input-group-lg>.input-group-btn>.btn {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33333;
    border-radius: 6px;
}

select.input-lg, .input-group-lg>select.form-control, .input-group-lg>select.input-group-addon, .input-group-lg>.input-group-btn>select.btn {
    height: 46px;
    line-height: 46px;
}

textarea.input-lg, .input-group-lg>textarea.form-control, .input-group-lg>textarea.input-group-addon, .input-group-lg>.input-group-btn>textarea.btn, select[multiple].input-lg, .input-group-lg>select[multiple].form-control, .input-group-lg>select[multiple].input-group-addon, .input-group-lg>.input-group-btn>select[multiple].btn {
    height: auto;
}

.form-group-lg .form-control {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33333;
    border-radius: 6px;
}

.form-group-lg select.form-control {
    height: 46px;
    line-height: 46px;
}

.form-group-lg textarea.form-control, .form-group-lg select[multiple].form-control {
    height: auto;
}

.form-group-lg .form-control-static {
    height: 46px;
    min-height: 38px;
    padding: 11px 16px;
    font-size: 18px;
    line-height: 1.33333;
}

.has-feedback {
    position: relative;
}

.has-feedback .form-control {
    padding-right: 42.5px;
}

.form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none;
}

.input-lg+.form-control-feedback, .input-group-lg>.form-control+.form-control-feedback, .input-group-lg>.input-group-addon+.form-control-feedback, .input-group-lg>.input-group-btn>.btn+.form-control-feedback, .input-group-lg+.form-control-feedback, .form-group-lg .form-control+.form-control-feedback {
    width: 46px;
    height: 46px;
    line-height: 46px;
}

.input-sm+.form-control-feedback, .input-group-sm>.form-control+.form-control-feedback, .input-group-sm>.input-group-addon+.form-control-feedback, .input-group-sm>.input-group-btn>.btn+.form-control-feedback, .input-group-sm+.form-control-feedback, .form-group-sm .form-control+.form-control-feedback {
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.has-success .help-block, .has-success .control-label, .has-success .radio, .has-success .checkbox, .has-success .radio-inline, .has-success .checkbox-inline, .has-success.radio label, .has-success.checkbox label, .has-success.radio-inline label, .has-success.checkbox-inline label {
    color: #3c763d;
}

.has-success .form-control {
    border-color: #3c763d;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-success .form-control:focus {
    border-color: #2b542c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

.has-success .input-group-addon {
    color: #3c763d;
    border-color: #3c763d;
    background-color: #dff0d8;
}

.has-success .form-control-feedback {
    color: #3c763d;
}

.has-warning .help-block, .has-warning .control-label, .has-warning .radio, .has-warning .checkbox, .has-warning .radio-inline, .has-warning .checkbox-inline, .has-warning.radio label, .has-warning.checkbox label, .has-warning.radio-inline label, .has-warning.checkbox-inline label {
    color: #8a6d3b;
}

.has-warning .form-control {
    border-color: #8a6d3b;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-warning .form-control:focus {
    border-color: #66512c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}

.has-warning .input-group-addon {
    color: #8a6d3b;
    border-color: #8a6d3b;
    background-color: #fcf8e3;
}

.has-warning .form-control-feedback {
    color: #8a6d3b;
}

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
    color: #a94442;
}

.has-error .form-control {
    border-color: #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-error .form-control:focus {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.has-error .input-group-addon {
    color: #a94442;
    border-color: #a94442;
    background-color: #f2dede;
}

.has-error .form-control-feedback {
    color: #a94442;
}

.has-feedback label~.form-control-feedback {
    top: 25px;
}

.has-feedback label.sr-only~.form-control-feedback {
    top: 0;
}

.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373;
}

@media (min-width: 768px) {
    .form-inline .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
    }
    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
    .form-inline .form-control-static {
        display: inline-block;
    }
    .form-inline .input-group {
        display: inline-table;
        vertical-align: middle;
    }
    .form-inline .input-group .input-group-addon, .form-inline .input-group .input-group-btn, .form-inline .input-group .form-control {
        width: auto;
    }
    .form-inline .input-group>.form-control {
        width: 100%;
    }
    .form-inline .control-label {
        margin-bottom: 0;
        vertical-align: middle;
    }
    .form-inline .radio, .form-inline .checkbox {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: middle;
    }
    .form-inline .radio label, .form-inline .checkbox label {
        padding-left: 0;
    }
    .form-inline .radio input[type="radio"], .form-inline .checkbox input[type="checkbox"] {
        position: relative;
        margin-left: 0;
    }
    .form-inline .has-feedback .form-control-feedback {
        top: 0;
    }
    .left-pad-50.schdl-apnt-btn {
        padding-left: 0;
    }
}

.form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 7px;
}

.form-horizontal .radio, .form-horizontal .checkbox {
    min-height: 27px;
}

.form-horizontal .form-group {
    margin-left: -15px;
    margin-right: -15px;
}

.form-horizontal .form-group:before, .form-horizontal .form-group:after {
    content: " ";
    display: table;
}

.form-horizontal .form-group:after {
    clear: both;
}

@media (min-width: 768px) {
    .form-horizontal .control-label {
        text-align: right;
        margin-bottom: 0;
        padding-top: 7px;
    }
}

.form-horizontal .has-feedback .form-control-feedback {
    right: 15px;
}

@media (min-width: 768px) {
    .form-horizontal .form-group-lg .control-label {
        padding-top: 11px;
        font-size: 18px;
    }
}

@media (min-width: 768px) {
    .form-horizontal .form-group-sm .control-label {
        padding-top: 6px;
        font-size: 12px;
    }
}

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
}

a.btn.disabled, fieldset[disabled] a.btn {
    pointer-events: none;
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.btn-default:focus, .btn-default.focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c;
}

.btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.btn-default:active, .btn-default.active, .open>.btn-default.dropdown-toggle {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus, .open>.btn-default.dropdown-toggle:hover, .open>.btn-default.dropdown-toggle:focus, .open>.btn-default.dropdown-toggle.focus {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
}

.btn-default:active, .btn-default.active, .open>.btn-default.dropdown-toggle {
    background-image: none;
}

.btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default.focus {
    background-color: #fff;
    border-color: #ccc;
}

.btn-default .badge {
    color: #fff;
    background-color: #333;
}

.btn-primary .badge {
    color: #337ab7;
    background-color: #fff;
}

.btn-white {
    background: #fff;
    color: #333;
    font-size: 16px;
    padding: 12px 50px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
}

.btn-success .badge {
    color: #5cb85c;
    background-color: #fff;
}

.btn-info .badge {
    color: #5bc0de;
    background-color: #fff;
}

.btn-warning .badge {
    color: #f0ad4e;
    background-color: #fff;
}

.btn-danger .badge {
    color: #d9534f;
    background-color: #fff;
}

.btn-link {
    color: #337ab7;
    font-weight: normal;
    border-radius: 0;
}

.btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled], fieldset[disabled] .btn-link {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
    border-color: transparent;
}

.btn-link:hover, .btn-link:focus {
    color: #23527c;
    text-decoration: underline;
    background-color: transparent;
}

.btn-link[disabled]:hover, .btn-link[disabled]:focus, fieldset[disabled] .btn-link:hover, fieldset[disabled] .btn-link:focus {
    color: #777777;
    text-decoration: none;
}

.btn-lg, .btn-group-lg>.btn {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33333;
    border-radius: 6px;
}

.btn-sm, .btn-group-sm>.btn {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.btn-xs, .btn-group-xs>.btn {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block+.btn-block {
    margin-top: 5px;
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
    width: 100%;
}

.fade {
    opacity: 0;
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}

.fade.in {
    opacity: 1;
}

.collapse {
    display: none;
}

.collapse.in {
    display: block;
}

tr.collapse.in {
    display: table-row;
}

tbody.collapse.in {
    display: table-row-group;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility;
    -webkit-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup, .dropdown {
    position: relative;
}

.dropdown-toggle:focus {
    outline: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
}

.dropdown-menu.pull-right {
    right: 0;
    left: auto;
}

.dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

.dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857;
    color: #333333;
    white-space: nowrap;
}

.dropdown-menu>li>a:hover, .dropdown-menu>li>a:focus {
    text-decoration: none;
    color: #262626;
    background-color: #f5f5f5;
}

.dropdown-menu>.active>a, .dropdown-menu>.active>a:hover, .dropdown-menu>.active>a:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    background-color: #337ab7;
}

.dropdown-menu>.disabled>a, .dropdown-menu>.disabled>a:hover, .dropdown-menu>.disabled>a:focus {
    color: #777777;
}

.dropdown-menu>.disabled>a:hover, .dropdown-menu>.disabled>a:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    cursor: not-allowed;
}

.open>.dropdown-menu {
    display: block;
}

.open>a {
    outline: 0;
}

.dropdown-menu-right {
    left: auto;
    right: 0;
}

.dropdown-menu-left {
    left: 0;
    right: auto;
}

.dropdown-header {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.42857;
    color: #777777;
    white-space: nowrap;
}

.dropdown-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 990;
}

.pull-right>.dropdown-menu {
    right: 0;
    left: auto;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
}

.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
}

@media (min-width: 768px) {
    .navbar-right .dropdown-menu {
        right: 0;
        left: auto;
    }
    .navbar-right .dropdown-menu-left {
        left: 0;
        right: auto;
    }
}

.btn-group, .btn-group-vertical {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.btn-group>.btn, .btn-group-vertical>.btn {
    position: relative;
    float: left;
}

.btn-group>.btn:hover, .btn-group>.btn:focus, .btn-group>.btn:active, .btn-group>.btn.active, .btn-group-vertical>.btn:hover, .btn-group-vertical>.btn:focus, .btn-group-vertical>.btn:active, .btn-group-vertical>.btn.active {
    z-index: 2;
}

.btn-group .btn+.btn, .btn-group .btn+.btn-group, .btn-group .btn-group+.btn, .btn-group .btn-group+.btn-group {
    margin-left: -1px;
}

.btn-toolbar {
    margin-left: -5px;
}

.btn-toolbar:before, .btn-toolbar:after {
    content: " ";
    display: table;
}

.btn-toolbar:after {
    clear: both;
}

.btn-toolbar .btn, .btn-toolbar .btn-group, .btn-toolbar .input-group {
    float: left;
}

.btn-toolbar>.btn, .btn-toolbar>.btn-group, .btn-toolbar>.input-group {
    margin-left: 5px;
}

.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0;
}

.btn-group>.btn:first-child {
    margin-left: 0;
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.btn-group>.btn:last-child:not(:first-child), .btn-group>.dropdown-toggle:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.btn-group>.btn-group {
    float: left;
}

.btn-group>.btn-group:not(:first-child):not(:last-child)>.btn {
    border-radius: 0;
}

.btn-group>.btn-group:first-child:not(:last-child)>.btn:last-child, .btn-group>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.btn-group .dropdown-toggle:active, .btn-group.open .dropdown-toggle {
    outline: 0;
}

.btn-group>.btn+.dropdown-toggle {
    padding-left: 8px;
    padding-right: 8px;
}

.btn-group>.btn-lg+.dropdown-toggle, .btn-group-lg.btn-group>.btn+.dropdown-toggle {
    padding-left: 12px;
    padding-right: 12px;
}

.btn-group.open .btn btn-new-visit dropdown-toggle {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-group.open .dropdown-toggle.btn-link {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn .caret {
    margin-left: 0;
}

.btn-lg .caret, .btn-group-lg>.btn .caret {
    border-width: 5px 5px 0;
    border-bottom-width: 0;
}

.dropup .btn-lg .caret, .dropup .btn-group-lg>.btn .caret {
    border-width: 0 5px 5px;
}

.btn-group-vertical>.btn, .btn-group-vertical>.btn-group, .btn-group-vertical>.btn-group>.btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
}

.btn-group-vertical>.btn-group:before, .btn-group-vertical>.btn-group:after {
    content: " ";
    display: table;
}

.btn-group-vertical>.btn-group:after {
    clear: both;
}

.btn-group-vertical>.btn-group>.btn {
    float: none;
}

.btn-group-vertical>.btn+.btn, .btn-group-vertical>.btn+.btn-group, .btn-group-vertical>.btn-group+.btn, .btn-group-vertical>.btn-group+.btn-group {
    margin-top: -1px;
    margin-left: 0;
}

.btn-group-vertical>.btn:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.btn-group-vertical>.btn:first-child:not(:last-child) {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:last-child:not(:first-child) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.btn-group-vertical>.btn-group:not(:first-child):not(:last-child)>.btn {
    border-radius: 0;
}

.btn-group-vertical>.btn-group:first-child:not(:last-child)>.btn:last-child, .btn-group-vertical>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn-group:last-child:not(:first-child)>.btn:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.btn-group-justified {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
}

.btn-group-justified>.btn, .btn-group-justified>.btn-group {
    float: none;
    display: table-cell;
    width: 1%;
}

.btn-group-justified>.btn-group .btn {
    width: 100%;
}

.btn-group-justified>.btn-group .dropdown-menu {
    left: auto;
}

[data-toggle="buttons"]>.btn input[type="radio"], [data-toggle="buttons"]>.btn input[type="checkbox"], [data-toggle="buttons"]>.btn-group>.btn input[type="radio"], [data-toggle="buttons"]>.btn-group>.btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}

.input-group[class*="col-"] {
    float: none;
    padding-left: 0;
    padding-right: 0;
}

.input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
}

.input-group .form-control:focus {
    z-index: 3;
}

.input-group-addon, .input-group-btn, .input-group .form-control {
    display: table-cell !important;
}

.input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child), .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.input-group-addon, .input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
}

.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: #555555;
    text-align: center;
    background-color: #eeeeee;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-group-addon.input-sm, .input-group-sm>.input-group-addon, .input-group-sm>.input-group-btn>.input-group-addon.btn {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px;
}

.input-group-addon.input-lg, .input-group-lg>.input-group-addon, .input-group-lg>.input-group-btn>.input-group-addon.btn {
    padding: 10px 16px;
    font-size: 18px;
    border-radius: 6px;
}

.input-group-addon input[type="radio"], .input-group-addon input[type="checkbox"] {
    margin-top: 0;
}

.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child>.btn, .input-group-btn:first-child>.btn-group>.btn, .input-group-btn:first-child>.dropdown-toggle, .input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle), .input-group-btn:last-child>.btn-group:not(:last-child)>.btn {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.input-group-addon:first-child {
    border-right: 0;
}

.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child>.btn, .input-group-btn:last-child>.btn-group>.btn, .input-group-btn:last-child>.dropdown-toggle, .input-group-btn:first-child>.btn:not(:first-child), .input-group-btn:first-child>.btn-group:not(:first-child)>.btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.input-group-addon:last-child {
    border-left: 0;
}

.input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap;
}

.input-group-btn>.btn {
    position: relative;
}

.input-group-btn>.btn+.btn {
    margin-left: -1px;
}

.input-group-btn>.btn:hover, .input-group-btn>.btn:focus, .input-group-btn>.btn:active {
    z-index: 2;
}

.input-group-btn:first-child>.btn, .input-group-btn:first-child>.btn-group {
    margin-right: -1px;
}

.input-group-btn:last-child>.btn, .input-group-btn:last-child>.btn-group {
    z-index: 2;
    margin-left: -1px;
}

.nav {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.nav:before, .nav:after {
    content: " ";
    display: table;
}

.nav:after {
    clear: both;
}

.nav>li {
    position: relative;
    display: block;
}

.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
}

.nav>li>a:hover, .nav>li>a:focus {
    text-decoration: none;
    background-color: #eeeeee;
}

.nav>li.disabled>a {
    color: #777777;
}

.nav>li.disabled>a:hover, .nav>li.disabled>a:focus {
    color: #777777;
    text-decoration: none;
    background-color: transparent;
    cursor: not-allowed;
}

.nav .open>a, .nav .open>a:hover, .nav .open>a:focus {
    background-color: #eeeeee;
    border-color: #337ab7;
}

.nav .nav-divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

.nav>li>a>img {
    max-width: none;
}

.nav-tabs {
    border-bottom: 1px solid #ddd;
}

.nav-tabs>li {
    float: left;
    margin-bottom: -1px;
}

.nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}

.nav-tabs>li>a:hover {
    border-color: #eeeeee #eeeeee #ddd;
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus {
    color: #555555;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
    cursor: default;
}

.nav-pills>li {
    float: left;
}

.nav-pills>li>a {
    border-radius: 4px;
}

.nav-pills>li+li {
    margin-left: 2px;
}

.nav-pills>li.active>a, .nav-pills>li.active>a:hover, .nav-pills>li.active>a:focus {
    color: #fff;
    background-color: #337ab7;
}

.nav-stacked>li {
    float: none;
}

.nav-stacked>li+li {
    margin-top: 2px;
    margin-left: 0;
}

.nav-justified, .nav-tabs.nav-justified {
    width: 100%;
}

.nav-justified>li, .nav-tabs.nav-justified>li {
    float: none;
}

.nav-justified>li>a, .nav-tabs.nav-justified>li>a {
    text-align: center;
    margin-bottom: 5px;
}

.nav-justified>.dropdown .dropdown-menu {
    top: auto;
    left: auto;
}

@media (min-width: 768px) {
    .nav-justified>li, .nav-tabs.nav-justified>li {
        display: table-cell;
        width: 1%;
    }
    .nav-justified>li>a, .nav-tabs.nav-justified>li>a {
        margin-bottom: 0;
    }
}

.nav-tabs-justified, .nav-tabs.nav-justified {
    border-bottom: 0;
}

.nav-tabs-justified>li>a, .nav-tabs.nav-justified>li>a {
    margin-right: 0;
    border-radius: 4px;
}

.nav-tabs-justified>.active>a, .nav-tabs.nav-justified>.active>a, .nav-tabs-justified>.active>a:hover, .nav-tabs.nav-justified>.active>a:hover, .nav-tabs-justified>.active>a:focus, .nav-tabs.nav-justified>.active>a:focus {
    border: 1px solid #ddd;
}

@media (min-width: 768px) {
    .nav-tabs-justified>li>a, .nav-tabs.nav-justified>li>a {
        border-bottom: 1px solid #ddd;
        border-radius: 4px 4px 0 0;
    }
    .nav-tabs-justified>.active>a, .nav-tabs.nav-justified>.active>a, .nav-tabs-justified>.active>a:hover, .nav-tabs.nav-justified>.active>a:hover, .nav-tabs-justified>.active>a:focus, .nav-tabs.nav-justified>.active>a:focus {
        border-bottom-color: #fff;
    }
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 20px;
    border: 1px solid transparent;
}

.navbar:before, .navbar:after {
    content: " ";
    display: table;
}

.navbar:after {
    clear: both;
}

@media (min-width: 768px) {
    .navbar {
        border-radius: 4px;
    }
}

.navbar-header:before, .navbar-header:after {
    content: " ";
    display: table;
}

.navbar-header:after {
    clear: both;
}

@media (min-width: 768px) {
    .navbar-header {
        float: left;
    }
}

.navbar-collapse {
    overflow-x: visible;
    padding-right: 15px;
    padding-left: 15px;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    -webkit-overflow-scrolling: touch;
}

.navbar-collapse:before, .navbar-collapse:after {
    content: " ";
    display: table;
}

.navbar-collapse:after {
    clear: both;
}

.navbar-collapse.in {
    overflow-y: auto;
}

@media (min-width: 768px) {
    .navbar-collapse {
        width: auto;
        border-top: 0;
        box-shadow: none;
    }
    .navbar-collapse.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important;
    }
    .navbar-collapse.in {
        overflow-y: visible;
    }
    .navbar-fixed-top .navbar-collapse, .navbar-static-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
        padding-left: 0;
        padding-right: 0;
    }
}

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    max-height: 340px;
}

@media (max-device-width: 480px) and (orientation: landscape) {
    .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
        max-height: 200px;
    }
}

.container>.navbar-header, .container>.navbar-collapse, .container-fluid>.navbar-header, .container-fluid>.navbar-collapse {
    margin-right: -15px;
    margin-left: -15px;
}

@media (min-width: 768px) {
    .container>.navbar-header, .container>.navbar-collapse, .container-fluid>.navbar-header, .container-fluid>.navbar-collapse {
        margin-right: 0;
        margin-left: 0;
    }
}

.navbar-static-top {
    z-index: 1000;
    border-width: 0 0 1px;
}

@media (min-width: 768px) {
    .navbar-static-top {
        border-radius: 0;
    }
}

.navbar-fixed-top, .navbar-fixed-bottom {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
}

@media (min-width: 768px) {
    .navbar-fixed-top, .navbar-fixed-bottom {
        border-radius: 0;
    }
}

.navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
}

.navbar-fixed-bottom {
    bottom: 0;
    margin-bottom: 0;
    border-width: 1px 0 0;
}

.navbar-brand {
    float: left;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
    height: 50px;
}

.navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none;
}

.navbar-brand>img {
    display: block;
}

@media (min-width: 768px) {
    .navbar>.container .navbar-brand, .navbar>.container-fluid .navbar-brand {
        margin-left: 0;
    }
}

@media (min-width: 992px) {
    .navbar>.container .navbar-brand, .navbar>.container-fluid .navbar-brand {
        margin-left: -15px;
    }
}

.navbar-toggle {
    position: relative;
    float: left;
    padding: 9px 10px;
    margin: 0 0 0 15px;
    background-color: transparent;
    background-image: none;
    border: none;
}

.navbar-toggle:focus {
    outline: 0;
}

.navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
}

.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 4px;
}

@media (min-width: 768px) {
    .navbar-toggle {
        margin-left: 0;
    }
}

.navbar-nav {
    margin: 7.5px -15px;
}

.navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 20px;
}

@media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none;
    }
    .navbar-nav .open .dropdown-menu>li>a, .navbar-nav .open .dropdown-menu .dropdown-header {
        padding: 5px 15px 5px 25px;
    }
    .navbar-nav .open .dropdown-menu>li>a {
        line-height: 20px;
    }
    .navbar-nav .open .dropdown-menu>li>a:hover, .navbar-nav .open .dropdown-menu>li>a:focus {
        background-image: none;
    }
}

@media (min-width: 768px) {
    .navbar-nav {
        float: left;
        margin: 0;
    }
    .navbar-nav>li {
        float: left;
    }
    .navbar-nav>li>a {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.navbar-form {
    margin-left: -15px;
    margin-right: -15px;
    padding: 10px 15px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
    margin-top: 8px;
    margin-bottom: 8px;
}

@media (min-width: 768px) {
    .navbar-form .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
    }
    .navbar-form .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
    .navbar-form .form-control-static {
        display: inline-block;
    }
    .navbar-form .input-group {
        display: inline-table;
        vertical-align: middle;
    }
    .navbar-form .input-group .input-group-addon, .navbar-form .input-group .input-group-btn, .navbar-form .input-group .form-control {
        width: auto;
    }
    .navbar-form .input-group>.form-control {
        width: 100%;
    }
    .navbar-form .control-label {
        margin-bottom: 0;
        vertical-align: middle;
    }
    .navbar-form .radio, .navbar-form .checkbox {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: middle;
    }
    .navbar-form .radio label, .navbar-form .checkbox label {
        padding-left: 0;
    }
    .navbar-form .radio input[type="radio"], .navbar-form .checkbox input[type="checkbox"] {
        position: relative;
        margin-left: 0;
    }
    .navbar-form .has-feedback .form-control-feedback {
        top: 0;
    }
}

@media (max-width: 767px) {
    .navbar-form .form-group {
        margin-bottom: 5px;
    }
    .navbar-form .form-group:last-child {
        margin-bottom: 0;
    }
}

@media (min-width: 768px) {
    .navbar-form {
        width: auto;
        border: 0;
        margin-left: 0;
        margin-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

.navbar-nav>li>.dropdown-menu {
    margin-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.navbar-fixed-bottom .navbar-nav>li>.dropdown-menu {
    margin-bottom: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.navbar-btn {
    margin-top: 8px;
    margin-bottom: 8px;
}

.navbar-btn.btn-sm, .btn-group-sm>.navbar-btn.btn {
    margin-top: 10px;
    margin-bottom: 10px;
}

.navbar-btn.btn-xs, .btn-group-xs>.navbar-btn.btn {
    margin-top: 14px;
    margin-bottom: 14px;
}

.navbar-text {
    margin-top: 15px;
    margin-bottom: 15px;
}

@media (min-width: 768px) {
    .navbar-text {
        float: left;
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media (min-width: 768px) {
    .navbar-left {
        float: left !important;
    }
    .navbar-right {
        float: right !important;
        margin-right: -15px;
    }
    .navbar-right~.navbar-right {
        margin-right: 0;
    }
}

.navbar-default {
    background-color: #f8f8f8;
    border-color: #e7e7e7;
}

.navbar-default .navbar-brand {
    color: #777;
}

.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
    color: #5e5e5e;
    background-color: transparent;
}

.navbar-default .navbar-text {
    color: #777;
}

.navbar-default .navbar-nav>li>a {
    color: #777;
}

.navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>li>a:focus {
    color: #333;
    background-color: transparent;
}

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus {
    color: #555;
    background-color: #e7e7e7;
}

.navbar-default .navbar-nav>.disabled>a, .navbar-default .navbar-nav>.disabled>a:hover, .navbar-default .navbar-nav>.disabled>a:focus {
    color: #ccc;
    background-color: transparent;
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:active {
    background-color: #fff;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus:hover,
.navbar-default .navbar-toggle:active:hover {
    background-color: #f2f2f2;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #888;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: #e7e7e7;
}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:hover, .navbar-default .navbar-nav>.open>a:focus {
    background-color: #e7e7e7;
    color: #555;
}

@media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu>li>a {
        color: #777;
    }
    .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover, .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus {
        color: #333;
        background-color: transparent;
    }
    .navbar-default .navbar-nav .open .dropdown-menu>.active>a, .navbar-default .navbar-nav .open .dropdown-menu>.active>a:hover, .navbar-default .navbar-nav .open .dropdown-menu>.active>a:focus {
        color: #555;
        background-color: #e7e7e7;
    }
    .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a, .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:hover, .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:focus {
        color: #ccc;
        background-color: transparent;
    }
}

.navbar-default .navbar-link {
    color: #777;
}

.navbar-default .navbar-link:hover {
    color: #333;
}

.navbar-default .btn-link {
    color: #777;
}

.navbar-default .btn-link:hover, .navbar-default .btn-link:focus {
    color: #333;
}

.navbar-default .btn-link[disabled]:hover, .navbar-default .btn-link[disabled]:focus, fieldset[disabled] .navbar-default .btn-link:hover, fieldset[disabled] .navbar-default .btn-link:focus {
    color: #ccc;
}

.navbar-inverse {
    background-color: #222;
    border-color: #090909;
}

.navbar-inverse .navbar-brand {
    color: #9d9d9d;
}

.navbar-inverse .navbar-brand:hover, .navbar-inverse .navbar-brand:focus {
    color: #fff;
    background-color: transparent;
}

.navbar-inverse .navbar-text {
    color: #9d9d9d;
}

.navbar-inverse .navbar-nav>li>a {
    color: #9d9d9d;
}

.navbar-inverse .navbar-nav>li>a:hover, .navbar-inverse .navbar-nav>li>a:focus {
    color: #fff;
    background-color: transparent;
}

.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:hover, .navbar-inverse .navbar-nav>.active>a:focus {
    color: #fff;
    background-color: #090909;
}

.navbar-inverse .navbar-nav>.disabled>a, .navbar-inverse .navbar-nav>.disabled>a:hover, .navbar-inverse .navbar-nav>.disabled>a:focus {
    color: #444;
    background-color: transparent;
}

.navbar-inverse .navbar-toggle {
    border-color: #333;
}

.navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
    background-color: #333;
}

.navbar-inverse .navbar-toggle .icon-bar {
    background-color: #fff;
}

.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
    border-color: #101010;
}

.navbar-inverse .navbar-nav>.open>a, .navbar-inverse .navbar-nav>.open>a:hover, .navbar-inverse .navbar-nav>.open>a:focus {
    background-color: #090909;
    color: #fff;
}

@media (max-width: 767px) {
    .navbar-inverse .navbar-nav .open .dropdown-menu>.dropdown-header {
        border-color: #090909;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
        background-color: #090909;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
        color: #9d9d9d;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:focus {
        color: #fff;
        background-color: transparent;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a, .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:focus {
        color: #fff;
        background-color: #090909;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a, .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:focus {
        color: #444;
        background-color: transparent;
    }
}

.navbar-inverse .navbar-link {
    color: #9d9d9d;
}

.navbar-inverse .navbar-link:hover {
    color: #fff;
}

.navbar-inverse .btn-link {
    color: #9d9d9d;
}

.navbar-inverse .btn-link:hover, .navbar-inverse .btn-link:focus {
    color: #fff;
}

.navbar-inverse .btn-link[disabled]:hover, .navbar-inverse .btn-link[disabled]:focus, fieldset[disabled] .navbar-inverse .btn-link:hover, fieldset[disabled] .navbar-inverse .btn-link:focus {
    color: #444;
}

.breadcrumb {
    padding: 8px 15px;
    margin-bottom: 20px;
    list-style: none;
    background-color: #f5f5f5;
    border-radius: 4px;
}

.breadcrumb>li {
    display: inline-block;
}

.breadcrumb>li+li:before {
    content: "/ ";
    padding: 0 5px;
    color: #ccc;
}

.breadcrumb>.active {
    color: #777777;
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
}

.pagination>li {
    display: inline;
}

.pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857;
    text-decoration: none;
    color: #337ab7;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
}

.pagination>li:first-child>a, .pagination>li:first-child>span {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.pagination>li:last-child>a, .pagination>li:last-child>span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.pagination>li>a:hover, .pagination>li>a:focus, .pagination>li>span:hover, .pagination>li>span:focus {
    z-index: 2;
    color: #23527c;
    background-color: #eeeeee;
    border-color: #ddd;
}

.pagination>.active>a, .pagination>.active>a:hover, .pagination>.active>a:focus, .pagination>.active>span, .pagination>.active>span:hover, .pagination>.active>span:focus {
    z-index: 3;
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
    cursor: default;
}

.pagination>.disabled>span, .pagination>.disabled>span:hover, .pagination>.disabled>span:focus, .pagination>.disabled>a, .pagination>.disabled>a:hover, .pagination>.disabled>a:focus {
    color: #777777;
    background-color: #fff;
    border-color: #ddd;
    cursor: not-allowed;
}

.pagination-lg>li>a, .pagination-lg>li>span {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33333;
}

.pagination-lg>li:first-child>a, .pagination-lg>li:first-child>span {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
}

.pagination-lg>li:last-child>a, .pagination-lg>li:last-child>span {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
}

.pagination-sm>li>a, .pagination-sm>li>span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.pagination-sm>li:first-child>a, .pagination-sm>li:first-child>span {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

.pagination-sm>li:last-child>a, .pagination-sm>li:last-child>span {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

.pager {
    padding-left: 0;
    margin: 20px 0;
    list-style: none;
    text-align: center;
}

.pager:before, .pager:after {
    content: " ";
    display: table;
}

.pager:after {
    clear: both;
}

.pager li {
    display: inline;
}

.pager li>a, .pager li>span {
    display: inline-block;
    padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 15px;
}

.pager li>a:hover, .pager li>a:focus {
    text-decoration: none;
    background-color: #eeeeee;
}

.pager .next>a, .pager .next>span {
    float: right;
}

.pager .previous>a, .pager .previous>span {
    float: left;
}

.pager .disabled>a, .pager .disabled>a:hover, .pager .disabled>a:focus, .pager .disabled>span {
    color: #777777;
    background-color: #fff;
    cursor: not-allowed;
}

.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

.label:empty {
    display: none;
}

.btn .label {
    position: relative;
    top: -1px;
}

a.label:hover, a.label:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

.label-default {
    background-color: #777777;
}

.label-default[href]:hover, .label-default[href]:focus {
    background-color: #5e5e5e;
}

.label-primary {
    background-color: #337ab7;
}

.label-primary[href]:hover, .label-primary[href]:focus {
    background-color: #286090;
}

.label-success {
    background-color: #5cb85c;
}

.label-success[href]:hover, .label-success[href]:focus {
    background-color: #449d44;
}

.label-info {
    background-color: #5bc0de;
}

.label-info[href]:hover, .label-info[href]:focus {
    background-color: #31b0d5;
}

.label-warning {
    background-color: #f0ad4e;
}

.label-warning[href]:hover, .label-warning[href]:focus {
    background-color: #ec971f;
}

.label-danger {
    background-color: #d9534f;
}

.label-danger[href]:hover, .label-danger[href]:focus {
    background-color: #c9302c;
}

.badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    line-height: 1;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
    background-color: #777777;
    border-radius: 10px;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.btn-xs .badge, .btn-group-xs>.btn .badge, .btn-group-xs>.btn .badge {
    top: 0;
    padding: 1px 5px;
}

.list-group-item.active>.badge, .nav-pills>.active>a>.badge {
    color: #337ab7;
    background-color: #fff;
}

.list-group-item>.badge {
    float: right;
}

.list-group-item>.badge+.badge {
    margin-right: 5px;
}

.nav-pills>li>a>.badge {
    margin-left: 3px;
}

a.badge:hover, a.badge:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

.jumbotron {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    color: inherit;
    background-color: #eeeeee;
}

.jumbotron h1, .jumbotron .h1 {
    color: inherit;
}

.jumbotron>hr {
    border-top-color: #d5d5d5;
}

.container .jumbotron, .container-fluid .jumbotron {
    border-radius: 20px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0px 10px 24px 14px rgba(0,0,0,0.12);
}

.jumbotron .container {
    max-width: 100%;
}

@media screen and (min-width: 768px) {
    .jumbotron {
        padding-top: 48px;
        padding-bottom: 48px;
    }
    .container .jumbotron, .container-fluid .jumbotron {
        padding-left: 60px;
        padding-right: 60px;
    }
}

.thumbnail {
    display: block;
    padding: 4px;
    margin-bottom: 20px;
    line-height: 1.42857;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: border 0.2s ease-in-out;
    -o-transition: border 0.2s ease-in-out;
    transition: border 0.2s ease-in-out;
}

.thumbnail>img, .thumbnail a>img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.thumbnail .caption {
    padding: 9px;
    color: #333333;
}

a.thumbnail:hover, a.thumbnail:focus, a.thumbnail.active {
    border-color: #337ab7;
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.alert h4 {
    margin-top: 0;
    color: inherit;
}

.alert .alert-link {
    font-weight: bold;
}

.alert>p, .alert>ul {
    margin-bottom: 0;
}

.alert>p+p {
    margin-top: 5px;
}

.alert-dismissable, .alert-dismissible {
    padding-right: 35px;
}

.alert-dismissable .close, .alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
}

.alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
}

.alert-success hr {
    border-top-color: #c9e2b3;
}

.alert-success .alert-link {
    color: #2b542c;
}

.alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f;
}

.alert-info hr {
    border-top-color: #a6e1ec;
}

.alert-info .alert-link {
    color: #245269;
}

.alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
}

.alert-warning hr {
    border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
    color: #66512c;
}

.alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
}

.alert-danger hr {
    border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
    color: #843534;
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 40px 0;
    }
    to {
        background-position: 0 0;
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 40px 0;
    }
    to {
        background-position: 0 0;
    }
}

.progress {
    overflow: hidden;
    height: 20px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-bar {
    float: left;
    width: 0%;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
}

.progress-striped .progress-bar, .progress-bar-striped {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 40px 40px;
}

.progress.active .progress-bar, .progress-bar.active {
    -webkit-animation: progress-bar-stripes 2s linear infinite;
    -o-animation: progress-bar-stripes 2s linear infinite;
    animation: progress-bar-stripes 2s linear infinite;
}

.progress-bar-success {
    background-color: #5cb85c;
}

.progress-striped .progress-bar-success {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-info {
    background-color: #5bc0de;
}

.progress-striped .progress-bar-info {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-warning {
    background-color: #f0ad4e;
}

.progress-striped .progress-bar-warning {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-danger {
    background-color: #d9534f;
}

.progress-striped .progress-bar-danger {
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.media {
    margin-top: 15px;
}

.media:first-child {
    margin-top: 0;
}

.media, .media-body {
    zoom: 1;
    overflow: hidden;
}

.media-body {
    width: 10000px;
}

.media-object {
    display: block;
}

.media-object.img-thumbnail {
    max-width: none;
}

.media-right, .media>.pull-right {
    padding-left: 10px;
}

.media-left, .media>.pull-left {
    padding-right: 10px;
}

.media-left, .media-right, .media-body {
    display: table-cell;
    vertical-align: top;
}

.media-middle {
    vertical-align: middle;
}

.media-bottom {
    vertical-align: bottom;
}

.media-heading {
    margin-top: 0;
    margin-bottom: 5px;
}

.media-list {
    padding-left: 0;
    list-style: none;
}

.list-group {
    margin-bottom: 20px;
    padding-left: 0;
}

#lstgroup {
    margin-bottom: 0;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd;
}

.list-group-item:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

a.list-group-item, button.list-group-item {
    color: #555;
}

a.list-group-item .list-group-item-heading, button.list-group-item .list-group-item-heading {
    color: #333;
}

a.list-group-item:hover, a.list-group-item:focus, button.list-group-item:hover, button.list-group-item:focus {
    text-decoration: none;
    color: #555;
    background-color: #f5f5f5;
}

button.list-group-item {
    width: 100%;
    text-align: left;
}

.list-group-item.disabled, .list-group-item.disabled:hover, .list-group-item.disabled:focus {
    background-color: #eeeeee;
    color: #777777;
    cursor: not-allowed;
}

.list-group-item.disabled .list-group-item-heading, .list-group-item.disabled:hover .list-group-item-heading, .list-group-item.disabled:focus .list-group-item-heading {
    color: inherit;
}

.list-group-item.disabled .list-group-item-text, .list-group-item.disabled:hover .list-group-item-text, .list-group-item.disabled:focus .list-group-item-text {
    color: #777777;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    z-index: 2;
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
}

.list-group-item.active .list-group-item-heading, .list-group-item.active .list-group-item-heading>small, .list-group-item.active .list-group-item-heading>.small, .list-group-item.active:hover .list-group-item-heading, .list-group-item.active:hover .list-group-item-heading>small, .list-group-item.active:hover .list-group-item-heading>.small, .list-group-item.active:focus .list-group-item-heading, .list-group-item.active:focus .list-group-item-heading>small, .list-group-item.active:focus .list-group-item-heading>.small {
    color: inherit;
}

.list-group-item.active .list-group-item-text, .list-group-item.active:hover .list-group-item-text, .list-group-item.active:focus .list-group-item-text {
    color: #c7ddef;
}

.list-group-item-success {
    color: #3c763d;
    background-color: #dff0d8;
}

a.list-group-item-success, button.list-group-item-success {
    color: #3c763d;
}

a.list-group-item-success .list-group-item-heading, button.list-group-item-success .list-group-item-heading {
    color: inherit;
}

a.list-group-item-success:hover, a.list-group-item-success:focus, button.list-group-item-success:hover, button.list-group-item-success:focus {
    color: #3c763d;
    background-color: #d0e9c6;
}

a.list-group-item-success.active, a.list-group-item-success.active:hover, a.list-group-item-success.active:focus, button.list-group-item-success.active, button.list-group-item-success.active:hover, button.list-group-item-success.active:focus {
    color: #fff;
    background-color: #3c763d;
    border-color: #3c763d;
}

.list-group-item-info {
    color: #31708f;
    background-color: #d9edf7;
}

a.list-group-item-info, button.list-group-item-info {
    color: #31708f;
}

a.list-group-item-info .list-group-item-heading, button.list-group-item-info .list-group-item-heading {
    color: inherit;
}

a.list-group-item-info:hover, a.list-group-item-info:focus, button.list-group-item-info:hover, button.list-group-item-info:focus {
    color: #31708f;
    background-color: #c4e3f3;
}

a.list-group-item-info.active, a.list-group-item-info.active:hover, a.list-group-item-info.active:focus, button.list-group-item-info.active, button.list-group-item-info.active:hover, button.list-group-item-info.active:focus {
    color: #fff;
    background-color: #31708f;
    border-color: #31708f;
}

.list-group-item-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
}

a.list-group-item-warning, button.list-group-item-warning {
    color: #8a6d3b;
}

a.list-group-item-warning .list-group-item-heading, button.list-group-item-warning .list-group-item-heading {
    color: inherit;
}

a.list-group-item-warning:hover, a.list-group-item-warning:focus, button.list-group-item-warning:hover, button.list-group-item-warning:focus {
    color: #8a6d3b;
    background-color: #faf2cc;
}

a.list-group-item-warning.active, a.list-group-item-warning.active:hover, a.list-group-item-warning.active:focus, button.list-group-item-warning.active, button.list-group-item-warning.active:hover, button.list-group-item-warning.active:focus {
    color: #fff;
    background-color: #8a6d3b;
    border-color: #8a6d3b;
}

.list-group-item-danger {
    color: #a94442;
    background-color: #f2dede;
}

a.list-group-item-danger, button.list-group-item-danger {
    color: #a94442;
}

a.list-group-item-danger .list-group-item-heading, button.list-group-item-danger .list-group-item-heading {
    color: inherit;
}

a.list-group-item-danger:hover, a.list-group-item-danger:focus, button.list-group-item-danger:hover, button.list-group-item-danger:focus {
    color: #a94442;
    background-color: #ebcccc;
}

a.list-group-item-danger.active, a.list-group-item-danger.active:hover, a.list-group-item-danger.active:focus, button.list-group-item-danger.active, button.list-group-item-danger.active:hover, button.list-group-item-danger.active:focus {
    color: #fff;
    background-color: #a94442;
    border-color: #a94442;
}

.list-group-item-heading {
    margin-top: 0;
    margin-bottom: 5px;
}

.list-group-item-text {
    margin-bottom: 0;
    line-height: 1.3;
}

.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-body {
    padding: 15px;
}

.panel-body:before, .panel-body:after {
    content: " ";
    display: table;
}

.panel-body:after {
    clear: both;
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.panel-heading>.dropdown .dropdown-toggle {
    color: inherit;
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
}

.panel-title>a, .panel-title>small, .panel-title>.small, .panel-title>small>a, .panel-title>.small>a {
    color: inherit;
}

.panel-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.panel>.list-group, .panel>.panel-collapse>.list-group {
    margin-bottom: 0;
}

.panel>.list-group .list-group-item, .panel>.panel-collapse>.list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0;
}

.panel>.list-group:first-child .list-group-item:first-child, .panel>.panel-collapse>.list-group:first-child .list-group-item:first-child {
    border-top: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.panel>.list-group:last-child .list-group-item:last-child, .panel>.panel-collapse>.list-group:last-child .list-group-item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.panel>.panel-heading+.panel-collapse>.list-group .list-group-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.panel-heading+.list-group .list-group-item:first-child {
    border-top-width: 0;
}

.list-group+.panel-footer {
    border-top-width: 0;
}

.panel>.table, .panel>.table-responsive>.table, .panel>.panel-collapse>.table {
    margin-bottom: 0;
}

.panel>.table caption, .panel>.table-responsive>.table caption, .panel>.panel-collapse>.table caption {
    padding-left: 15px;
    padding-right: 15px;
}

.panel>.table:first-child, .panel>.table-responsive:first-child>.table:first-child {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.panel>.table:first-child>thead:first-child>tr:first-child, .panel>.table:first-child>tbody:first-child>tr:first-child, .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child, .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.panel>.table:first-child>thead:first-child>tr:first-child td:first-child, .panel>.table:first-child>thead:first-child>tr:first-child th:first-child, .panel>.table:first-child>tbody:first-child>tr:first-child td:first-child, .panel>.table:first-child>tbody:first-child>tr:first-child th:first-child, .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:first-child, .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:first-child, .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:first-child, .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:first-child {
    border-top-left-radius: 3px;
}

.panel>.table:first-child>thead:first-child>tr:first-child td:last-child, .panel>.table:first-child>thead:first-child>tr:first-child th:last-child, .panel>.table:first-child>tbody:first-child>tr:first-child td:last-child, .panel>.table:first-child>tbody:first-child>tr:first-child th:last-child, .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:last-child, .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:last-child, .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:last-child, .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:last-child {
    border-top-right-radius: 3px;
}

.panel>.table:last-child, .panel>.table-responsive:last-child>.table:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.panel>.table:last-child>tbody:last-child>tr:last-child, .panel>.table:last-child>tfoot:last-child>tr:last-child, .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child, .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.panel>.table:last-child>tbody:last-child>tr:last-child td:first-child, .panel>.table:last-child>tbody:last-child>tr:last-child th:first-child, .panel>.table:last-child>tfoot:last-child>tr:last-child td:first-child, .panel>.table:last-child>tfoot:last-child>tr:last-child th:first-child, .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:first-child, .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:first-child, .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:first-child, .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:first-child {
    border-bottom-left-radius: 3px;
}

.panel>.table:last-child>tbody:last-child>tr:last-child td:last-child, .panel>.table:last-child>tbody:last-child>tr:last-child th:last-child, .panel>.table:last-child>tfoot:last-child>tr:last-child td:last-child, .panel>.table:last-child>tfoot:last-child>tr:last-child th:last-child, .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:last-child, .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:last-child, .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:last-child, .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:last-child {
    border-bottom-right-radius: 3px;
}

.panel>.panel-body+.table, .panel>.panel-body+.table-responsive, .panel>.table+.panel-body, .panel>.table-responsive+.panel-body {
    border-top: 1px solid #ddd;
}

.panel>.table>tbody:first-child>tr:first-child th, .panel>.table>tbody:first-child>tr:first-child td {
    border-top: 0;
}

.panel>.table-bordered, .panel>.table-responsive>.table-bordered {
    border: 0;
}

.panel>.table-bordered>thead>tr>th:first-child, .panel>.table-bordered>thead>tr>td:first-child, .panel>.table-bordered>tbody>tr>th:first-child, .panel>.table-bordered>tbody>tr>td:first-child, .panel>.table-bordered>tfoot>tr>th:first-child, .panel>.table-bordered>tfoot>tr>td:first-child, .panel>.table-responsive>.table-bordered>thead>tr>th:first-child, .panel>.table-responsive>.table-bordered>thead>tr>td:first-child, .panel>.table-responsive>.table-bordered>tbody>tr>th:first-child, .panel>.table-responsive>.table-bordered>tbody>tr>td:first-child, .panel>.table-responsive>.table-bordered>tfoot>tr>th:first-child, .panel>.table-responsive>.table-bordered>tfoot>tr>td:first-child {
    border-left: 0;
}

.panel>.table-bordered>thead>tr>th:last-child, .panel>.table-bordered>thead>tr>td:last-child, .panel>.table-bordered>tbody>tr>th:last-child, .panel>.table-bordered>tbody>tr>td:last-child, .panel>.table-bordered>tfoot>tr>th:last-child, .panel>.table-bordered>tfoot>tr>td:last-child, .panel>.table-responsive>.table-bordered>thead>tr>th:last-child, .panel>.table-responsive>.table-bordered>thead>tr>td:last-child, .panel>.table-responsive>.table-bordered>tbody>tr>th:last-child, .panel>.table-responsive>.table-bordered>tbody>tr>td:last-child, .panel>.table-responsive>.table-bordered>tfoot>tr>th:last-child, .panel>.table-responsive>.table-bordered>tfoot>tr>td:last-child {
    border-right: 0;
}

.panel>.table-bordered>thead>tr:first-child>td, .panel>.table-bordered>thead>tr:first-child>th, .panel>.table-bordered>tbody>tr:first-child>td, .panel>.table-bordered>tbody>tr:first-child>th, .panel>.table-responsive>.table-bordered>thead>tr:first-child>td, .panel>.table-responsive>.table-bordered>thead>tr:first-child>th, .panel>.table-responsive>.table-bordered>tbody>tr:first-child>td, .panel>.table-responsive>.table-bordered>tbody>tr:first-child>th {
    border-bottom: 0;
}

.panel>.table-bordered>tbody>tr:last-child>td, .panel>.table-bordered>tbody>tr:last-child>th, .panel>.table-bordered>tfoot>tr:last-child>td, .panel>.table-bordered>tfoot>tr:last-child>th, .panel>.table-responsive>.table-bordered>tbody>tr:last-child>td, .panel>.table-responsive>.table-bordered>tbody>tr:last-child>th, .panel>.table-responsive>.table-bordered>tfoot>tr:last-child>td, .panel>.table-responsive>.table-bordered>tfoot>tr:last-child>th {
    border-bottom: 0;
}

.panel>.table-responsive {
    border: 0;
    margin-bottom: 0;
}

.panel-group {
    margin-bottom: 20px;
}

.panel-group .panel {
    margin-bottom: 0;
    border-radius: 4px;
}

.panel-group .panel+.panel {
    margin-top: 5px;
}

.panel-group .panel-heading {
    border-bottom: 0;
}

.panel-group .panel-heading+.panel-collapse>.panel-body, .panel-group .panel-heading+.panel-collapse>.list-group {
    border-top: 1px solid #ddd;
}

.panel-group .panel-footer {
    border-top: 0;
}

.panel-group .panel-footer+.panel-collapse .panel-body {
    border-bottom: 1px solid #ddd;
}

.panel-default {
    border-color: #ddd;
}

.panel-default>.panel-heading {
    color: #333333;
    background-color: #f5f5f5;
    border-color: #ddd;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #ddd;
}

.panel-default>.panel-heading .badge {
    color: #f5f5f5;
    background-color: #333333;
}

.panel-default>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #ddd;
}

.panel-primary {
    border-color: #337ab7;
}

.panel-primary>.panel-heading {
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
}

.panel-primary>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #337ab7;
}

.panel-primary>.panel-heading .badge {
    color: #337ab7;
    background-color: #fff;
}

.panel-primary>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #337ab7;
}

.panel-success {
    border-color: #d6e9c6;
}

.panel-success>.panel-heading {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.panel-success>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #d6e9c6;
}

.panel-success>.panel-heading .badge {
    color: #dff0d8;
    background-color: #3c763d;
}

.panel-success>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #d6e9c6;
}

.panel-info {
    border-color: #bce8f1;
}

.panel-info>.panel-heading {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.panel-info>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #bce8f1;
}

.panel-info>.panel-heading .badge {
    color: #d9edf7;
    background-color: #31708f;
}

.panel-info>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #bce8f1;
}

.panel-warning {
    border-color: #faebcc;
}

.panel-warning>.panel-heading {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}

.panel-warning>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #faebcc;
}

.panel-warning>.panel-heading .badge {
    color: #fcf8e3;
    background-color: #8a6d3b;
}

.panel-warning>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #faebcc;
}

.panel-danger {
    border-color: #ebccd1;
}

.panel-danger>.panel-heading {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.panel-danger>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #ebccd1;
}

.panel-danger>.panel-heading .badge {
    color: #f2dede;
    background-color: #a94442;
}

.panel-danger>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #ebccd1;
}

.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
}

.embed-responsive-16by9 {
    padding-bottom: 56.25%;
}

.embed-responsive-4by3 {
    padding-bottom: 75%;
}

.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well blockquote {
    border-color: #ddd;
    border-color: rgba(0, 0, 0, 0.15);
}

.well-lg {
    padding: 24px;
    border-radius: 6px;
}

.well-sm {
    padding: 9px;
    border-radius: 3px;
}

.close {
    float: right;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.2;
    filter: alpha(opacity=20);
}

.close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.5;
    filter: alpha(opacity=50);
}

button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
}

.modal-open {
    overflow: hidden;
}

.modal {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}

.modal.fade .modal-dialog {
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
}

.modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
}

.modal-content {
    position: relative;
    background-color: #fff;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
    filter: alpha(opacity=0);
}

.modal-backdrop.in {
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
}

.modal-header:before, .modal-header:after {
    content: " ";
    display: table;
}

.modal-header:after {
    clear: both;
}

.modal-header .close {
    margin-top: -2px;
}

.modal-title {
    margin: 0;
    line-height: 1.42857;
}

.modal-body {
    position: relative;
    padding: 15px;
}

.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

.modal-footer:before, .modal-footer:after {
    content: " ";
    display: table;
}

.modal-footer:after {
    clear: both;
}

.modal-footer .btn+.btn {
    margin-left: 5px;
    margin-bottom: 0;
}

.modal-footer .btn-group .btn+.btn {
    margin-left: -1px;
}

.modal-footer .btn-block+.btn-block {
    margin-left: 0;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 768px) {
    .modal-dialog {
        width: 600px;
        margin: 30px auto;
    }
    .modal-content {
        -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }
    .modal-sm {
        width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        width: 900px;
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.42857;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 12px;
    opacity: 0;
    filter: alpha(opacity=0);
}

.tooltip.in {
    opacity: 0.9;
    filter: alpha(opacity=90);
}

.tooltip.top {
    margin-top: -3px;
    padding: 5px 0;
}

.tooltip.right {
    margin-left: 3px;
    padding: 0 5px;
}

.tooltip.bottom {
    margin-top: 3px;
    padding: 5px 0;
}

.tooltip.left {
    margin-left: -3px;
    padding: 0 5px;
}

.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 4px;
}

.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
}

.tooltip.top-left .tooltip-arrow {
    bottom: 0;
    right: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
}

.tooltip.top-right .tooltip-arrow {
    bottom: 0;
    left: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
}

.tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #000;
}

.tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: #000;
}

.tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000;
}

.tooltip.bottom-left .tooltip-arrow {
    top: 0;
    right: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000;
}

.tooltip.bottom-right .tooltip-arrow {
    top: 0;
    left: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000;
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    max-width: 276px;
    padding: 1px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.42857;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 14px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.popover.top {
    margin-top: -10px;
}

.popover.right {
    margin-left: 10px;
}

.popover.bottom {
    margin-top: 10px;
}

.popover.left {
    margin-left: -10px;
}

.popover-title {
    margin: 0;
    padding: 8px 14px;
    font-size: 14px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
}

.popover-content {
    padding: 9px 14px;
}

.popover>.arrow, .popover>.arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.popover>.arrow {
    border-width: 11px;
}

.popover>.arrow:after {
    border-width: 10px;
    content: "";
}

.popover.top>.arrow {
    left: 50%;
    margin-left: -11px;
    border-bottom-width: 0;
    border-top-color: #999999;
    border-top-color: rgba(0, 0, 0, 0.25);
    bottom: -11px;
}

.popover.top>.arrow:after {
    content: " ";
    bottom: 1px;
    margin-left: -10px;
    border-bottom-width: 0;
    border-top-color: #fff;
}

.popover.right>.arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-left-width: 0;
    border-right-color: #999999;
    border-right-color: rgba(0, 0, 0, 0.25);
}

.popover.right>.arrow:after {
    content: " ";
    left: 1px;
    bottom: -10px;
    border-left-width: 0;
    border-right-color: #fff;
}

.popover.bottom>.arrow {
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999999;
    border-bottom-color: rgba(0, 0, 0, 0.25);
    top: -11px;
}

.popover.bottom>.arrow:after {
    content: " ";
    top: 1px;
    margin-left: -10px;
    border-top-width: 0;
    border-bottom-color: #fff;
}

.popover.left>.arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999999;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.popover.left>.arrow:after {
    content: " ";
    right: 1px;
    border-right-width: 0;
    border-left-color: #fff;
    bottom: -10px;
}

.carousel {
    position: relative;
}

.carousel-inner {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.carousel-inner>.item {
    display: none;
    position: relative;
    -webkit-transition: 0.6s ease-in-out left;
    -o-transition: 0.6s ease-in-out left;
    transition: 0.6s ease-in-out left;
}

.carousel-inner>.item>img, .carousel-inner>.item>a>img {
    display: block;
    max-width: 100%;
    height: auto;
    line-height: 1;
}

@media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-inner>.item {
        -webkit-transition: -webkit-transform 0.6s ease-in-out;
        -moz-transition: -moz-transform 0.6s ease-in-out;
        -o-transition: -o-transform 0.6s ease-in-out;
        transition: transform 0.6s ease-in-out;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-perspective: 1000px;
        -moz-perspective: 1000px;
        perspective: 1000px;
    }
    .carousel-inner>.item.next, .carousel-inner>.item.active.right {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        left: 0;
    }
    .carousel-inner>.item.prev, .carousel-inner>.item.active.left {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        left: 0;
    }
    .carousel-inner>.item.next.left, .carousel-inner>.item.prev.right, .carousel-inner>.item.active {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        left: 0;
    }
}

.carousel-inner>.active, .carousel-inner>.next, .carousel-inner>.prev {
    display: block;
}

.carousel-inner>.active {
    left: 0;
}

.carousel-inner>.next, .carousel-inner>.prev {
    position: absolute;
    top: 0;
    width: 100%;
}

.carousel-inner>.next {
    left: 100%;
}

.carousel-inner>.prev {
    left: -100%;
}

.carousel-inner>.next.left, .carousel-inner>.prev.right {
    left: 0;
}

.carousel-inner>.active.left {
    left: -100%;
}

.carousel-inner>.active.right {
    left: 100%;
}

.carousel-control {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 15%;
    opacity: 0.5;
    filter: alpha(opacity=50);
    font-size: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    background-color: transparent;
}

.carousel-control.left {
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
}

.carousel-control.right {
    left: auto;
    right: 0;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
}

.carousel-control:hover, .carousel-control:focus {
    outline: 0;
    color: #fff;
    text-decoration: none;
    opacity: 0.9;
    filter: alpha(opacity=90);
}

.carousel-control .icon-prev, .carousel-control .icon-next, .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    z-index: 5;
    display: inline-block;
}

.carousel-control .icon-prev, .carousel-control .glyphicon-chevron-left {
    left: 50%;
    margin-left: -10px;
}

.carousel-control .icon-next, .carousel-control .glyphicon-chevron-right {
    right: 50%;
    margin-right: -10px;
}

.carousel-control .icon-prev, .carousel-control .icon-next {
    width: 20px;
    height: 20px;
    line-height: 1;
    font-family: serif;
}

.carousel-control .icon-prev:before {
    content: '\2039';
}

.carousel-control .icon-next:before {
    content: '\203a';
}

.carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 15;
    width: 60%;
    margin-left: -30%;
    padding-left: 0;
    list-style: none;
    text-align: center;
}

.carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    border: 1px solid #fff;
    border-radius: 10px;
    cursor: pointer;
    background-color: #000 \9;
    background-color: transparent;
}

.carousel-indicators .active {
    margin: 0;
    width: 12px;
    height: 12px;
    background-color: #fff;
}

.carousel-caption {
    position: absolute;
    left: 15%;
    right: 15%;
    bottom: 20px;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.carousel-caption .btn {
    text-shadow: none;
}

@media screen and (min-width: 768px) {
    .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-prev, .carousel-control .icon-next {
        width: 30px;
        height: 30px;
        margin-top: -10px;
        font-size: 30px;
    }
    .carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev {
        margin-left: -10px;
    }
    .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next {
        margin-right: -10px;
    }
    .carousel-caption {
        left: 20%;
        right: 20%;
        padding-bottom: 30px;
    }
    .carousel-indicators {
        bottom: 20px;
    }
}

.clearfix:before, .clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
}

.hide {
    display: none !important;
}

.show {
    display: block !important;
}

.invisible {
    visibility: hidden;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.hidden {
    display: none !important;
}

.affix {
    position: fixed;
}

@-ms-viewport {
    width: device-width;
}

.visible-xs {
    display: none !important;
}

.visible-sm {
    display: none !important;
}

.visible-md {
    display: none !important;
}

.visible-lg {
    display: none !important;
}

.visible-xs-block, .visible-xs-inline, .visible-xs-inline-block, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block, .visible-md-block, .visible-md-inline, .visible-md-inline-block, .visible-lg-block, .visible-lg-inline, .visible-lg-inline-block {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }
    table.visible-xs {
        display: table !important;
    }
    tr.visible-xs {
        display: table-row !important;
    }
    th.visible-xs, td.visible-xs {
        display: table-cell !important;
    }
    .footer-toast {
        padding-top: 10px;
    }
    .footer-toast .btn-primary-transparent.btn, .footer-toast .btn {
        display: inline-block;
        width: auto;
        margin: 0;
    }
}

@media (max-width: 767px) {
    .visible-xs-block {
        display: block !important;
    }
}

@media (max-width: 767px) {
    .visible-xs-inline {
        display: inline !important;
    }
}

@media (max-width: 767px) {
    .visible-xs-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm {
        display: block !important;
    }
    table.visible-sm {
        display: table !important;
    }
    tr.visible-sm {
        display: table-row !important;
    }
    th.visible-sm, td.visible-sm {
        display: table-cell !important;
    }
    .middle-content .card {
        margin-bottom: 20px;
    }
    .v2 .directory-field>.clearfix {
        margin-left: 23%;
        margin-right: 30px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-block {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-inline {
        display: inline !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .visible-sm-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md {
        display: block !important;
    }
    table.visible-md {
        display: table !important;
    }
    tr.visible-md {
        display: table-row !important;
    }
    th.visible-md, td.visible-md {
        display: table-cell !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-block {
        display: block !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-inline {
        display: inline !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .visible-md-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg {
        display: block !important;
    }
    table.visible-lg {
        display: table !important;
    }
    tr.visible-lg {
        display: table-row !important;
    }
    th.visible-lg, td.visible-lg {
        display: table-cell !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg-block {
        display: block !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg-inline {
        display: inline !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg-inline-block {
        display: inline-block !important;
    }
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
    }
}

.visible-print {
    display: none !important;
}

@media print {
    .visible-print {
        display: block !important;
    }
    table.visible-print {
        display: table !important;
    }
    tr.visible-print {
        display: table-row !important;
    }
    th.visible-print, td.visible-print {
        display: table-cell !important;
    }
}

.visible-print-block {
    display: none !important;
}

@media print {
    .visible-print-block {
        display: block !important;
    }
}

.visible-print-inline {
    display: none !important;
}

@media print {
    .visible-print-inline {
        display: inline !important;
    }
}

.visible-print-inline-block {
    display: none !important;
}

@media print {
    .visible-print-inline-block {
        display: inline-block !important;
    }
}

@media print {
    .hidden-print {
        display: none !important;
    }
}

.bootstrap-select {
    /*width: 220px\9; IE8 and below*/
    width: 220px \0;
    /*IE9 and below*/
}

.bootstrap-select>.btn {
    width: 100%;
    padding-right: 25px;
}

.error .bootstrap-select .btn {
    border: 1px solid #b94a48;
}

.control-group.error .bootstrap-select .dropdown-toggle {
    border-color: #b94a48;
}

.bootstrap-select.fit-width {
    width: auto !important;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
}

.bootstrap-select .btn:focus {
    outline: thin dotted #333333 !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px;
}

.bootstrap-select.form-control {
    margin-bottom: 0;
    padding: 0;
    border: none;
}

.bootstrap-select.form-control:not([class*="col-"]) {
    width: 100%;
}

.bootstrap-select.btn-group:not(.input-group-btn), .bootstrap-select.btn-group[class*="col-"] {
    float: none;
    display: inline-block;
    margin-left: 0;
}

.bootstrap-select.btn-group.dropdown-menu-right, .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right, .row-fluid .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
    float: right;
}

.form-search .bootstrap-select.btn-group, .form-inline .bootstrap-select.btn-group, .form-horizontal .bootstrap-select.btn-group, .form-group .bootstrap-select.btn-group {
    margin-bottom: 0;
}

.form-group-lg .bootstrap-select.btn-group.form-control, .form-group-sm .bootstrap-select.btn-group.form-control {
    padding: 0;
}

.form-inline .bootstrap-select.btn-group .form-control {
    width: 100%;
}

.input-append .bootstrap-select.btn-group {
    margin-left: -1px;
}

.input-prepend .bootstrap-select.btn-group {
    margin-right: -1px;
}

.bootstrap-select.btn-group>.disabled {
    cursor: not-allowed;
}

.bootstrap-select.btn-group>.disabled:focus {
    outline: none !important;
}

.bootstrap-select.btn-group .btn .filter-option {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    text-align: left;
}

.bootstrap-select.btn-group .btn .caret {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -2px;
    vertical-align: middle;
}

.bootstrap-select.btn-group[class*="col-"] .btn {
    width: 100%;
}

.bootstrap-select.btn-group .dropdown-menu {
    min-width: 100%;
    z-index: 1035;
    box-sizing: border-box;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
    position: static;
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
}

.bootstrap-select.btn-group .dropdown-menu li {
    position: relative;
}

.bootstrap-select.btn-group .dropdown-menu li:not(.disabled) a:hover small, .bootstrap-select.btn-group .dropdown-menu li:not(.disabled) a:focus small, .bootstrap-select.btn-group .dropdown-menu li.active:not(.disabled) a small {
    color: #64b1d8;
    color: fade(#64b1d8, 40%);
}

.bootstrap-select.btn-group .dropdown-menu li.disabled a {
    cursor: not-allowed;
}

.bootstrap-select.btn-group .dropdown-menu li a {
    cursor: pointer;
}

.bootstrap-select.btn-group .dropdown-menu li a.opt {
    position: relative;
    padding-left: 2.25em;
}

.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
    display: none;
}

.bootstrap-select.btn-group .dropdown-menu li a span.text {
    display: inline-block;
}

.bootstrap-select.btn-group .dropdown-menu li small {
    padding-left: 0.5em;
}

.bootstrap-select.btn-group .dropdown-menu .notify {
    position: absolute;
    bottom: 5px;
    width: 96%;
    margin: 0 2%;
    min-height: 26px;
    padding: 3px 5px;
    background: whitesmoke;
    border: 1px solid #e3e3e3;
    box-shadow: inset 0 1px 1px fade(black, 5%);
    pointer-events: none;
    opacity: 0.9;
    box-sizing: border-box;
}

.bootstrap-select.btn-group .no-results {
    padding: 3px;
    background: #f5f5f5;
    margin: 0 5px;
}

.bootstrap-select.btn-group.fit-width .btn .filter-option {
    position: static;
}

.bootstrap-select.btn-group.fit-width .btn .caret {
    position: static;
    top: auto;
    margin-top: -1px;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
    position: absolute;
    display: inline-block;
    right: 15px;
    margin-top: 5px;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
    margin-right: 34px;
}

.bootstrap-select.show-menu-arrow.open>.btn {
    z-index: 1036;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
    content: '';
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom-width: 7px;
    border-bottom-style: solid;
    border-bottom-color: #cccccc;
    border-bottom-color: fade(#cccccc, 20%);
    position: absolute;
    bottom: -4px;
    left: 9px;
    display: none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid white;
    position: absolute;
    bottom: -4px;
    left: 10px;
    display: none;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
    bottom: auto;
    top: -3px;
    border-bottom: 0;
    border-top-width: 7px;
    border-top-style: solid;
    border-top-color: #cccccc;
    border-top-color: fade(#cccccc, 20%);
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
    bottom: auto;
    top: -3px;
    border-top: 6px solid white;
    border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
    right: 12px;
    left: auto;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
    right: 13px;
    left: auto;
}

.bootstrap-select.show-menu-arrow.open>.dropdown-toggle:before, .bootstrap-select.show-menu-arrow.open>.dropdown-toggle:after {
    display: block;
}

.bs-searchbox, .bs-actionsbox {
    padding: 4px 8px;
}

.bs-actionsbox {
    float: left;
    width: 100%;
    box-sizing: border-box;
}

.bs-actionsbox .btn-group button {
    width: 50%;
}

.bs-searchbox+.bs-actionsbox {
    padding: 0 8px 4px;
}

.bs-searchbox input.form-control {
    margin-bottom: 0;
    width: 100%;
}

.mobile-device {
    position: absolute;
    top: 0;
    left: 0;
    display: block !important;
    width: 100%;
    height: 100% !important;
    opacity: 0;
}

/* -------------------------------------
* Bar container
* ------------------------------------- */

.progress-radial {
    position: relative;
    width: 242px;
    height: 242px;
    border-radius: 50%;
    background-color: #1774cc;
    border: 1px solid #fff;
}

@media (min-width: 1600px) {
    .progress-radial {
        margin: 0 auto;
    }
}

@media screen and (max-width: 990px) {
    .progress-radial {
        position: relative;
        width: 190px;
        height: 190px;
        border-radius: 50%;
        background-color: #1774cc;
        border: 1px solid #fff;
        right: 25px;
    }
    .progress-radial .overlay {
        position: absolute;
        width: 172px;
        height: 173px;
        background-color: #fff;
        border-radius: 50%;
        margin-left: 8px;
        margin-top: 8px;
        text-align: center;
        line-height: 60px;
        font-size: 12px;
    }
    .progress-radial .overlay .completion-txt {
        font-size: 50px;
        font-weight: bold;
        padding-top: 66px;
        line-height: 40%;
    }
}

/* -------------------------------------
 * Optional centered circle w/text
 * ------------------------------------- */

.progress-radial .overlay {
    position: absolute;
    width: 224px;
    height: 224px;
    background-color: #fff;
    border-radius: 50%;
    margin-left: 8px;
    margin-top: 8px;
    text-align: center;
    line-height: 60px;
    font-size: 12px;
}

.progress-radial .overlay .completion-txt {
    font-size: 66px;
    font-weight: bold;
    padding-top: 90px;
    line-height: 40%;
}

.progress-radial .overlay .called {
    padding: 90px 50px 0;
    line-height: 100%;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
}

/* -------------------------------------
 * Mixin for progress-% class
 * ------------------------------------- */

.progress-0 {
    background-image: linear-gradient(90deg, #f0f0f0 50%, transparent 50%, transparent), linear-gradient(90deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-5 {
    background-image: linear-gradient(90deg, #f0f0f0 50%, transparent 50%, transparent), linear-gradient(108deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-10 {
    background-image: linear-gradient(90deg, #f0f0f0 50%, transparent 50%, transparent), linear-gradient(126deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-15 {
    background-image: linear-gradient(90deg, #f0f0f0 50%, transparent 50%, transparent), linear-gradient(144deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-20 {
    background-image: linear-gradient(90deg, #f0f0f0 50%, transparent 50%, transparent), linear-gradient(162deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-25 {
    background-image: linear-gradient(90deg, #f0f0f0 50%, transparent 50%, transparent), linear-gradient(180deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-30 {
    background-image: linear-gradient(90deg, #f0f0f0 50%, transparent 50%, transparent), linear-gradient(198deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-35 {
    background-image: linear-gradient(90deg, #f0f0f0 50%, transparent 50%, transparent), linear-gradient(216deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-40 {
    background-image: linear-gradient(90deg, #f0f0f0 50%, transparent 50%, transparent), linear-gradient(234deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-45 {
    background-image: linear-gradient(90deg, #f0f0f0 50%, transparent 50%, transparent), linear-gradient(252deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-50 {
    background-image: linear-gradient(-90deg, #1193bf 50%, transparent 50%, transparent), linear-gradient(270deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-55 {
    background-image: linear-gradient(-72deg, #1193bf 50%, transparent 50%, transparent), linear-gradient(270deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-60 {
    background-image: linear-gradient(-54deg, #1193bf 50%, transparent 50%, transparent), linear-gradient(270deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-65 {
    background-image: linear-gradient(-36deg, #1193bf 50%, transparent 50%, transparent), linear-gradient(270deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-70 {
    background-image: linear-gradient(-18deg, #1193bf 50%, transparent 50%, transparent), linear-gradient(270deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-75 {
    background-image: linear-gradient(0deg, #1193bf 50%, transparent 50%, transparent), linear-gradient(270deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-80 {
    background-image: linear-gradient(18deg, #1193bf 50%, transparent 50%, transparent), linear-gradient(270deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-85 {
    background-image: linear-gradient(36deg, #1193bf 50%, transparent 50%, transparent), linear-gradient(270deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-90 {
    background-image: linear-gradient(54deg, #1193bf 50%, transparent 50%, transparent), linear-gradient(270deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-95 {
    background-image: linear-gradient(72deg, #1193bf 50%, transparent 50%, transparent), linear-gradient(270deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

.progress-100 {
    background-image: linear-gradient(90deg, #1193bf 50%, transparent 50%, transparent), linear-gradient(270deg, #1193bf 50%, #f0f0f0 50%, #f0f0f0);
}

/*
  Responsive
*/

.checkbox-primary input[type="checkbox"]:checked+label::before, .checkbox-primary input[type="radio"]:checked+label::before {
    background-color: #337ab7;
    border-color: #337ab7;
}

.checkbox-primary input[type="checkbox"]:checked+label::after, .checkbox-primary input[type="radio"]:checked+label::after {
    color: #fff;
}

.checkbox-danger input[type="checkbox"]:checked+label::before, .checkbox-danger input[type="radio"]:checked+label::before {
    background-color: #d9534f;
    border-color: #d9534f;
}

.checkbox-danger input[type="checkbox"]:checked+label::after, .checkbox-danger input[type="radio"]:checked+label::after {
    color: #fff;
}

.checkbox-info input[type="checkbox"]:checked+label::before, .checkbox-info input[type="radio"]:checked+label::before {
    background-color: #5bc0de;
    border-color: #5bc0de;
}

.checkbox-info input[type="checkbox"]:checked+label::after, .checkbox-info input[type="radio"]:checked+label::after {
    color: #fff;
}

.checkbox-warning input[type="checkbox"]:checked+label::before, .checkbox-warning input[type="radio"]:checked+label::before {
    background-color: #f0ad4e;
    border-color: #f0ad4e;
}

.checkbox-warning input[type="checkbox"]:checked+label::after, .checkbox-warning input[type="radio"]:checked+label::after {
    color: #fff;
}

.checkbox-success input[type="checkbox"]:checked+label::before, .checkbox-success input[type="radio"]:checked+label::before {
    background-color: #5cb85c;
    border-color: #5cb85c;
}

.checkbox-success input[type="checkbox"]:checked+label::after, .checkbox-success input[type="radio"]:checked+label::after {
    color: #fff;
}

.checkbox-primary input[type="checkbox"]:indeterminate+label::before, .checkbox-primary input[type="radio"]:indeterminate+label::before {
    background-color: #337ab7;
    border-color: #337ab7;
}

.checkbox-primary input[type="checkbox"]:indeterminate+label::after, .checkbox-primary input[type="radio"]:indeterminate+label::after {
    background-color: #fff;
}

.checkbox-danger input[type="checkbox"]:indeterminate+label::before, .checkbox-danger input[type="radio"]:indeterminate+label::before {
    background-color: #d9534f;
    border-color: #d9534f;
}

.checkbox-danger input[type="checkbox"]:indeterminate+label::after, .checkbox-danger input[type="radio"]:indeterminate+label::after {
    background-color: #fff;
}

.checkbox-info input[type="checkbox"]:indeterminate+label::before, .checkbox-info input[type="radio"]:indeterminate+label::before {
    background-color: #5bc0de;
    border-color: #5bc0de;
}

.checkbox-info input[type="checkbox"]:indeterminate+label::after, .checkbox-info input[type="radio"]:indeterminate+label::after {
    background-color: #fff;
}

.checkbox-warning input[type="checkbox"]:indeterminate+label::before, .checkbox-warning input[type="radio"]:indeterminate+label::before {
    background-color: #f0ad4e;
    border-color: #f0ad4e;
}

.checkbox-warning input[type="checkbox"]:indeterminate+label::after, .checkbox-warning input[type="radio"]:indeterminate+label::after {
    background-color: #fff;
}

.checkbox-success input[type="checkbox"]:indeterminate+label::before, .checkbox-success input[type="radio"]:indeterminate+label::before {
    background-color: #5cb85c;
    border-color: #5cb85c;
}

.checkbox-success input[type="checkbox"]:indeterminate+label::after, .checkbox-success input[type="radio"]:indeterminate+label::after {
    background-color: #fff;
}

.radio {
    padding-left: 20px;
}

.radio label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
}

.radio label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 2px solid #5b6b78;
    border-radius: 50%;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out;
    top: 10px;
}

.radio label::after {
    display: inline-block;
    position: absolute;
    content: " ";
    width: 9px;
    height: 9px;
    left: 4px;
    top: 14px;
    margin-left: -20px;
    border-radius: 50%;
    background-color: #1774cc;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio input[type="radio"] {
    opacity: 0;
    z-index: 1;
}

.radio input[type="radio"]:focus+label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.radio input[type="radio"]:checked+label::after {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
}

.radio input[type="radio"]:disabled+label {
    opacity: 0.65;
}

.radio input[type="radio"]:disabled+label::before {
    cursor: not-allowed;
}

.radio.radio-inline {
    margin-top: 0;
}

.radio-primary input[type="radio"]+label::after {
    background-color: #337ab7;
}

.radio-primary input[type="radio"]:checked+label::before {
    border-color: #337ab7;
}

.radio-primary input[type="radio"]:checked+label::after {
    background-color: #337ab7;
}

.radio-danger input[type="radio"]+label::after {
    background-color: #d9534f;
}

.radio-danger input[type="radio"]:checked+label::before {
    border-color: #d9534f;
}

.radio-danger input[type="radio"]:checked+label::after {
    background-color: #d9534f;
}

.radio-info input[type="radio"]+label::after {
    background-color: #5bc0de;
}

.radio-info input[type="radio"]:checked+label::before {
    border-color: #5bc0de;
}

.radio-info input[type="radio"]:checked+label::after {
    background-color: #5bc0de;
}

.radio-warning input[type="radio"]+label::after {
    background-color: #f0ad4e;
}

.radio-warning input[type="radio"]:checked+label::before {
    border-color: #f0ad4e;
}

.radio-warning input[type="radio"]:checked+label::after {
    background-color: #f0ad4e;
}

.radio-success input[type="radio"]+label::after {
    background-color: #5cb85c;
}

.radio-success input[type="radio"]:checked+label::before {
    border-color: #5cb85c;
}

.radio-success input[type="radio"]:checked+label::after {
    background-color: #5cb85c;
}

/* custom radio */

input[type="radio"].styled:checked+label:after {
    font-family: "FontAwesome";
    content: "";
}

input[type="radio"] .styled:checked+label::before {
    color: #fff;
}

input[type="radio"] .styled:checked+label::after {
    color: #fff;
}

/* custom checkboxes goes here */

.custom-checkbox {
    position: relative;
}

.custom-checkbox input {
    opacity: 0;
    z-index: 4;
    top: 9px;
    left: 20px;
}

.custom-checkbox input[type=checkbox]:checked+.checkb {
    border: none;
    background: url("/img/check.png") center center no-repeat;
    background-size: 100%;
    background-color: #1774cc;
    background-size: 8px 7px;
}


.navbar-brand img, .login-sec-a img {
    display: inline-block;
    max-width: 120px;
    vertical-align: middle;
    margin-right: 10px;
    max-height: 60px;
}

/*compile testing*/

ul {
    padding: 0;
    margin: 0;
}

ul li {
    list-style: none;
}

a:hover {
    text-decoration: none;
}

.navbar-default {
    border: none;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
    min-height: 75px;
    margin-bottom: 0;
}

.reset-states, .form-control:focus, .form-control:focus:hover, .form-control:hover, .form-control:active:hover, .form-control:active, .btn-default:focus, .btn-default:focus:hover, .btn-default:hover, .btn-default:active:hover, .btn-default:active, .btn-group.open:focus, .btn-group.open:focus:hover, .btn-group.open:hover, .btn-group.open:active:hover, .btn-group.open:active, .open:focus, .open:focus:hover, .open:hover, .open:active:hover, .open:active, .open .btn-default.dropdown-toggle {
    background: transparent;
    -webkit-box-shadow: none;
    -khtml-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    border-color: #1774cc;
}

.bootstrap-select.btn-group {
    color: #cfcfcf;
}

.bootstrap-select.btn-group .btn {
    color: #cfcfcf;
    padding-right: 20px;
}

.bootstrap-select.btn-group .btn .caret {
    right: 0px;
    border-top: 5px dashed;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    color: #cfcfcf;
}

label {
    font-size: 16px;
    color: #313336;
    padding-top: 7px;
    padding-left: 0;
    font-weight: 400;
}

.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.alert {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    border: none;
    margin: 10px 0;
    padding: 10px 15px;
    color: #ffffff;
}

.alert.alert-danger {
    background: #e6172c;
}

.alert.alert-success {
    background: #30a039;
}

.dialup-modal .modal-dialog {
    z-index: 1041;
    width: 90%;
    height: 90%;
    padding: 0;
    color: #ffffff;
}

.dialup-modal .modal-content {
    padding-top: 5.5%;
    min-height: 100%;
    background: #1774cc;
    padding-bottom: 5%;
    margin-bottom: 5%;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -khtml-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
}

.dialup-modal .modal-header, .dialup-modal .modal-body, .dialup-modal .modal-content, .dialup-modal .modal-footer {
    border: none;
}

.user-picture img {
    background: #cfcfcf;
    width: 60px !important;
    height: 60px !important;
    max-width: 70px;
    vertical-align: middle;
    text-align: center;
    background-image: url("../img/img-icon.png");
    margin-right: 10px;
    border-radius: 40px;
    background-size: cover;
    background-position: center center;
}

.user-picture a, .user-picture .btn-file {
    padding: 0 15px;
    color: #1774cc;
    font-size: 16px;
    margin-top: -2px;
}

.white-circle {
    font-size: 33px;
    background: #ffffff;
    width: 50px;
    height: 50px;
    text-align: center;
    color: #313336;
    padding-top: 8px;
    margin: 0 15px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

/* Radial progress media recommended by Mubashir*/

/*testing compiler*/

@media screen and (max-width: 990px) {
    .progress-radial {
        position: relative;
        width: 190px;
        height: 190px;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        background-color: #1774cc;
        border: 1px solid #fff;
        right: 25px;
    }
    .progress-radial .overlay {
        position: absolute;
        width: 172px;
        height: 173px;
        background-color: #fff;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        margin-left: 8px;
        margin-top: 8px;
        text-align: center;
        line-height: 60px;
        font-size: 12px;
    }
    .progress-radial .overlay .completion-txt {
        font-size: 50px;
        font-weight: bold;
        padding-top: 66px;
        line-height: 40%;
    }
}

.health-history-details {
    display: inline-block;
    width: 100%;
}

.health-history-details .history-field-wraper {
    width: 100%;
    margin: 10px 0;
    display: inline-block;
}

.health-history-details .history-field-wraper .radio label {
    word-break: break-all;
}

.health-history-details .history-field-wraper .btn {
    padding: 10px 40px;
    margin: 0px 5px;
}

.health-history-details .history-field-wraper .smbtn {
    padding: 10px 30px;
}

.listing {
    border: 1px solid #e6e6e6;
    margin: 30px 0 0;
}

.listing ul {
    max-height: 481px;
    overflow-y: auto;
}

.listing ul.with-map {
    width: 50%;
}

.listing li {
    border-bottom: 1px solid #e6e6e6;
}

.listing li.active a {
    background: #f2f2f2;
}

.listing li p {
    line-height: 24px;
}

.listing a {
    padding: 5px 10px;
    display: block;
    color: #313336;
}

.map {
    width: 50%;
    height: 481px;
    overflow: hidden;
}

.show-more-results {
    margin-bottom: 30px;
}

.show-more-results a {
    display: block;
    padding: 10px 0px;
    color: #313336;
    background: #f2f2f2;
}

.jumbotron .listing li a p, .jumbotron .alert p, .jumbotron .tab-content p, .jumbotron .show-more-results a p {
    margin: 0;
}

.btn-primary-transparent.reduced-btn-spacing {
    padding: 12px 20px;
}

.price {
    font-size: 64px;
    font-weight: 900;
}

.costing-visit {
    padding: 10px 20px;
    font-weight: 400;
    color: #ffffff;
    background: #1774cc;
}

.costing-visit sup {
    font-size: 35px;
    font-weight: bold;
    top: -25px;
}

.visit-txt {
    padding-top: 33px;
    display: inline-block;
}

.test-bar {
    min-height: 100px;
}

.progress-bar-b {
    height: 30px;
    background: #f2f2f2;
}

.progress-bar-b span {
    height: 100%;
    display: block;
}

.progress-bar-b i {
    font-size: 24px;
    color: #ffffff;
    margin: 3px 3px 0;
}

.progress-bar-b small {
    font-size: 12px;
    line-height: 12px;
    display: block;
    margin-top: 6px;
}

.video-control .fa {
    font-size: 24px;
    color: #5b6b78;
}

.software-update-check {
    font-size: 200px;
    margin-top: 30px;
    color: #30a039;
}

.microphone-error {
    min-height: 110px;
}

.buffering-bar {
    background: #f2f2f2;
    height: 20px;
    display: block;
    margin-top: 30px;
}

.pointer {
    position: absolute;
    background: #313336;
    width: 10px;
    height: 100%;
    right: 0;
    top: 0;
    cursor: pointer;
}

.pointer.start {
    left: 0;
}

.buuffed-bar {
    display: block;
    background: #1774cc;
    height: 100%;
    width: 0;
    min-width: 3%;
    position: relative;
}

.panel-closed .form-elements {
    display: none;
}

.panel .edit-details {
    display: none;
}

.panel.panel-closed .edit-details {
    display: block;
}

.dashboard-navigation {
    background: #ffffff;
}

.dashboard-navigation {
    .navbar-nav {
        li {
            a {
                color: #313336;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: bold;
                &:hover,
                &:active,
                &:focus {
                    background-color: transparent;
                }
                &:focus {
                    color: $gray-dark !important;
                    &:hover {
                        color: $primary !important;
                    }
                }
            }

            &.active {
                a {
                    color: $primary;
                    background-color: transparent;
                    &:hover,
                    &:active,
                    &:focus {
                        background-color: transparent;
                        color: $primary !important;
                    }
                }
            }
        }
    }
}

.dashboard-navigation .navbar-nav li a:hover, .dashboard-navigation .navbar-nav li a.active {
    color: #30a039;
}

.dashboard-navigation .navbar-brand {
    padding-top: 10px;
    padding-bottom: 10px;
}

/*Intake Accordion work*/

.accordion {
    background: #f2f2f2;
    border: none;
    padding: 10px 20px;
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 16px;
}

.accordion.active .fa-angle-down {
    display: block;
}

.accordion.active .accordion-icon {
    top: 10px;
}

.accordion.active .fa-angle-up {
    display: none;
}

.accordion .fa-angle-down {
    display: none;
}

button.accordion.active, button.accordion:hover {
    background-color: #ddd;
}

div.custom-accordian {
    padding: 0 18px;
    display: none;
    background-color: white;
}

div.custom-accordian.show {
    display: block !important;
}

.accordion-icon {
    display: block;
    position: absolute;
    top: 5px;
    right: 20px;
    font-size: 20px;
}

/*in take accordion work ends...*/

/*partials/Footer scss mozart*/

footer {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
}

footer .navbar-default {
    background: transparent;
}

footer .navbar-default .navbar-right {
    float: none !important;
    width: 100%;
    margin-right: 0;
    text-align: center;
}

footer .navbar-default .navbar-right li {
    display: inline-block;
    float: none;
}

footer .navbar-default a, footer .navbar-default li a {
    font-size: 12px;
}

/* custom toast msg in footer*/

.footer-toast {
    background: #969696;
    /*recompiling*/
    min-height: 70px;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 1;
    border-radius: 5px;
    left: 0;
}

.bottom-marg-off, .previous-visit p {
    margin-bottom: 0px;
}

.bottom-marg-20 {
    margin-bottom: 20px;
}

.bottom-marg-15 {
    margin-bottom: 15px;
}

.bottom-marg-25 {
    margin-bottom: 25px;
}

.margin-space-0 {
    margin: 0px;
}

.top-marg-off, .existing-appointments h1.spacing-top, .existing-appointments .icon-circle.icon-spacing-b {
    margin-top: 0px;
}

.top-marg-off-10 {
    margin-top: -10px;
}

.top-marg-10 {
    margin-top: 10px;
}

.right-marg-off {
    margin-right: 0px;
}

.left-marg-off {
    margin-left: 0px;
}

.left-pad-off {
    padding-left: 0px;
}

.right-pad-off, .test-bar .col-sm-6 {
    padding-right: 0px;
}

.spacing-a {
    padding: 10px 20px;
}

.spacing-b {
    padding: 18px 20px;
}

.top-pad-10 {
    padding-top: 10px;
}

.top-pad-15 {
    padding-top: 15px;
}

.top-pad-20 {
    padding-top: 20px;
}

.top-pad-30 {
    padding-top: 30px;
}

.top-pad-40 {
    padding-top: 40px;
}

.top-pad-60 {
    padding-top: 60px;
}

.top-pad-80 {
    padding-top: 80px;
}

.top-pad-90 {
    padding-top: 90px;
}

.right-margin-10 {
    margin-right: 10px;
}

.right-margin-10 .personal-profile-pic {
    margin-right: 30px;
}

.right-margin-20 {
    margin-right: 20px;
}

.left-margin-10 {
    margin-right: 10px;
}

.left-margin-20 {
    margin-right: 20px;
}

.left-marg-20 {
    margin-left: 20px;
}

.top-margin-4 {
    margin-top: 4px;
}

.top-margin-20, .existing-appointments, .jumbotron .help-popup p.top-margin-20 {
    margin-top: 20px;
}

.top-margin-45 {
    margin-top: 45px;
}

.medium, .font-weight-normal {
    font-weight: 400;
}

.thick {
    font-weight: 600;
}

.bold {
    font-weight: 900;
}

.white-color, body.page-not-found footer .navbar-default .navbar-brand, body.page-not-found footer .navbar-default li a {
    color: #ffffff;
}

.green-color {
    color: #30a039;
}

.success-bg {
    background: #30a039;
}

.danger-bg {
    background: #e6172c;
}

.pink-color {
    color: #e6172c;
}

.secondary-grey {
    color: #5b6b78;
}

.blue-bg, body.page-not-found {
    background: #1774cc;
}

.font-size-18 {
    font-size: 16px;
}

.font-size-eighteen {
    font-size: 18px;
}

.font-size-22 {
    font-size: 20px;
}

.font-size-30 {
    font-size: 28px;
}

.font-size-35 {
    font-size: 33px;
}

.font-size-48 {
    font-size: 48px;
}

.font-size-55 {
    font-size: 53px;
}

.font-size-66 {
    font-size: 64px;
}

.card {
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2) 2px 5px rgba(0, 0, 0, 0.4);
    -khtml-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2) 2px 5px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2) 2px 5px rgba(0, 0, 0, 0.4);
    -ms-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2) 2px 5px rgba(0, 0, 0, 0.4);
    -o-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2) 2px 5px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2) 2px 5px rgba(0, 0, 0, 0.4);
    background: #ffffff;
}

.img-radius {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.display-inline, .user-panel img, .clynder {
    display: inline-block;
}

.text-underline {
    text-decoration: underline;
}

.text-decoration-none {
    text-decoration: none;
}

.panel {
    border: 8px solid #e6e6e6;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    padding: 30px 30px 30px 40px;
    margin-bottom: 20px;
    &.terms {
        p {
            @extend .lead;
            @extend .fw-normal;
            @extend .m-b15;
        }
    }
}

.panel-closed {
    background: #f2f2f2;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    padding: 20px 20px 20px 40px;
    margin-bottom: 20px;
}

/* Gloabaly used padding*/

.padding-10 {
    padding: 10px;
}

.padding-20 {
    padding: 20px;
}

a.remove-hover:hover {
    cursor: default;
    color: inherit !important;
}

select.form-control.placeholder, select.form-control option {
    color: #313336;
}

select.form-control {
    color: #cfcfcf;
}

.login-inner-container {
    max-width: 524px;
}

.page-not-found {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background: #1774cc;
    left: 0;
}

/*# sourceMappingURL=main.css.map */

.visit-a-doc .list-group-item p.list-group-item-text {
    margin: 0;
    font-size: 14px;
}

.panel .width-full {
    width: auto;
}

.panel.panel-closed .width-full {
    width: 66.66667%;
}

.error {
    color: #e6172c;
    /* #a94442; */
    font-size: 14px;
}

footer .navbar-brand {
    padding-top: 30px;
}

/*# sourceMappingURL=main.css.map */

/* custom checkboxes goes here */

.terms li, .policy p, .policy li, .policy address {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-left: 20px;
}

.terms ol ol li {
    list-style: inherit;
}

.terms h3, .policy h3 {
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
}

.policy p {
    /* margin-left: 0;
    margin-bottom: 0; */
    margin: 0 0 20px 0;
}

.policy address {
    margin-top: 10px;
}

.policy h3 {
    margin: 30px 0 10px;
}

/*# sourceMappingURL=main.css.map */

span.checkb {
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    background: white;
    border: 2px solid #5b6b78;
    top: 12px;
    z-index: 2;
    left: 0px;
    border-radius: 2px;
}

.vertical-top {
    vertical-align: top;
}

.sm-font-txt {
    font-size: 10px;
}

.ma-list-style-default {
    padding: 0 15px;
}

.ma-list-style-default li {
    list-style-type: initial;
}

.ma-list-style-dash li:before {
    content: "-";
    font-size: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.ma-list-style-dash p {
    display: inline-block;
}

.full-width {
    width: 100%;
}

.border-bottom {
    border-bottom: 1px solid #dadada;
}

.pos-rel {
    position: relative;
}

.pos-abs {
    position: absolute;
}

.remove-padding {
    padding: 0 !important;
}

.remove-margin {
    margin: 0 !important;
}

.remove-border {
    border: none !important;
}

/*For INput Phone full width*/

.ph-full-width .intl-tel-input {
    width: 100%;
}

/*testing compiling*/

/* random styles that were placed after the scss was destroyed... */

.scheduled .consultation {
    min-height: 50px;
    height: auto;
}

.personal-profile-pic {
    width: 125px;
    height: 125px;
}

.right-margin-10 .personal-profile-pic {
    margin-right: 30px;
}

#changePassword.panel .fa-angle-down, #changePassword.panel.panel-closed .fa-angle-up {
    display: none;
}

#changePassword.panel.panel-closed .fa-angle-down {
    display: inline-block;
}

#changePassword a {
    color: #313336;
}

.pagination li.per-page:hover span {
    color: #337ab7;
}

.pagination li.per-page span {
    background: transparent;
    border: none;
}

.btn-primary-round {
    background: #1296E7;
    color: #ffffff;
    font-size: 32px;
    padding: 13px 25px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    min-width: auto;
}

.btn-primary-round:hover {
    background: #3a89d4;
    transition: all 0.3s ease;
    color: #fff;
}

.btn-primary-round:active {
    background: #5197d9;
}

.question-icon {
    font-size: 30px;
    margin-top: -11px;
    position: relative;
    top: 8px;
    margin-right: 7px;
}

.form-control {
    border: none;
    border-bottom: 2px solid #e6e6e6;
    color: #313336;
    font-size: 16px;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    -khtml-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    height: 40px;
    padding-left: 0;
}

.form-control::-webkit-input-placeholder {
    color: #ABABAB;
}

.form-control:-moz-placeholder {
    /* Firefox 18- */
    color: #ABABAB;
}

.form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: #ABABAB;
}

.form-control:-ms-input-placeholder {
    color: #ABABAB;
}

textarea {
    resize: none;
}

.placeholder-animation {
    position: relative;
    background: #fff;
    margin-top: 30px;
}

#PartialGeneralConsult .placeholder-animation {
    @media (max-width: 767px) {
        margin-top: 55px;
    }
}


.placeholder-animation .form-control {
    z-index: 1;
    position: relative;
    background: transparent;
    padding: 12px 0;
    height: 49px;
}

input:-internal-autofill-selected {
    background-color: none;
}

input:-internal-autofill-selected {
    background-color: none;
}

.top-label label,
.placeholder-animation .form-control:focus+label {
    top: -25px;
    font-size: 12px;
    line-height: 14px;
}

.top-label label {
    top: -25px !important;
}

.placeholder-animation label {
    position: absolute;
    top: 0;
    transition: all 0.3s;
    z-index: 0;
    color: #999;
    left: 0;
}

.placeholder-animation.hide-placeholder label {
    display: none;
}

.placeholder-animation.has-data label {
    top: -25px;
    font-size: 12px;
}

.placeholder-animation.col-sm-3 select~label {
    padding-left: 20px;
}

.checkbox label {
    word-wrap: break-word;
}

.custom-form.jumbotron {
    background: #ffffff;
    padding: 40px;
    margin-bottom: 0;
}

.custom-form.custom-padding, .custom-form.customclass {
    padding: 20px;
}

.custom-form.custom-padding .font-weight-normal, .custom-form.customclass .font-weight-normal {
    font-weight: 400;
    font-size: 16px;
}

.custom-form form {
    margin-top: 40px;
}

.custom-form .form-group {
    margin-bottom: 26px;
}

.custom-form .no-margin {
    margin-top: 0
}

.custom-form .form-group.reduce-space {
    margin-bottom: 18px;
}

@media (max-width: 767px) {
    .header-content h1 {
        font-size: 25px;
        margin: 24px 0;
        line-height: 1.1;
    }
}

.header-content h2.top-margin-20, .header-content h2.existing-appointments, .header-content h2.existing-appointments {
    margin-top: 20px;
}

.top-margin-80 {
    margin-top: 80px;
}

.previous-visit {
    padding: 20px;
    border-bottom: 1px solid #dadada;
}

.user-panel img {
    max-width: 60px;
}

.profile-info {
    vertical-align: middle;
    max-width: 80%;
}

.clynder {
    color: #313336;
    padding: 10px 60px 10px 40px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
    margin-right: -40px;
    margin-top: 5px;
    position: relative;
    top: 0;
    z-index: 0;
}

.custom-mobile {
    @media (max-width: 767px) {
        display: block;
    }
}

.card.user-panel {
    .clynder {
        @media (max-width: 992px) and (min-width: 768px) {
            padding: 10px 0px 10px 19px;
            margin-right: 0;
        }
        @media (max-width: 767px) {
            margin-bottom: 0;
        }
    }
}

.user-panel .btn {
    position: relative;
}

@media (max-width: 992px) and (min-width: 768px) {
    .user-panel .pull-right {
        display: flex;
        align-items: center;
    }
    .user-panel .btn {
        min-width: auto;
    }
}

@media (max-width: 480px) {
    .header-content h2 {
        margin: 10px 0 8px;
        width: 100%;
    }
    p.safeopacity {
        margin: 0 !important;
    }
    .consultation .btn {
        &.custom-arrow {
            margin-left: -20px;
        }
    }
}

.existing-appointments h1 {
    margin: 10px 0 20px;
    font-size: 46px;
    vertical-align: middle;
    display: inline-block;
    margin-left: 20px;
    max-width: 67%;
}

.appointment-detail-card .existing-appointments h1 {
    font-size: 33px;
    margin: 0 0 36px;
}

.custom-dashboard h1 {
    font-size: 24px;
}

.existing-appointments .spacing {
    padding: 20px 30px;
}

.existing-appointments .spacing-c {
    padding: 10px 40px 0px;
}

.existing-appointments .icon-circle {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    padding: 5px 0;
    width: 64px;
    height: 64px;
    vertical-align: middle;
    display: inline-block;
    position: relative;
    span {
        font-size: 30px !important;
    }
}

.custom-dashboard .icon-circle {
    width: 64px;
    height: 64px;
    padding: 0;
    span {
        font-size: 30px !important;
    }
}

.custom-dashboard .icon-circle {
    width: 65px;
    height: 65px;
    padding: 0;
    span {
        font-size: 30px !important;
    }
}

.existing-appointments .icon-circle span {
    font-size: 48px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.existing-appointments .icon-circle.icon-spacing {
    padding-top: 46px;
    margin: 10px 0px 35px 0;
}

.existing-appointments .icon-circle.icon-spacing-c {
    margin-bottom: 10px;
}

.existing-appointments .icon-circle.small-icon {
    padding: 40px 46px 5px;
    width: 140px;
    height: 140px;
}

.errorMsg {
    display: none;
    color: red;
}

@media (min-width: 1600px) {
    .texttual-content img {
        display: inline-block;
    }
}

.help-icon {
    width: 23px;
    height: 23px;
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    background: url("../img/help-icon.png");
}

.help-popup b {
    color: #1774cc;
}

.tab-content>.active {
    position: relative;
}

.user-picture img {
    background: #cfcfcf;
    width: auto;
    height: auto;
    max-width: 70px;
    vertical-align: middle;
    text-align: center;
    background-image: url("../img/img-icon.png");
    margin-right: 10px;
}

.user-picture a, .user-picture .btn-file {
    padding: 0 15px;
    color: #1774cc;
    font-size: 16px;
    margin-top: -2px;
}

.white-circle {
    font-size: 33px;
    background: #ffffff;
    width: 50px;
    height: 50px;
    text-align: center;
    color: #313336;
    padding-top: 8px;
    margin: 0 15px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

/* Radial progress media recommended by Mubashir*/

/*testing compiler*/

@media screen and (max-width: 990px) {
    .progress-radial {
        position: relative;
        width: 190px;
        height: 190px;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        background-color: #1774cc;
        border: 1px solid #fff;
        right: 25px;
    }
    .progress-radial .overlay {
        position: absolute;
        width: 172px;
        height: 173px;
        background-color: #fff;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        margin-left: 8px;
        margin-top: 8px;
        text-align: center;
        line-height: 60px;
        font-size: 12px;
    }
    .progress-radial .overlay .completion-txt {
        font-size: 50px;
        font-weight: bold;
        padding-top: 66px;
        line-height: 40%;
    }
}

/*Health history */

.visit-a-doc-step3-txt p {
    font-size: 16px;
    text-align: left;
    font-weight: normal;
}

.visit-a-doc-step3-txt p span {
    font-weight: 600;
    width: 100%;
    margin: 15px;
    text-align: center;
}

/*Health history ends*/

/*Login Scss Starts here... */

.login-bg {
    background: url("../img/login-bg.png") no-repeat;
    background-size: cover;
    background-position: center center !important;
}

.login-wrapper {
    margin: 85px 0 90px;
    width: 99.99%;
    overflow: hidden;
}

@media (min-width: 480px) and (max-width: 768px) {
    .login-wrapper {
        overflow: visible;
    }
}

.login-wrapper .login-sec-a {
    padding: 100px 60px 0 80px;
    height: 590px;
    max-height: 100%;

    @media (max-width: 767px) {
        padding: 100px 60px 60px 60px !important;
    }
}

@media (min-width: 480px) and (max-width: 768px) {
    .login-wrapper .login-sec-a {
        padding: 30px 30px 0 30px;
    }
}

.login-wrapper .login-sec-a>a {
    display: inline-block;
    height: 49px;
}

.login-wrapper .login-sec-a h1 {
    font-size: 32px;
    margin-top: 8px;
    margin-bottom: 15px;
}

@media (min-width: 480px) and (max-width: 768px) {
    .login-wrapper .login-sec-a h1 {
        font-size: 28px;
    }
}

.login-wrapper .login-sec-a .btn-success {
    width: 170px;
    margin-bottom: 22px;
}

.login-wrapper .login-sec-a span {
    display: inline-block;
    line-height: 16px;
}

@media (min-width: 480px) and (max-width: 768px) {
    .login-wrapper .login-sec-a span {
        margin-top: 20px;
    }
}

.login-wrapper .login-sec-a span.error {
    font-size: 14px;
}

.login-wrapper .login-footer {
    padding: 20px 50px;
}

.login-wrapper .login-sec-b {
    padding: 85px 25px 0 25px;
    background: #1774cc;
}

@media (min-width: 1600px) {
    .login-wrapper .login-sec-b {
        padding: 85px 25px 0 25px;
    }
}

.login-wrapper .login-sec-b h1 {
    color: #ffffff;
    font-size: 20px;
    line-height: 34px;
    font-weight: 600;
    margin: 0;
}

.login-wrapper .login-sec-b p {
    margin-top: 25px;
    color: #ffffff;
    font-weight: 400;
    margin-bottom: 20px;
}

.login-wrapper .login-sec-b ul li {
    display: inline-block;
}

.login-wrapper .login-sec-b ul li span {
    display: inline-block;
    min-width: 45px;
}

.login-wrapper .login-sec-b ul li .material-icons,
.login-wrapper .login-sec-b ul li .fa {
    font-size: 45px;
    color: #ffffff;
}

.login-wrapper .login-sec-b ul li p {
    display: inline-block;
    width: 60%;
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 15px;
}

.login-wrapper .login-sec-b label {
    font-size: 10px;
    width: 116px;
    color: #ffffff;
    margin: 45px 10px 20px 0;
    padding-top: 5px;
}

.login-sec-a a img {
    max-width: 100%;
}

.login-footer .navbar-right, .login-footer .navbar-nav li {
    float: none !important;
    display: inline-block;
}

/*Login Scss Starts here... */

.login-wrapper, .login-wrapper .login-sec-b {
    min-height: 681px;
}

.login-wrapper, .login-wrapper .login-sec-b {
    height: 681px;
}

@media (min-width: 480px) and (max-width: 768px) {
    .login-wrapper, .login-wrapper .login-sec-b {
        height: auto;
    }
}

.login-wrapper.left-wrapper, .login-wrapper.right-wrapper {
    height: 100%;
}

@media (min-width: 480px) and (max-width: 768px) {
    .login-wrapper.left-wrapper, .login-wrapper.right-wrapper {
        height: auto;
    }
}

.login-footer.navbar-default .navbar-brand {
    color: #ffffff;
    padding-top: 30px;
}

.login-footer.navbar-default .navbar-nav>li>a {
    color: #ffffff;
    font-family: 'Open Sans';
}

/* Welcome Note Screen starts..*/

.ma-greeting-note-wrapper {
    background: url("../img/thanks-note-bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 80px 90px 110px 120px;
}

.ma-greeting-note-wrapper h1 {
    color: #ffffff;
}

.ma-greeting-note-wrapper p {
    color: #ffffff;
    font-size: 20px;
}

.ma-greeting-note-wrapper h1.gray-dark-text {
    color: #313336;
}

.ma-greeting-note-wrapper p.gray-dark-text {
    color: #313336;
}

.ma-greeting-note-wrapper.expanded {
    padding-left: 30px;
    padding-right: 30px;
}

/*thanks note ratings section*/

.rating-ul {
    color: #004d40;
}

.rating-ul li {
    display: inline-block;
    margin: 0 15px;
}

.rating-ul li a {
    font-size: 25px;
    color: #c0c0c0;
}

.rating-ul li a.active {
    color: #30a039;
}

/*thanks note ratings section*/

/* Welcome Note Screen ends..*/

.bubble {
    width: 307px;
    height: 307px;
    background: #ffffff;
    position: relative;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
}

.bubble .user-img {
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
    margin: 28px 28px 0;
    width: 250px;
    height: 250px;
    border: 1px solid #dadada;
}

.bubble .user-img img {
    width: 250px;
    height: 250px;
}

.bubble:before {
    content: "";
    position: absolute;
    right: 262px;
    bottom: 26px;
    width: 0;
    height: 0;
    border-top: 33px solid transparent;
    border-right: 48px solid #fff;
    border-bottom: 33px solid transparent;
    -webkit-transform: rotate(-37deg);
    -khtml-transform: rotate(-37deg);
    -moz-transform: rotate(-37deg);
    -ms-transform: rotate(-37deg);
    -o-transform: rotate(-37deg);
    transform: rotate(-37deg);
}

.call-btn {
    width: 321px;
}

.call-btn .fa {
    margin-right: -14px;
}

.center {
    display: table;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
}

.center .col-sm-5, .center .col-sm-7 {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.ma-vh-ul {
    width: 100%;
}

.ma-vh-ul li {
    border-bottom: 1px solid #dadada;
    list-style: none;
}

.ma-vh-pagination {
    width: 100%;
    margin: 10px 0;
}

.ma-vh-pagination li a {
    color: #1774cc;
    border: none;
}

.ma-vh-pagination li a.active, .ma-vh-pagination li a:hover {
    color: #30a039;
    background: none;
}

.ma-vh-pagination li ul {
    margin: 0;
}

.ma-vh-pagination li ul li {
    margin: 0;
    display: inline-block;
}

.ma-vh-account-ul li {
    margin: 10px 0;
}

.ma-vh-account-ul li.active, .ma-vh-account-ul li:hover {
    color: #30a039;
}

.ma-vh-account-ul li.active a, .ma-vh-account-ul li:hover a {
    color: #30a039;
}

.ma-vh-account-ul li a {
    color: #5b6b78;
}

.ma-vh-account-ul li a i {
    font-size: 24px;
    width: 25px;
}

.ma-vh-account-ul li a span {
    font-size: 16px;
    padding-left: 20px;
    vertical-align: top;
}

/*Visit Details A&B*/

.ma-vd-btn-pdf {
    margin-top: 25px;
    width: 100%;
}

.ma-vd-btn-pdf i {
    font-size: 16px;
    margin-left: -9px;
    margin-right: 3px;
}

.ma-vh-btn {
    margin: 60px 0 0;
}

.ma-vh-btn i {
    padding-right: 20px;
    margin-left: -35px;
    font-size: 20px;
}

.ma-upload-actions {
    top: 55px;
    left: 170px;
}

.nav-tabs.tabs-left {
    border-bottom: none;
    border: none;
}

.nav-tabs.tabs-left>li {
    float: none;
    margin-bottom: 10px;
    margin-right: -1px;
}

.nav-tabs.tabs-left>li>a {
    text-align: left;
    border-radius: 4px;
    font-size: 14px;
    margin-right: 0;
    display: block;
    border: none;
    background: #5197d9;
    color: #fff;
    border: 8px solid #5197d9;
}

@media (min-width: 1600px) {
    .nav-tabs.tabs-left>li>a {
        padding: 28px 0px 28px 10px;
    }
}

.nav-tabs.tabs-left>li>a:hover {
    background: #1774cc;
    color: #fff;
    border: 8px solid #1774cc;
}

.nav-tabs.tabs-left>li>a .software {
    background-position: -33px 0px;
}

.nav-tabs.tabs-left>li>a .video-icon {
    background-position: -71px 0px;
}

.nav-tabs.tabs-left>li>a .mic {
    background-position: -106px 0px;
}

.nav-tabs.tabs-left>li>a .sound {
    background-position: -136px 0px;
}

.nav-tabs.tabs-left>li>a .custom-icons {
    width: 28px;
}

.nav-tabs.tabs-left>li.active>a {
    background: #fff;
    color: #1774cc;
    border: 8px solid #1774cc;
}

.nav-tabs.tabs-left>li.active>a .wifi {
    background-position: 0 -31px;
}

.nav-tabs.tabs-left>li.active>a .software {
    background-position: -33px -31px;
}

.nav-tabs.tabs-left>li.active>a .video-icon {
    background-position: -71px -31px;
}

.nav-tabs.tabs-left>li.active>a .mic {
    background-position: -106px -31px;
}

.nav-tabs.tabs-left>li.active>a .sound {
    background-position: -136px -31px;
}

.nav-tabs.tabs-left>li .notready, .nav-tabs.tabs-left>li .ready {
    display: none;
    position: absolute;
    left: 51px;
    bottom: 12px;
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
}

.nav-tabs.tabs-left .connection-success a {
    background: #30a039;
    border-color: #30a039;
}

.nav-tabs.tabs-left .connection-success a .ready {
    color: #30a039;
    display: inline-block;
}

.nav-tabs.tabs-left .connection-faliure a {
    background: #ec5161;
    border-color: #ec5161;
}

.nav-tabs.tabs-left .connection-faliure a .notready {
    display: inline-block;
    color: #e6172c;
}

.wifi {
    height: 19px;
}

.wifi.placement {
    top: 2px;
}

.software {
    height: 24px;
}

.software.placement {
    top: 5px;
}

.video-icon {
    height: 16px;
}

.video-icon.placement {
    top: 2px;
}

.mic {
    height: 27px;
}

.mic.placement {
    top: 5px;
}

.sound {
    height: 26px;
}

.sound.placement {
    top: 7px;
}

.placement {
    margin-right: 10px;
    position: relative;
}

.custom-icons {
    background: url("../img/sprites.png");
    background-size: 1024px 681px;
    display: inline-block;
}

.video-controls {
    margin-top: -17px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.7+0,0.8+100 */
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.8) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.8) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.8) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3000000', endColorstr='#cc000000', GradientType=0);
    /* IE6-9 */
    padding: 0 60px;
}

.video-controls li {
    display: inline-block;
    padding: 4px 25px 3px;
}

.video-controls li:first-child {
    padding-left: 0;
}

.video-controls li:last-child {
    padding-right: 0;
}

.video-controls li button {
    background: none;
    padding: 0;
    margin: 0;
    border: none;
}

/*# sourceMappingURL=main.css.map */

.busymodal {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.8) url("../../loading.gif") 50% 50% no-repeat;
}

/* When the body has the loading class, we turn
 the scrollbar off with overflow:hidden */

.loader.loading {
    overflow: hidden;
}

/* Anytime the body has the loading class, our
 modal element will be visible */

.loader.loading .busymodal {
    display: block;
}

/*# sourceMappingURL=main.css.map */

.help-popup .modal-footer {
    text-align: center;
    position: relative;
    z-index: 1;
    border: none;
}

.help-popup .modal-footer img {
    max-width: 100%;
}

.help-popup .col-sm-2 {
    position: relative;
    height: 100px;
    z-index: 0;
}

.help-popup .col-sm-2 img {
    position: absolute;
    top: 0;
    transition: all 0.3s;
    z-index: 0;
    color: #999;
    left: 0;
}

.help-popup .col-sm-2 img.chrome-download {
    top: 395%;
    left: 20px;
}

.placeholder-animation.hide-placeholder label {
    display: none;
}

.placeholder-animation .form-control:focus+label, .placeholder-animation.has-data label {
    top: -25px;
    font-size: 12px;
}

.col-sm-3.placeholder-animation select~label {
    padding-left: 20px;
}

#changePassword.panel .fa-angle-down, #changePassword.panel.panel-closed .fa-angle-up {
    display: none;
}

#changePassword.panel.panel-closed .fa-angle-down {
    display: inline-block;
}

#changePassword a {
    color: #313336;
}

.pagination li.per-page:hover span {
    color: #337ab7;
}

.pagination li.per-page span {
    background: transparent;
    border: none;
}

.phonecallselected {
    background-color: #30a039;
}

.phonecallselected b {
    color: #ffffff;
}

.speedTest-failure {
    margin: 125px 0;
}

.speedTest-failure {
    margin: 125px 0;
}

.custom-container {
    width: 80%;
    margin: 0 auto;
    float: none;
}

.custom-container .card {
    padding-left: 20px;
    padding-right: 20px;
    margin: 20px 0;
    border: 1px solid #c8cfd3;
    border-radius: 4px;
}

.custom-container pre {
    background: transparent;
    font-family: 'Open Sans';
    border: none;
    padding: 0;
    font-size: 12px;
}

.custom-container .previous-visit {
    padding: 0;
}

.thin {
    font-weight: 400;
}

.custom-container p {
    margin: 0 0 5px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 10px;
}

.custom-container hr {
    margin: 5px 0 0;
}

.custom-container div:nth-last-child(2) {
    margin: 0 0 5px;
    font-size: 13px;
    font-weight: 500;
}

.custom-container textarea {
    margin-top: 5px;
    margin-bottom: 5px;
}

.tempsdfssdf rateit {
    width: 25px;
}

.spacing-children label {
    margin-right: 30px;
}

.spacing-children input {
    margin-right: 5px;
}

@media only screen and (max-width: 700px) {
    .custom-container textarea {
        width: auto;
        min-width: 1px;
        max-width: 100%;
    }
    .custom-container {
        width: auto;
    }
    .custom-container .rateit .rateit-range {
        background-size: 18px;
        height: 17px !important;
        width: auto !important;
        min-width: 181px;
    }
    .custom-container pre {
        word-break: normal;
        white-space: inherit;
    }
    .custom-container {
        width: auto;
    }
    .custom-container h3 {
        font-size: 18px;
    }
    .custom-container p {
        font-size: 12px;
        font-weight: 500;
    }
    .custom-container .rateit .rateit-range {
        background-size: 18px;
        height: 17px !important;
        width: auto !important;
        min-width: 181px;
    }
    .custom-container .rateit .rateit-selected {
        background-size: 18px;
        height: 17px !important;
        max-width: 180px;
    }
}

.login-language-select select, .language-select select {
    background: silver;
    color: #7d7d7d;
    border: none;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    width: auto;
    height: 19px;
}

.login-language-select select option, .language-select option {
    background: #fff;
    color: #232323;
}

.login-inner-container p {
    margin-bottom: 19px;
}

.login-language-select select, .language-select select {
    background: silver;
    color: #7d7d7d;
    border: none;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    width: auto;
    height: 19px;
}

.login-language-select select option, .language-select option {
    background: #fff;
    color: #232323;
}

.language-select {
    float: right;
}

.navbar-brand img {
    max-width: 213px;
}

.dashboard-navigation .navbar-brand {
    line-height: 56px;
}

.login-language-select select, .language-select select {
    background: #fff;
    color: #333;
    border: none;
    font-size: 13px;
    font-weight: 500;
    width: auto;
    padding: 6px;
    height: 30px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.075);
    border: 1px solid #B3B3B3;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
}

.login-language-select {
    position: fixed;
    right: 0;
    top: 4px;
    z-index: 1000;
}

.login-inner-container .form-group {
    margin-bottom: 8px;
}

footer .navbar-default li a {
    padding-top: 44px;
    padding-bottom: 0;
    padding-left: 16px;
}

.navbar-default li a {
    padding-top: 30px;
    padding-bottom: 30px;
}

#profilePhoto {
    margin-right: 15px;
    width: 60px;
    height: 60px;
    border-radius: 40px;
    background-size: cover;
    background-position: center center;
    overflow: auto;
    display: inline-block;
    vertical-align: middle;
}

#personal-profile {
    margin-right: 15px;
    width: 125px;
    height: 125px;
    border-radius: 74px;
    background-size: cover;
    background-position: center center;
    overflow: auto;
    display: inline-block;
    vertical-align: middle;
}

@media only screen and (max-width: 480px) {
    .language-select select {
        position: absolute;
    }
    .existing-appointments .spacing-c {
        padding: 10px 20px 0px;
    }

    .custom-dashboard .btn {
        width: 100%;
        white-space: normal !important;
        margin-top: 16px;
    }
}

.custom-label {
    color: #999;
}

/* this tis the CSS for the adds instead of jpegs*/

.help-instructions {
    background: #363636;
    position: relative;
    padding: 10px 60px 20px;
    width: 562px;
    text-align: center;
    margin: 0 auto;
}

.help-instructions h3 {
    font-weight: 500;
    color: #fff;
    line-height: 25px;
    margin: 15px 0;
    font-size: 18px;
}

.checkbox-box {
    width: 15px;
    height: 15px;
    background: #fff;
    border: 1px solid rgb(54, 54, 54);
    display: inline-block;
}

.accept {
    font-size: 12px;
    color: #ababab;
}

.download-plugin {
    background: #296c89;
    padding: 15px;
    font-size: 20px;
    margin: 15px 0 10px;
    font-weight: 300;
    text-decoration: none;
    display: inline-block;
    min-width: 200px;
}

.download-plugin, .download-plugin:hover {
    color: #fff;
}

.cancel, .cancel:hover {
    color: #ababab;
}

.cancel {
    text-decoration: none;
    background: #444444;
    padding: 10px;
    min-width: 200px;
    font-size: 14px;
    margin: 10px 0;
    font-weight: 300;
    display: inline-block;
}

.end-user, .end-user:hover {
    color: #1774cc;
    text-decoration: underline;
}

.close-instructions {
    position: absolute;
    top: 4px;
    right: 10px;
    font-size: 16px;
}

.run .close-instructions {
    color: #7d7d7d;
}

.close-instructions, .close-instructions:hover {
    color: #ababab;
}

.download-plugin.dark {
    background: #1ca3db;
}

.run {
    background: #fead13;
    padding: 13px 0;
    text-align: center;
    font-size: 13px;
    border-bottom: 3px solid #000;
    margin: 10px auto;
    width: 562px;
    position: relative;
}

.grey-btn {
    display: inline-block;
    border: 1px solid #adadad;
    font-size: 12px;
    padding: 3px 20px;
    margin: 5px 4px 0;
}

.grey-btn, .grey-btn:hover, .grey-btn.dropdown a, .grey-btn:hover.dropdown a {
    background: #efefef;
    color: #000;
}

.caret-wrap {
    display: inline-block;
    border: 1px solid #adadad;
    background: #efefef;
    margin-left: -7px;
    padding: 2px 5px 3px;
}

.caret-wrap .caret {
    margin-top: -1px;
    margin-left: 0px;
}

.run-white {
    background: #fff;
    border: 1px solid #363636;
}

.run-white .inner {
    border-top: 6px solid #fead13;
    font-size: 12px;
    padding: 5px 10px;
}

.inner .grey-btn {
    margin: 3px 4px 0;
}

.inner .text-left {
    text-align: left;
}

.inner .caret-wrap {
    padding-top: 3px;
}

.runorsave {
    display: inline-block;
    padding-top: 3px;
}

.inner .close-instructions {
    top: 0px;
}

.vidyo-extension-chrome {
    background: #fff;
    padding: 5px;
    width: 592px;
    margin: 0 auto;
}

.vidyo-extension-chrome h3 {
    margin: 0;
}

.add-to-chrome-btn, .share-link-icon {
    border: 1px solid #406db7;
    padding: 4px 13px;
    font-size: 12px;
    text-align: center;
    border-radius: 2px;
}

.add-to-chrome-btn, .add-to-chrome-btn:hover, .share-link-icon, .share-link-icon:hover {
    background: #5789db;
    color: #fff;
}

.share-link-icon {
    margin-left: 2px;
}

.vidyo-extension-chrome small {
    font-size: 11px;
}

.social-communication {
    display: inline-block;
    padding: 0 5px;
    font-size: 12px;
    border-right: 1px solid #d4d4d5;
    border-left: 1px solid #d4d4d5;
}

img.width-full {
    width: 100%;
}

.vidyo-safari-extension {
    background: #ececec;
    border-radius: 10px;
    width: 360px;
    margin: 0 auto;
}

.downloads-txt {
    position: relative;
    text-align: center;
    font-size: 12px;
    color: #717171;
    font-weight: bold;
    padding: 3px 3px 16px;
}

.clear-btn, .clear-btn:hover {
    background: #fff;
    color: #000;
    border: 1px solid #c8c8c8;
}

.clear-btn {
    display: inline-block;
    padding: 1px 10px;
    border-radius: 6px;
    font-size: 14px;
    position: absolute;
    right: 10px;
}

.time-remaining {
    position: relative;
}

.second-remaining {
    position: absolute;
    color: #717171;
    font-size: 12px;
    left: 147px;
    top: 23px;
    font-weight: bold;
}

.vidyo-firefox-extension {
    background: #dfdfdf;
    margin: 0 auto;
    padding-right: 10px;
    width: 721px;
}

.show-downloads {
    padding-top: 10px;
    color: #000;
    font-size: 12px;
}

.show-downloads small {
    display: inline-block;
    margin-left: 10px;
    margin-right: 20px;
    text-decoration: underline;
}

.margin-remove {
    margin: 0;
}

.ff-download {
    width: 448px;
    height: 210px;
    margin: 0 auto;
    position: relative;
}

.ff-download-bg {
    position: absolute;
    border-radius: 8px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.top-ff-download {
    padding-top: 6px;
    font-size: 13px;
    font-weight: bold;
    z-index: 4;
    color: #000;
    padding-left: 8px;
    width: 393px;
    position: absolute;
    left: 0;
}

.body-ff-download {
    font-size: 14px;
    color: #000;
    position: relative;
    z-index: 3;
    padding: 40px 11px 0 25px;
    width: 433px;
}

.file-name-spacing {
    padding-left: 23px;
}

.wouldyou {
    padding-top: 7px;
    display: inline-block;
}

.ff-decision-btn {
    border: 1px solid #707070;
    font-size: 13px;
    padding: 6px 16px;
    border-radius: 3px;
    margin-top: 10px;
    display: inline-block;
    margin-left: 5px;
}

.ff-decision-btn, .ff-decision-btn:hover {
    background: #e8e8e8;
    color: #000;
}

/* NEW SCREENS */

.consultation.border {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.consultation {
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: hidden;
}

.custom-dashboard .consultation{
    margin-bottom: 0;
}

.black-color {
    color: #313336;
}

.speciality {
    max-width: 435px;
    margin: 40px auto 0;
}

select.blue {
    color: #1774cc !important;
}

.speciality select {
    margin-bottom: 60px;
}

select.blue option {
    font-size: 16px;
}

.speciality a {
    margin-left: 20px;
    min-width: 120px;
}

.apointment-schedule input, select.blue {
    font-size: 24px;
    height: 45px;
}

.apointment-schedule .btn {
    color: #515151;
    border-bottom: none;
    border-radius: 0;
    position: absolute;
    z-index: 5;
    right: 3px;
    top: 0;
    padding: 11px 5px;
}

.search-by-name {
    position: relative;
}

.search-by-name input {
    padding-right: 30px;
}

.previous-visit .apointment-schedule p {
    margin-top: 15px;
}

.results-found {
    background: #fff;
    padding-top: 15px;
    padding-bottom: 15px;
}

.top-0 {
    top: 0;
    z-index: 1;
}

.count {
    width: 36px;
    height: 36px;
    background: #0099cc;
    font-size: 16px;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    line-height: 35px;
    text-align: center;
    margin-right: 10px;
}

.days a {
    color: #000;
    line-height: 15px;
}

.days li, .days a, .days ul {
    display: inline-block;
    vertical-align: top;
    text-align: center;
}

.not-clickable {
    pointer-events: none;
    cursor: default;
}

.days li {
    width: 110px;
    height: 40px;
    font-weight: bold;
    line-height: 40px;
}

.days li.pill-active {
    background: #e3e3e3;
    border-radius: 20px;
}

.doc-info .media-left {
    width: 110px;
    height: 110px;
}

.doc-info h3 {
    font-weight: bold;
}

.doc-info .media-body {
    padding-left: 20px;
}

.doc-info .media-body a {
    color: #000;
}

.doc-info .media-body p {
    color: #949494;
    margin: 10px 0;
}

.days ul {
    width: 800px;
}

.apointment-timings-wrapper {
    max-height: 200px;
    overflow: auto;
    position: relative;
}

.border-none {
    border: none;
}

.attach-btn input[type="file"] {
    z-index: 999;
    position: absolute;
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: "alpha(opacity=0)";
    cursor: pointer;
    _cursor: hand;
    margin: 0;
    padding: 0;
    left: 0;
}

.add-photo-btn {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.add-photo-btn input {
    display: none;
}

.attached-images {
    padding: 0 20px 20px;
}

.attached-images li {
    display: inline-block;
    margin-bottom: 15px;
    width: 60%;
    position: relative;
}

.attached-images li img {
    width: 100%;
}

.attached-images .remove-img {
    position: absolute;
    right: 8px;
}

.attached-images .remove-img .fa {
    border-radius: 50%;
    background: #4d4d4d;
    color: #fff;
    padding: 5px 6px;
    font-size: 11px;
}

nav .pagination {
    padding: 0 20px;
    font-size: 12px;
    font-weight: bold;
}

nav .pagination a {
    color: #000;
    border: none;
    border-radius: 0;
}

nav .pagination li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
}

nav .pagination select {
    display: inline-block;
    width: 45px;
    padding: 0;
    height: auto;
    margin-left: 10px;
}

.left-pad-50 {
    padding-left: 50px;
}

.left-pad-10 {
    padding-left: 10px;
}

.v-middle {
    vertical-align: middle;
}

.min-numeric {
    line-height: 45px;
    margin-top: 5px;
    vertical-align: middle;
}

.min {
    vertical-align: bottom;
}

.appointment-fixed {
    padding: 20px 0;
    border-bottom: 1px solid #dadada;
}

.pad-bot-50 {
    padding-bottom: 50px;
}

.previous-visit.pad-less {
    padding: 6px 20px;
}

.existing-appointments.top-marg-off {
    margin-top: 0;
}

.left-space-15 {
    padding-left: 15px;
}

.days ul.apointment-timings-wrapper {
    padding-left: 40px;
    text-align: left;
}

.provider-calendar {
    padding: 18px 20px;
}

.results-found, .provider-calendar {
    border-bottom: 10px solid #e6e6e6;
}

.apointment-timings-wrapper {
    height: 200px;
    overflow: auto;
}

.delete-attachment {
    color: #fff;
    background: #ef1818;
    width: 20px;
    height: 20px;
    display: inline-block;
    text-align: center;
    line-height: 16px;
    border-radius: 50%;
    margin-left: 15px;
}

.doc-info .media-body {
    word-wrap: break-word;
    width: 70%;
    display: inline-block;
    vertical-align: top;
}

.doc-info .media-left {
    width: 70px;
    height: 110px;
    display: inline-block;
}

.no-apint-middle {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.search-by-name .form-control::-ms-clear {
    display: none;
}

.dialup-modal .modal-content {
    min-height: 1px;
    padding-bottom: 10%;
}

.dialup-modal .modal-content {
    height: 100%;
    margin: 0;
}

.other-textbox {
    overflow: hidden
}

.tab-right-content {
    padding: 1.5rem 0;
    border: none;
}

@media(max-width:767px) {
    .panel.panel-closed .edit-details {
        text-align: left;
    }
    .img-responsive {
        display: inline;
    }
    .panel h3 {
        font-size: 16px !important;
        line-height: 20px !important;
    }
    .custom-form.jumbotron .panel.panel-closed {
        padding: 15px 15px;
    }
    .custom-form.jumbotron {
        padding: 0 20px 20px;
    }
    .custom-form.jumbotron .panel {
        padding: 0;
        margin-top: 30px;
        border: none;
    }
    .clearfix.form-group .placeholder-animation {
        padding-left: 0;
        padding-right: 0;
    }
    .clynder {
        padding: 13px 20px;
        text-align: center;
        margin: 15px 0;
        top: 0;
        display: block;
        font-size: 14px;
    }
    .card.user-panel .pull-right {
        float: none !important;
        text-align: center;
    }
    .existing-appointments .icon-circle.icon-spacing-b {
        margin-top: 0;
    }
    .existing-appointments .icon-circle.icon-spacing {
        padding-top: 28px;
        margin-bottom: 0;
    }
    .existing-appointments .icon-circle {
        padding: 0px 30px;
        width: 60px;
        height: 60px;
    }
    .custom-dashboard .icon-circle {
        width: 64px;
        height: 64px;
    }
    .existing-appointments .icon-circle span {
        font-size: 25px;
    }
    .consultation {
        height: auto;
        margin-top: 20px;
    }
    .existing-appointments .spacing.card {
        padding: 20px;
        padding-bottom: 100px;
    }
    img {
        max-width: 100%;
    }
    .middle-content.top-margin-80 {
        margin-top: 20px;
    }
    .terms ol, .policy ul {
        padding-left: 10px;
    }
    .btn-primary-round {
        font-size: 10px;
        padding: 17px 18px;
        line-height: 1;
    }
    .previous-visit .col-sm-6.text-right {
        text-align: left;
    }
    .nav-tabs.tabs-left>li {
        margin-right: -1px;
        display: inline-block;
        vertical-align: top;
    }
    .nav-tabs.tabs-left>li>a .custom-icons.video-icon {
        height: 17px;
        top: 5px;
        left: 2px;
    }
    .nav-tabs.tabs-left>li>a .custom-icons.wifi {
        top: 4px;
        left: 3px;
    }
    .nav-tabs.tabs-left>li.active>a, .nav-tabs.tabs-left>li>a:hover {
        border: 2px solid #1774cc;
    }
    .nav-tabs.tabs-left>li.active>a .custom-icons.wifi {
        left: 0;
    }
    .nav-tabs.tabs-left>li>a {
        font-size: 0;
        padding: 0;
        border: 2px solid #88c9df;
        width: 48px;
        height: 40px;
    }
    .tab-right-content {
        width: 100%;
        padding: 1rem 0;
    }
    .nav-tabs.tabs-left>li.active>a .custom-icons.wifi, .nav-tabs.tabs-left>li>a .custom-icons.wifi {
        left: 11px;
    }
    .nav-tabs.tabs-left>li>a .custom-icons.wifi {
        top: 9px;
        width: 23px;
    }
    .software.placement {
        top: 6px;
        left: 9px;
    }
    .nav-tabs.tabs-left>li>a .custom-icons.video-icon {
        top: 10px;
        left: 10px;
    }
    .mic.placement {
        left: 11px;
    }
    .sound.placement {
        left: 11px;
    }
    .form-elements .right-pad-off {
        padding-right: 15px;
    }
    ul.ma-vh-ul .col-sm-7 .display-block {
        display: inline-block;
        margin-bottom: 10px;
    }
    li.previous, li.next {
        float: none;
    }
    .pagination>li.pull-right {
        float: none !important;
    }
    .navbar-default .navbar-collapse {
        border: none;
    }
    .navbar-brand img {
        max-width: 60%;
    }
    .middle-content .card {
        margin-bottom: 20px;
    }
    .btn-primary-transparent {
        white-space: inherit;
    }
    .custom-form.jumbotron .panel .panel {
        padding: 0;
        margin: 0;
        border: none;
    }
    .progress-radial {
        display: none;
    }
    .ma-upload-actions {
        top: 0;
        left: 0;
        position: relative;
        margin: 10px 0;
    }
    .panel {
        padding: 30px 10px 30px 10px;
    }
    .speciality a {
        margin-left: 0;
        width: 100%;
    }
    .center-arrow .top-pad-30 {
        padding-top: 75px;
    }
    .ma-greeting-note-wrapper {
        padding: 30px;
    }
    .card .col-sm-6>.left-pad-50 {
        padding-left: 0;
    }
    .top-space-xs {
        padding-top: 20px;
    }
    .navbar-brand {
        max-width: 75%;
        height: auto;
    }
    .navbar-nav {
        border-top: 1px solid #e2e2e2;
    }
    .dashboard-navigation .navbar-brand {
        line-height: 100%;
    }
    .txt-cntr-res .text-right, .text-right.txt-cntr-res, .txt-cntr-res {
        text-align: center;
    }
    .custom-form.jumbotron .panel {
        border-bottom: none;
        box-shadow: none;
    }
    .btn-btm-res-rmv, .btn-btm-res-rmv.btn-primary-transparent {
        margin-bottom: 0;
    }
    .test-bar {
        min-height: 110px;
    }
    .bubble .user-img {
        margin: 14px 16px 0;
        width: 140px;
        height: 140px;
    }
    .bubble {
        width: 170px;
        height: 170px;
        position: relative;
    }
    .bubble .user-img img {
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
    }
    .dialup-modal .modal-content {
        min-height: 1px;
        padding-bottom: 10%;
    }
    .res-position {
        margin-top: 100px;
    }
    .bubble:before {
        display: none;
    }
    .dialup-modal .modal-dialog {
        height: 90%;
        top: 5%;
        width: auto;
        margin: 0 20px;
    }
    .dialup-modal .modal-content {
        height: 100%;
        margin: 0;
    }
    .call-btn {
        width: 290px;
    }
    .navbar-default li a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .previous-visit .col-sm-6.text-right.schdl-apnt {
        text-align: center;
    }
    .schdl-apnt .btn {
        margin: 10px 0;
    }
    .consultation.border {
        padding-bottom: 20px;
    }
    .days li {
        width: 13%;
        height: auto;
        font-weight: 500;
        line-height: 100%;
        border-right: 1px solid #cfcfcf;
    }
    .days a {
        font-size: 12px;
    }
    .days ul {
        width: 100%;
    }
    .affix .doc-info .media-body {
        padding-left: 0px;
    }
    .doc-info .media-body h4 {
        font-size: 14px;
    }
    .doc-info .media-body h3 {
        font-size: 16px;
    }
    .doc-info .media-left {
        width: 60px;
        height: 100px;
    }
    .doc-info .media-left .media-object {
        height: auto !important;
    }
    .apointment-timings-wrapper {
        height: 130px;
        padding-left: 0;
    }
    .results-found>div {
        height: auto !important;
    }
    .days a.next {
        position: absolute;
        right: 2px;
        bottom: 10px;
    }
    .days a.prev {
        position: absolute;
        left: 2px;
        bottom: 10px;
    }
    .days {
        margin: 20px 0;
    }
    .top-0.affix {
        width: 100%;
    }
    .apointment-timings-wrapper li a {
        display: block;
        height: 25px;
        margin-bottom: 15px;
    }
}

@media (max-width: 480px) {
    .panel.scroll:not(.panel-closed) h3 {
        margin: 0 0 46px !important;
    }
    .existing-appointments .dashboard-widget-btn .btn {
        padding: 9px 12px;
    }
}

@media(min-width:1023px) and (max-width: 1200px) {
    .existing-appointments .icon-circle.icon-spacing {
        padding-top: 40px;
        font-size: 28px;
        padding: 40px 44px 0;
        width: 110px;
        height: 110px;
        margin: 10px 0px 35px 0;
    }
    .custom-dashboard .icon-circle.icon-spacing{
        width: 65px;
        height: 65px;
        padding: 0;
    }
    .existing-appointments h1 {
        font-size: 26px;
        line-height: 36px;
    }
    .custom-dashboard h1 {
        font-size: 24px;
    }
    .display-block.startdateMonth, .display-block.startdateYear {
        display: inline-block;
    }
    .consultation {
        min-height: 70px;
    }
    .col-sm-8.pad-right-zero {
        padding-right: 0;
    }
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #313336;
}

*, *:focus, *:active {
    outline: none !important;
}

.txt-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

@media (min-width: 768px) and (max-width: 1023px) {
    .lead {
        font-size: 21px;
    }
}

@media(max-width: 1023px) {
    .custom-dashboard .spacing {
        min-height: 0 !important;
    }
    .custom-dashboard .icon-circle.icon-spacing {
        margin: 0 0 7px 0 !important;
    }
    .appointment-detail-card .existing-appointments .spacing {
        padding: 30px 20px 30px 20px;
    }
}
@media (min-width: 320px) and (max-width: 1200px) {
    .custom-dashboard h1 {
        font-size: 24px;
        line-height: 30px;
    }
    .consultation .btn {
        &.custom-arrow {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
@media (min-width: 768px) and (max-width: 1200px) {
    .days li {
        width: 13%;
        height: auto;
        font-weight: 500;
        line-height: 100%;
        border-right: 1px solid #cfcfcf;
    }
    .apointment-timings-wrapper li a {
        display: block;
        height: 25px;
        margin-bottom: 15px;
    }
    .results-found>div {
        height: auto !important;
    }
    .days a.next {
        position: absolute;
        right: 10px;
        bottom: 3px;
    }
    .days a.prev {
        position: absolute;
        left: 10px;
        bottom: 3px;
    }
    .previous-visit .apointment-schedule p {
        text-align: left;
    }
    .days ul {
        width: 100%;
    }
    .apointment-schedule input, select.blue {
        font-size: 18px;
    }
    .affix .doc-info .media-body {
        padding-left: 0px;
    }
}

@media (max-width: 991px) {
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-default {
        min-height: 95px;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
        margin-top: 30px;
    }
    .navbar-collapse.collapse {
        display: none !important;
    }
    .navbar-nav {
        float: none !important;
        margin: 7.5px -15px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .navbar-text {
        float: none;
        margin: 15px 0;
    }
    .navbar-collapse.collapse.in {
        display: block !important;
    }
    .collapsing {
        overflow: hidden !important;
    }
    .display-grid .consultation {
        margin-top: 0px;
    }
    .display-grid {
        width: 100%;
    }
}

.consultation .tables {
    display: table;
    width: 100%;
    height: 100%;
}

.consultation .table-cells {
    display: table-cell;
    vertical-align: middle;
    display: flex;
    align-items: center;
}

/*Attachments*/

.attachments-case .case-form__body {
    padding: 23px 30px 15px;
}

ul.attach-list {
    /* margin: 0; */
    /* padding-top: 12px; */
}

ul.attach-list li {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
}

@media (min-width: 1024px) and (max-width: 1300px) {
    ul.attach-list li {
        width: 140px;
        height: 140px;
    }
}

ul.attach-list li.case-attached {
    position: relative;
    z-index: 1;
    margin-bottom: 35px !important;
}

ul.attach-list li.case-attached a {
    display: block;
    width: 200px;
    height: 200px;
}

ul.attach-list li.case-attached a figure {
    margin: 0;
    position: absolute;
    bottom: 0;
    padding: 0 10px;
    max-height: 65px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    overflow: hidden;
}

ul.attach-list li+li {
    margin-left: -3px;
}

ul.attach-list .case-attached-img {
    display: inline-block;
    width: 200px;
    height: 200px;
    margin-right: 20px;
}

@media (min-width: 1024px) and (max-width: 1300px) {
    ul.attach-list .case-attached-img {
        width: 140px;
        height: 140px;
    }
}

#toast-container {
    height: calc(100% - 50px);
    z-index: 10;
    max-width: 275px;
    top: 70px;
    right: 1%;
    display: block;
    position: fixed;
}

.toast {
    background-color: transparent;
    padding: 0;
    word-break: normal;
    box-shadow: none;
    margin-bottom: -21px;
    border-radius: 2px;
    top: 0;
    width: auto;
    clear: both;
    margin-top: 10px;
    position: relative;
    max-width: 100%;
    height: auto;
    min-height: 48px;
    line-height: 1.5em;
    font-size: 1.1rem;
    font-weight: 300;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    float: right;
}

.np {
    width: 394px;
}

.card {
    position: relative;
    margin: 0.5rem 0 1rem 0;
    background-color: #fff;
    transition: box-shadow .25s;
    border-radius: 20px;
    box-shadow: 0px 10px 24px 14px rgba(0,0,0,0.12);
}

.np .card-content {
    padding: 0;
    padding-top: 0;
    color: #000;
    border-radius: 0 0 2px 2px;
}

.np .card-content .collection {
    border: 0;
    margin: 0;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    padding-left: 0;
    list-style-type: none;
}

.np .card-content .collection .collection-item.avatar {
    padding: 10px 20px;
    padding-left: 60px;
    padding-right: 10px;
    font-size: 14px;
    border-bottom: none;
    min-height: 84px;
    position: relative;
    background-color: #fff;
    line-height: 1.5rem;
    margin: 0;
    list-style-type: none;
    float: none;
}

.np .card-content .collection .collection-item.avatar .circle {
    left: 10px;
    font-size: 18px;
    line-height: 42px;
    color: #fff;
    background-color: #999;
    text-align: center;
    position: absolute;
    width: 42px;
    height: 42px;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
}

.np .card-action {
    background-color: #f2f2f2;
    padding: 10px;
    text-align: center;
    position: relative;
    border-top: 1px solid rgba(160, 160, 160, 0.2);
}

.card .card-action a:not(.btn):not(.btn-large):not(.btn-large):not(.btn-floating) {
    color: #ffab40;
    margin-right: 20px;
    transition: color .3s ease;
    text-transform: uppercase;
}

.np .card-action a {
    color: #919191 !important;
    font-weight: bold;
    margin: 0 10px !important;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    text-align: center;
}

.np .card-content .collection .collection-item p {
    font-weight: bold;
    opacity: .7;
}

.collection .collection-item.avatar p {
    margin: 0;
}

.btn-flat {
    border: none;
    border-radius: 3px;
    padding: 7px 1.25rem;
}

.btn-flat:focus, .btn-flat:hover {
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: none;
}

.download-file-link {
    text-align: center;
    display: inline-block;
    width: 200px;
    height: 200px;
    background: #e9eef1;
    position: relative;
    z-index: 1;
}

.download-file-link i {
    font-size: 50px;
    display: block;
}

.login-footer label {
    font-size: 16px;
    color: #313336;
    max-width: 60%;
    padding-top: 7px;
    padding-left: 0;
    font-weight: 400;
    cursor: auto;
}

.custom-appointments .card {
    border-radius: 20px;
    box-shadow: 0px 10px 24px 14px rgba(0,0,0,0.12);
}

.custom-dashboard .card{
    width: 100%;
}

.pull-right.past-btn {
    margin: 0 20px 0 0px;
    @media (max-width:1024px) {
        margin: 0;
    }
}

.col-btn {
    margin-left: 10px;
    margin-bottom: 10px;
}

.custom-appointments .visitEnabled .display-grid, .custom-appointments .appointmentEnabled .display-grid {
    width: 100%;
}

.custom-appointments .visitEnabled .consultation>p, .custom-appointments .appointmentEnabled .consultation>p {
    text-align: left;
    padding-top: 10px;
}

.custom-appointments h1.resource {
    max-width: calc(100% - 160px);
}

.custom-dashboard {
    .custom-class-for-height {
        height: 300px;
    }
}
.custom-class-for-height {
    height: 476px;
}

.custom-class-for-margin {
    margin-bottom: 10px;
}

/*Attachement*/

.attachments-case .case-form__body {
    padding: 23px 15px 15px;
}

.attachments-case .case-detail__content-section__heading {
    padding: 20px 0 0;
}

.attachments-case .case-form {
    border-radius: 3px;
    margin-bottom: 30px;
}

.attachments-case .case-form .case-form__header {
    background: #e9eef1;
    border-bottom: 1px solid #e0e0e0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 30px;
}

.attachments-case .case-form .case-form__header .case-form__meta p {
    color: #5b6b78;
    font-size: 12px;
    margin: 0;
}

.attachments-case .case-form .case-form__header .case-form__meta p a {
    color: #1774cc;
}

.attachments-case .case-form.is-done {
    border: 1px solid #e6e6e6;
}

.attachments-case .case-form.is-edit {
    border: 1px solid #5b6b78;
}

.attachments-case .case-form.is-edit .delete-image {
    position: absolute;
    color: #fff;
    right: 5px;
    z-index: 10;
    background: #000;
    font-size: 16px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #fff;
    text-align: center;
    line-height: 18px;
    top: 5px;
    cursor: pointer;
}

.attachments-case .case-form.is-edit .case-form__header {
    background: #5b6b78;
}

.attachments-case .case-form.is-edit .case-form__header p {
    font-weight: 400;
    font-size: 20px;
    margin: 0;
    color: #fff;
    line-height: 33px;
}

.attachments-case .case-form.is-edit .case-form__header .case-form__asignee .close-form-icon {
    color: #e6172c;
    margin-right: 20px;
    background: none;
    border: none;
    padding: 0;
    margin-top: 5px;
    display: inline-block;
}

.attachments-case .case-form.is-edit .case-form__header .case-form__asignee .save-form-icon {
    color: #1774cc;
    background: none;
    border: none;
    padding: 0;
    margin-top: 5px;
    display: inline-block;
}

.attachments-case .case-form-body-wrapper, .attachments-case .case-default-block .case-form-body-wrapper {
    border-bottom: 1px solid #e6e6e6;
}

.attachments-case .attach-list li a {
    display: block;
}

.attachments-case .attach-list li a .upload-link {
    color: #5b6b78;
    text-align: center;
    display: inline-block;
    width: 200px;
    height: 200px;
    border: 2px dashed #e6e6e6;
    padding-top: 60px;
    margin-right: 20px;
}

.attachments-case .attach-list li a .upload-link i {
    font-size: 50px;
    display: block;
}

.attachments-case .attach-list li a .upload-link span {
    font-size: 14px;
    font-weight: bold;
    display: block;
    text-transform: uppercase;
}

.attachments-case .case-form__body-footer {
    padding: 20px;
    border-top: 1px solid #e6e6e6;
}

.attachments-case .case-form__body-footer .collapse-bottom {
    margin-top: 40px;
}

.attachments-case .case-form__body-footer .collapse-bottom .cancel-link {
    background-color: #f2f2f2;
    color: #e6172c;
    box-shadow: none !important;
}

.attachments-case .case-form__body-footer .collapse-bottom .cancel-link i {
    vertical-align: middle;
}

.attachments-case .case-form__body-footer .collapse-bottom .btn-save {
    text-decoration: none;
    color: #fff;
    background-color: #1774cc;
    text-align: center;
    letter-spacing: .5px;
    transition: .2s ease-out;
    cursor: pointer;
    width: 90px;
    margin-left: 5px;
}

.overlayAttach {
    position: relative;
}

.hiddenclass {
    display: none
}

.loaderText {
    display: block !important;
    font-size: 30px;
    color: #fff;
    margin-top: 5px;
}

.overlayAttach .loader {
    border: 16px solid #e6e6e6;
    border-top: 16px solid #1774cc;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
    display: inline-block;
}

.loaderAndText {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    display: none;
    position: absolute;
    background: rgba(0, 0, 0, 0.75);
    z-index: 100;
    padding: 15px;
}

.attachedImg {
    background: url('/img/loading.gif') 50% no-repeat;
    border: 1px solid black;
    border-radius: 5px;
}

.overlayAttach .loaderAndText {
    display: block;
}

.centeredTextLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.icon-circle {
    font-size: 40px;
    background: #f2f2f2;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    padding: 5px 50px;
    vertical-align: middle;
}

.icon-circle.icon-spacing {
    padding-top: 46px;
    margin: 10px 0px 35px 0;
}

.custom-dashboard .icon-circle.icon-spacing {
    margin: 10px 0px 17px 0;
}


.ngdialog.ngdialog-theme-default .ngdialog-content {
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 1.75rem;
    color: #313336;
    max-width: calc(100% - 2rem);
    padding: 24px;
    background: #fff;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0px 10px 24px 14px rgba(0,0,0,0.12);
}
@media (max-width:767px) {
    .ngdialog.ngdialog-theme-default .ngdialog-content {
        padding: 16px;
    }
}

.ngdialog.ngdialog-theme-default .ngdialog-close {
    cursor: pointer;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    border: 0px;
    height: 32px;
    line-height: 1;
    padding: 0;
}
.ngdialog.ngdialog-theme-default .ngdialog-close:before {
    padding: 0;
    background: 0 0;
    color: #5b6b78;
    font-weight: 400;
    content: "close";
    font-family: 'Material Icons';
    text-rendering: optimizeLegibility;
    font-feature-settings: "liga" 1;
    font-style: normal;
    text-transform: none;
    border-radius: 1000px;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    font-size: 1.5rem;
    display: inline-block;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.passwordlegends {
    font-size: 14px;
    margin: 20px 0px 10px 0px;
}

.passwordinvalid {
    color: red;
}

.passwordinvalid:before {
    position: relative;
    left: -3px;
    content: "✖";
}

.passwordvalid {
    color: green;
}

.passwordvalid:before {
    position: relative;
    left: -3px;
    content: "✔";
}

.centerErrorPage {
    display: flex;
    margin-top: 50px;
    margin-bottom: 50px;
    align-items: center;
    justify-content: center;
}

.buttonErrorPage {
    border: 1px solid;
    padding: 10px 15px;
    min-width: 150px;
    text-align: center;
}

.buttonErrorPage:hover, .buttonErrorPage:focus, .buttonErrorPage:active:hover, .buttonErrorPage:active {
    border: 1px solid #fff;
    color: #fff;
}

.inline-input input {
    text-align: center;
    width: auto;
    display: inline-block;
    vertical-align: middle;
}

.inline-input span {
    display: inline-block;
    vertical-align: middle;
}

.visit-a-doc .jumbotron .current-address p {
    margin-top: 20px;
}

.m-top-30 {
    margin-top: -30px;
}

.login-height-wrapper {
    height: calc(100vh - 170px);
    position: relative;
    min-height: 800px;
}

.middle-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.bg-cover {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    content: '';
    z-index: -1;
    background-attachment: inherit !important;
}

.navbar-default {
    z-index: 2;
}

.txtarea-small {
    height: 40px !important;
}
.custom-form-margin {
    margin-top: 0 !important;
}

#SelectedChiefComplaint {
    color: #000;
}

.red {
    color: #e6172c;
}

.summary-data>ul li {
    list-style-type: initial;
}

.summary-data>ul {
    padding-left: 20px;
}

.loading-ico {
    background: url('/img/loading.gif') 50% no-repeat;
    height: 24px;
    width: 24px;
}

.modal-patient-notification .badge {
    background-color: #e6172c;
}

.modal-patient-notification .speech-bubble {
    padding: 15px;
	position: relative;
	background: #f2f2f2;
    border-radius: .4em;
    font-family: 'Open Sans';
    font-weight: bold;
}

.modal-patient-notification .speech-bubble:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-top-color: #e6e6e6;
	border-bottom: 0;
	margin-left: -10px;
	margin-bottom: -10px;
}

.modal-patient-notification .avatar {
    margin: 15px 0;
}

.modal-patient-notification .img-circle {
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

.modal-patient-notification .avatar-circle {
    width: 80px;
    height: 80px;
    background-color: #1774cc;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
}

.modal-patient-notification .initials {
    position: relative;
    top: 20px;
    font-size: 40px;
    line-height: 40px;
    color: white;
    font-weight: bold;
}

.modal-patient-notification .btn-success {
    margin: 25px 0 15px 0;
    width: 300px;
}

.language-select select {
    background: #fff !important;
    border: none;
    border-bottom: 2px solid #e6e6e6;
    color: #313336;
    font-size: 16px;
    border-radius: 0;
    box-shadow: none;
    height: 40px;
    z-index: 3;
}
.language-select select:hover {
    box-shadow: none;
    border-color: #1774cc;
}

.card.card-hero {
    margin-top: 0;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
}
.circle {
    border-radius: 50%;
}
.card.card-hero .circle {
    margin: 48px auto 48px;
    height: 100px;
    width: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.card.card-hero .btn {
    margin: 24px auto 48px;
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 991px) {
    .card.card-hero .btn {
        margin: 0 auto 16px;
    }
    .custom-dashboard .custom-class-for-margin {
        margin-bottom: 2px;
    }
    .appointment-detail-card .existing-appointments .icon-circle.icon-spacing {
        margin-bottom: 17px;
    }
    .appointment-detail-card .existing-appointments h1 {
        margin: 0 0 14px;
    }
}

.ib {
    display: inline-block;
}
.stepper-new  .custom-form.jumbotron {
    padding: 40px 40px 25px;
}
.stepper-new .custom-form.jumbotron .panel {
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
}
.stepper-new .custom-form.jumbotron .panel.panel-closed {
    padding: 0 16px;
    margin-bottom: 24px;
}
.stepper-new .create-visit-wrapper .panel.panel-closed h3 {
    margin-top: 10px;
}
@media (max-width: 991px) {
    .stepper-new .create-visit-wrapper .panel h3 {
        margin: 10px 0;
        line-height: 1.5;
    }
}
@media (max-width: 767px) {
    .stepper-new .custom-form.jumbotron {
        padding: 16px;
    }
    .stepper-new .custom-form.jumbotron .panel {
        border: none;
        box-shadow: none;
    }
    .stepper-new .custom-form.jumbotron .panel.panel-closed {
        margin-bottom: 16px;
    }
    .stepper-new .tab-right-content {
        width: 100%;
        margin: 0;
    }
}
.stepper-new .white-circle {
    font-size: 20px;
    background: #ffffff;
    width: 24px;
    height: 24px;
    text-align: center;
    color: #303030;
    padding-top: 0px;
    margin: 8px 15px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    line-height: 24px;
    vertical-align: sub;
}
@media (max-width: 991px) {
    .stepper-new .create-visit-wrapper .done-icon {
        width: 24px;
        height: 24px;
        font-size: 20px;
        margin: 8px 0 8px 8px;
    }
}
.stepper-new  #divTestConnection {
    margin: 0 !important;
}
.stepper-new .custom-form form {
    margin-top: 0;
}
.stepper-new #step2 .spacing-b {
    padding: 0;
}
.stepper-new .custom-form .form-group {
    margin-bottom: 16px;
    &.has-data {
        margin-bottom: 10px;
    }
    p {
        @extend .lead, .fw-normal, .fz-small, .m-b15;
    }
}
.footer-menu {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #5b6b78;
    text-align: left;
    flex-wrap: wrap;
    background: $white;
    border-radius: 20px;
    box-shadow: 0 10px 24px 14px rgba(0,0,0,.12);
}
.footer-menu {
    .navbar-nav>li a {
        &:hover {
            background-color: transparent;
        }
    }
}
@media (min-width: 768px) {
    .footer-menu {
        max-width: 720px;
    }
}
@media (max-width: 767px) {
    .footer-menu {
        margin: 0 15px;
    }
}
@media (min-width: 992px) {
    .footer-menu {
        max-width: 940px;
    }
}
@media (min-width: 1200px) {
    .footer-menu {
        max-width: 1140px;
    }
}
.footer-menu .footer-logo {
    height: 25px;
    margin-right: 16px;
}
.footer-menu .footer-logo img {
    max-width: 100%;
}
.footer-menu .footer-left {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
}
.small {
    font-weight: 400!important;
    line-height: 20px!important;
    font-size: 14px!important;
}
.footer-menu .footer-left p {
    margin: 0 16px 0 0;
}
@media only screen and (max-width: 1280px) {
    .footer-menu {
        display: block;
    }
}
@media (max-width: 767px) {
    .footer-menu .navbar-nav {
        border-top: none;
    }
}
@media only screen and (max-width: 480px) {
    .footer-menu .footer-left {
        display: block;
    }
}
.footer-menu ul {
    font-size: 14px;
    padding: 0 !important;
    margin: 0 !important;
    list-style-type: none !important;
    flex: 1 0 auto;
}
.footer-menu ul li {
    display: inline-block !important;
    margin-right: 16px !important;
    padding: 0!important;
    list-style-type: none !important;
    line-height: initial !important;
}
.footer-menu ul li a:link {
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
}

@media (max-width: 991px) {
    .navbar-block {
        overflow: hidden;
        position: absolute;
        transition: all .3s;
        background: #fff;
        left: 0;
        width: 100%;
        transform: translateX(-100%);
        padding: 0 15px;
        margin-top: 10px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .navbar-block {
        margin-top: 32px;
    }
}

.active-menu .navbar-block {
    transform: translateX(0);
}

@media (max-width: 767px)  {
    .login-inner-container .btn {
        width: 100%;
        font-size: 18px;
    }
}


@media (min-width: 992px) {
    .custom-row {
        display: flex;
    }
}

@media (max-width: 991px) {
    .existing-appointments .card {
        width: 100%;
    }
}
.w100 {
    width: 100%;
}
.dash-header-cntr{
    margin-top: 3px;
    width: 200px;
}

.bg-cover {
    z-index: -2;
}
.darken {
    z-index: -1;
    width: 100%;
    height: 0;
    position: relative;
}
.darken:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150px;
    content: "";
    background: linear-gradient(to bottom,rgba(0,0,0,.6) 0,rgba(0,0,0,.4) 40%,rgba(0,0,0,.2) 65%,rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66303336', endColorstr='#00303336',GradientType=0 );
}
p.safeopacity {
    color: #fff !important;
}
.safeopacity {
    opacity: .7;
}
.border-rad-top{
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.border-bottom-none{
    border-bottom: none !important;
}
.ngdialog {
    &.ngdialog-theme-default {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
}
.ngdialog {
    &.ngdialog-theme-default {
        .ngdialog-content {
            margin: auto;
            overflow: unset;
        }
    }
}
.form-btn-group {

    @media (max-width: 767px) {
        .col,
        .btn {
            width: 100%;
        }
    }
}

.video-wrapper-preview {
    padding-bottom: 1rem;

    &:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background-color: #000;
    }

    .video-nav {
        position: static;
    }

    .preview-camera {
        z-index: 1;
    }

    .close-btn-res {
        position: relative;
        z-index: 1;
    }
}
.policy,
.terms {
    border: none;
    box-shadow: none;
}
.specific-page {
    .custom-form.jumbotron {
        box-shadow: none;
    }
}
.form-btn-group {
    @media (max-width: 767px) {
        .col,
        .btn {
            width: 100%;
        }
    }
}
.ngdialog {
    &.ngdialog-theme-default {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        .ngdialog-content {
            margin: auto;
            overflow: unset;
        }
    }
}

.policy,
.terms {
    border: none;
    box-shadow: none;
}
.specific-page {
    .custom-form.jumbotron {
        box-shadow: none;
    }
}

.stepper-new .custom-form .form-group p.m-0 {
    margin: 0 !important;
}

.placeholder-animation {
    &.reduce-space-margin {
        margin-top: 29px;
    }
}

.custom-size {
    font-size: 12px;
    color: #999;
}
.incoming-call-dialog {
    .modal-footer {
        .btn {
            @media (max-width: 767px) {
                max-width: 100% !important;
                margin: 0 6px 5px!important;
            }
        }
    }
}
@media (max-width: 480px) {
    .custom-link-box {
        .btn {
            min-width: 103px;
            padding: 9px 14px;
        }
    }
}
.existing-appointments .dashboard-widget-btn .btn {
    max-width: 320px;
    width: 100%;
    margin: auto;
}

@media (max-width: 767px) {
    .existing-appointments .dashboard-widget-btn .btn {
        max-width: 320px;
        width: 100%;
        margin: auto;
        bottom: 12px !important;
    }
}

@media (max-width: 1200px) and (min-width: 320px) {
    .partial-behaviour-health .placeholder-animation {
        margin-top: 65px !important;
    }

    .placeholder-animation .form-control:focus+label, .placeholder-animation.has-data label {
        top: -35px;
    }
}

.placeholder-animation.has-data.reduce-space-margin label {
    top: -32px;
}
.video-nav {
    bottom: 20px !important;
}

.video-stream-block {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    bottom: 85px;
}

.video-stream-block .custom-video-view,
.video-stream-block .ie-video-preview {
    height: 100vh !important;
    width: 100vw !important;
}