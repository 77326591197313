.youtube-box {
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
  &:after {
    clear: both;
    display: table;
    content: "";
  }

  .youtube-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    &:after {
      clear: both;
      display: table;
      content: "";
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100%!important;
    }
  }
}
