.video-wrapper {
    .companion {
        left: 0 !important;
        top: 0 !important;
        padding: 4px 2px 4px 4px;
        width: 150px;
        display: flex;
        background-color: $black;

        @media (min-width: 550px) {
            padding: 4px;
        }
    }
    .video-multi-flash {
        .companion {
            left: 50% !important;
            transform: translateX(-150px);

            @media (min-width: 550px) {
                left: 0 !important;
                transform: translate(0);
            }
            @media (min-width: 768px) {
                left: 50% !important;
                transform: translate(-148px, 0);
            }
        }
    }
}
.self-view-video {
    width: 100%;
    object-fit: contain;
    max-height: 80px;
    object-position: center;
}
.video-single-flash {
    .self-view-video {
        object-position: left;

        @media (min-width: 768px) {
            max-height: 144px;
        }
    }
    .companion {
        background-color: transparent;

        @media (min-width: 768px) {
            width: 265px;
        }
    }
    .video-multi  {
        .video-stream-block {
            background-color: transparent;
        }
    }
}
.video-multi {
    .video-stream-block {
        position: absolute;
        display: flex;
        width: 150px;
        padding: 4px 4px 4px 2px;
        right: 50%;
        top: 0;
        text-align: left;
        transform: translateX(150px);
        background-color: $black;
        left: auto;
        bottom: auto;

        @media (min-width: 550px) {
            left: 0;
            right: auto;
            padding: 4px;
            text-align: center;
            top: 84px;
            transform: translate(0);
            justify-content: center;
        }

        @media (min-width: 768px) {
            left: auto;
            right: 50%;
            justify-content: left;
            transform: translate(148px, 0);
            top: 0;
            text-align: left;
            bottom: auto;
        }

        .video-main {
            transform: scale(1) !important;
            object-fit: contain;
            max-height: 80px;
        }
    }
}
.video-presentation-image {
    object-fit: contain;
    height: 100%;
    width: auto;
}
.video-presentation-block {
    position: absolute;
    max-height: calc(100% - 200px);
    top: 100px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;

    @media (min-width: 550px) and (max-width: 767px) {
        width: calc(100% - 150px) !important;
        right: 0;
        top: 0;
        left: auto;
        max-height: calc(100% - 95px);
        align-items: flex-start;
    }

    @media (min-width: 768px) {
        width: 100% !important;
        top: 100px;
        max-height: calc(100% - 210px);
        align-items: center;
    }
}