/* new test connection */
.connection-tab-wrapper .tab-right-content {
    padding: 2rem 0 1.5rem;
    border: none;
}
.connection-tab-wrapper #summaryForm {
    width: 600px;
    margin: 0 auto;
    max-width: 100%;
}
.test-connect-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1 0 auto;
}
.test-connect-nav .mic {
    height: 24px;
}
.test-connect-nav .material-icons:before {
    font-family: 'Material Icons';
    color: #5b6b78;
}
.test-connect-nav .material-icons.camera:before {
    content: "videocam";
}
.test-connect-nav .material-icons.mic:before {
    content: "mic";
}
.test-connect-nav .material-icons.speaker:before {
    content: "volume_up";
}
.test-connect-nav .material-icons.call:before {
    content: "call";
}
.test-connect-nav .material-icons.summary:before {
    content: "list";
}
.test-connect-nav .connection-success:not(.active) a .material-icons:before {
    content: "check_circle";
    color: #30a039;
}
.test-connect-nav .connection-faliure:not(.active) a .material-icons:before {
    content: "error";
    color: #e7741c;
}
.test-connect-nav a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 auto;
    align-items: center;
    color: #313336 !important;
    text-decoration: none !important;
}

.test-connect-nav .connection-txt {
    font-size: 14px;
    line-height: 20px;
    color: #313336 !important;
}
.test-connect-nav li {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: .5s;
    transition: .5s;
}
.test-connect-nav li:not(:last-child):after,
.test-connect-nav li:not(:first-child):before {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 1px;
    margin: .5rem 0 0 0;
    content: "";
    background-color: #E6E6E6;
    top: -12px;
}
@media (max-width: 991px) {
    .test-connect-nav li:not(:last-child):after,
    .test-connect-nav li:not(:first-child):before {
        top: -3px;
    }
}
.connecting-gif {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: auto;
}
.connecting-gif img {
    max-height: 100%;
    max-width: 100%;
}
.spinner-gif {
    display: block;
    margin: auto;
    min-height: 3rem;
}

.mic-test {
    border: 1px solid #E6E6E6;
    border-radius: .25rem;
    padding: .5rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 80%;
    margin: 0 auto;
}
@media (max-width: 767px) {
    .mic-test {
        width: 100%;
    }
}
.mic-test .material-icons {
    color: #1774CC;
    margin: 0 1rem 0 .25rem;
}
.mic-test-bar {
    display: flex;
    flex-flow: row nowrap;
    align-content: space-between;
    justify-content: center;
    flex: 1 0 auto;
}
.mic-test-bar span {
    flex: 1 1 auto;
    height: 1.5rem;
    background: #F2F2F2;
}
.mic-test-bar span:not(:first-child) {
    margin-left: .5rem;
}
.mic-test-bar span:first-child {
    border-radius: 3px 0 0 3px;
}
.mic-test-bar span:last-child {
    border-radius: 0 3px 3px 0;
}

.player-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 3rem 1.5rem;
    width: 80%;
    margin: 0 auto;
}
@media (max-width: 767px) {
    .player-container {
        width: 100%;
    }
}
.player-container .player {
    flex: 1 0 auto;
}
.player-container .player .player-bar {
    background: rgba(0, 0, 0, .15);
    height: .5rem;
    position: relative;
    border-radius: 100px;
    overflow: hidden;
}
.player-container .player .player-bar .progress-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #1774cc;
    border-radius: 100px;
    width: 0;
}
.player-container a {
    display: inline-block;
    line-height: 1;
    text-decoration: none;
    margin-right: 1rem;
    color: #1774cc !important;
}
.player-container a:hover,
.player-container a:active,
.player-container a:focus {
    text-decoration: none;
    color: #1774cc;
    cursor: pointer;
}
.player-container .material-icons {
    font-size: 3rem;
}
.player-btn {
    height: 3rem;
}
.summary-icon {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    margin: 2rem auto 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.summary-icon .material-icons {
    font-size: 4rem;
}
.summary-icon.pass {
    color: #30a039;
    border: 3px solid #30a039;
}
.summary-icon.fail {
    color: #e7741c;
    border: 3px solid #e7741c;
}
.allow-dropdown ul {
    list-style-type: none!important;
    padding-left: 0!important;
    margin: 0!important;
}
.allow-dropdown ul li {
    list-style-type: none!important;
    line-height: 1.75rem!important;
}
.preview-ratio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: auto;
}
.preview-ratio .preview-wrap {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
}
.preview-ratio .preview-wrap video,
.preview-ratio .preview-wrap object,
.preview-ratio .preview-wrap img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.test-call-descr {
    width: 80%;
    margin: 0 auto;
}
