// 20. Typography
// ==========================================================================
$main-font: 'Open Sans', sans-serif;

$fz-display: 3.5rem;/*56px*/
$fz-h1: 2.5rem;/*40px*/
$fz-h2: 2rem;/*32px*/
$fz-h3: 1.75rem;/*28px*/
$fz-h4: 1.5rem;/*24px*/
$fz-h5: 1.25rem;/*20px*/
$fz-h6: 1rem;/*16px*/
$fz-lead: 1.125rem;/*18px*/
$fz-normal: 16px;
$fz-small: 0.875rem;/*14px*/
$fz-xsmall: 0.75rem;/*12px*/

/*Font Weights*/
$fw-bold: 600;
$fw-normal: 400;
$fw-light: 300;