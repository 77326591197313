@font-face {
  font-family: "blackwell";
  src: url("../../shared/fonts/blackwell.eot?1pplvt");
  src: url("../../shared/fonts/blackwell.eot?1pplvt#iefix") format("embedded-opentype"),
        url("../../shared/fonts/blackwell.ttf?1pplvt") format("truetype"),
        url("../../shared/fonts/blackwell.woff?1pplvt") format("woff"),
        url("../../shared/fonts/blackwell.svg?1pplvt#blackwell") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "blackwell" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^=icon-].standard, [class*=" icon-"].standard {
  color: #5b6b78;
}
[class^=icon-].layered, [class*=" icon-"].layered {
  position: relative;
  width: 18px;
  height: 18px;
}
[class^=icon-].layered .path1, [class^=icon-].layered .path2, [class^=icon-].layered .path3, [class^=icon-].layered .path4, [class^=icon-].layered .path5, [class^=icon-].layered .path6, [class^=icon-].layered .path7, [class^=icon-].layered .path8, [class*=" icon-"].layered .path1, [class*=" icon-"].layered .path2, [class*=" icon-"].layered .path3, [class*=" icon-"].layered .path4, [class*=" icon-"].layered .path5, [class*=" icon-"].layered .path6, [class*=" icon-"].layered .path7, [class*=" icon-"].layered .path8 {
  position: absolute;
  top: 0;
  left: 0;
}
[class^=icon-].transform, [class*=" icon-"].transform {
  transform: rotate(180deg);
}
[class^=icon-].lg-icon, [class*=" icon-"].lg-icon {
  font-size: 80px;
}
[class^=icon-].wh80, [class*=" icon-"].wh80 {
  width: 80px;
  height: 80px;
}
.graphics-icon,
.graphics-icon.layered {
	 width: 80px;
	 height: 80px;
	 font-size: 80px;
}
.graphics-icon-big,
.graphics-icon-big.layered {
	 width: 160px;
	 height: 160px;
	 font-size: 160px;
}

.icon-video-circle-black:before {
	 content: "\eb17";
}
 .icon-protected-room:before {
	 content: "\eb16";
}
 .icon-camera-black:before {
	 content: "\eb15";
}
 .icon-tablet-app-patient-video-call-landscape-black:before {
	 content: "\eb14";
}
 .icon-edu-black:before {
	 content: "\eb12";
}
 .icon-email-black:before {
	 content: "\eb13";
}
 .icon-ripley-non-cart-black:before {
	 content: "\eb0f";
}
 .icon-ripley-non-ptz-black:before {
	 content: "\eb10";
}
 .icon-ripley-with-ptz-camera-black:before {
	 content: "\eb11";
}
 .icon-workflow-black:before {
	 content: "\eafc";
}
 .icon-on-demand-visit-black:before {
	 content: "\eafd";
}
 .icon-rounding-black:before {
	 content: "\eb0c";
}
 .icon-service-line-black:before {
	 content: "\eb0d";
}
 .icon-scheduled-visits-black:before {
	 content: "\eb0e";
}
 .icon-notification-added-to-care-team:before {
	 content: "\eafe";
}
 .icon-notification-appointment-reminders:before {
	 content: "\eaff";
}
 .icon-notification-case-archived:before {
	 content: "\eb00";
}
 .icon-notification-case-assigned-to-me:before {
	 content: "\eb01";
}
 .icon-notification-case-cancelled:before {
	 content: "\eb02";
}
 .icon-notification-case-completed:before {
	 content: "\eb03";
}
 .icon-notification-case-created:before {
	 content: "\eb04";
}
 .icon-notification-case-updates:before {
	 content: "\eb05";
}
 .icon-notification-case-general:before {
	 content: "\eb06";
}
 .icon-notification-escalations:before {
	 content: "\eb07";
}
 .icon-notification-generated-reports:before {
	 content: "\eb08";
}
 .icon-notification-imaging-available:before {
	 content: "\eb09";
}
 .icon-notification-new-secure-message:before {
	 content: "\eb0a";
}
 .icon-notification-requested-access:before {
	 content: "\eb0b";
}
 .icon-empty-results-black .path1:before {
	 content: "\eaf6";
	 color: #000;
}
 .icon-empty-results-black .path2:before {
	 content: "\eaf7";
	 color: #000;
	 opacity: 0.3;
}
 .icon-empty-results-black .path3:before {
	 content: "\eaf8";
	 color: #000;
	 opacity: 0.3;
}
 .icon-empty-results-black .path4:before {
	 content: "\eaf9";
	 color: #000;
}
 .icon-empty-results-black .path5:before {
	 content: "\eafa";
	 color: #e6172c;
}
 .icon-empty-results-black .path6:before {
	 content: "\eafb";
	 color: #fff;
}
 .icon-audiotrack:before {
	 content: "\eaf5";
}
 .icon-status-available:before {
	 content: "\eaf1";
}
 .icon-status-busy:before {
	 content: "\eaf2";
}
 .icon-status-offline:before {
	 content: "\eaf3";
}
 .icon-status-unknown:before {
	 content: "\eaf4";
}
 .icon-video-settings-error-black .path1:before {
	 content: "\eaee";
	 color: #e6172c;
}
 .icon-video-settings-error-black .path2:before {
	 content: "\eaef";
	 color: #fff;
}
 .icon-video-settings-error-black .path3:before {
	 content: "\eaf0";
	 color: #000;
}
 .icon-avatar-person:before {
	 content: "\eaec";
}
 .icon-group:before {
	 content: "\eaed";
}
 .icon-audio-black:before {
	 content: "\eae9";
}
 .icon-led-light-black:before {
	 content: "\eaea";
}
 .icon-video-settings-black:before {
	 content: "\eaeb";
}
 .icon-baseline-settings_brightness:before {
	 content: "\eae8";
}
 .icon-television-app-v-3-black:before {
	 content: "\eae7";
}
 .icon-test-success_black:before {
	 content: "\eae6";
}
 .icon-control_camera:before {
	 content: "\eae5";
}
 .icon-cart-endpoint:before {
	 content: "\eae4";
}
 .icon-camera-error-black .path1:before {
	 content: "\eadd";
	 color: #000;
}
 .icon-camera-error-black .path2:before {
	 content: "\eade";
	 color: #e6172c;
	 margin-left: -1.5em;
}
 .icon-camera-error-black .path3:before {
	 content: "\eadf";
	 color: #fff;
	 margin-left: -1.5em;
}
 .icon-connectivity-black:before {
	 content: "\eae0";
}
 .icon-microphone-error-black .path1:before {
	 content: "\eae1";
	 color: #000;
}
 .icon-microphone-error-black .path2:before {
	 content: "\eae2";
	 margin-left: -1.5em;
	 color: #e6172c;
}
 .icon-microphone-error-black .path3:before {
	 content: "\eae3";
	 margin-left: -1.5em;
	 color: #fff;
}
 .icon-network-black .path1:before {
	 content: "\eada";
	 color: #e7741c;
}
 .icon-network-black .path2:before {
	 content: "\eadb";
	 color: #fff;
}
 .icon-network-black .path3:before {
	 content: "\eadc";
	 color: #000;
}
 .icon-manually-fail-black .path1:before {
	 content: "\ead7";
	 color: #000;
}
 .icon-manually-fail-black .path2:before {
	 content: "\ead8";
	 color: #e6172c;
}
 .icon-manually-fail-black .path3:before {
	 content: "\ead9";
	 color: #fff;
}
 .icon-baseline-info:before {
	 content: "\ead5";
}
 .icon-outline-info:before {
	 content: "\ead6";
}
 .icon-outline-looks_one:before {
	 content: "\ead3";
}
 .icon-outline-looks_two:before {
	 content: "\ead4";
}
 .icon-console-kiosk-black:before {
	 content: "\eacd";
}
 .icon-enclosed-kiosk-black:before {
	 content: "\eace";
}
 .icon-tablet-100-black:before {
	 content: "\eacf";
}
 .icon-tablet-200-black:before {
	 content: "\ead0";
}
 .icon-tablet-300-black:before {
	 content: "\ead1";
}
 .icon-tactical-black:before {
	 content: "\ead2";
}
 .icon-claims-black:before {
	 content: "\eabd";
}
 .icon-dicom-black:before {
	 content: "\eabe";
}
 .icon-fhir-black:before {
	 content: "\eabf";
}
 .icon-hl-7-black:before {
	 content: "\eac0";
}
 .icon-insurance-eligibility-black:before {
	 content: "\eac1";
}
 .icon-patient-demographics-patient-creation-black:before {
	 content: "\eac2";
}
 .icon-patient-vitals-black:before {
	 content: "\eac3";
}
 .icon-provider-note-black:before {
	 content: "\eac4";
}
 .icon-saml-black:before {
	 content: "\eac5";
}
 .icon-scheduling-black:before {
	 content: "\eac6";
}
 .icon-sdk-1-black:before {
	 content: "\eac7";
}
 .icon-sdk-2-black:before {
	 content: "\eac8";
}
 .icon-sftp-black:before {
	 content: "\eac9";
}
 .icon-share-button-black:before {
	 content: "\eaca";
}
 .icon-single-sign-on-black:before {
	 content: "\eacb";
}
 .icon-sso-black:before {
	 content: "\eacc";
}
 .icon-ethernet-fail-black .path1:before {
	 content: "\eab1";
	 color: #e6172c;
}
 .icon-ethernet-fail-black .path2:before {
	 content: "\eab2";
	 color: #fff;
}
 .icon-ethernet-fail-black .path3:before {
	 content: "\eab3";
}
 .icon-ethernet-general-black:before {
	 content: "\eab4";
}
 .icon-ethernet-success-black .path1:before {
	 content: "\eab5";
	 color: #30a039;
}
 .icon-ethernet-success-black .path2:before {
	 content: "\eab6";
}
 .icon-wifi-fail-black .path1:before {
	 content: "\eab7";
	 color: #e6172c;
}
 .icon-wifi-fail-black .path2:before {
	 content: "\eab8";
	 color: #fff;
}
 .icon-wifi-fail-black .path3:before {
	 content: "\eab9";
}
 .icon-wifi-general-black:before {
	 content: "\eaba";
}
 .icon-wifi-success-black .path1:before {
	 content: "\eabb";
}
 .icon-wifi-success-black .path2:before {
	 content: "\eabc";
	 color: #30a039;
}
 .icon-addiction-medicine-black:before {
	 content: "\ea9f";
}
 .icon-aerospace-medicine-black:before {
	 content: "\eaa0";
}
 .icon-occupational-medicine-black:before {
	 content: "\eaa1";
}
 .icon-preventive-medicine-clinical-informatics-black:before {
	 content: "\eaa2";
}
 .icon-preventive-medicine-medical-toxicology-black:before {
	 content: "\eaa3";
}
 .icon-preventive-medicine-undersea-and-hyperbaric-medicine-black:before {
	 content: "\eaa4";
}
 .icon-public-health-and-general-preventive-medicine-black:before {
	 content: "\eaa5";
}
 .icon-pediatric-urology-black:before {
	 content: "\eaa6";
}
 .icon-urology-female-pelvic-medicine-and-reconstructive-surgery-black:before {
	 content: "\eaa7";
}
 .icon-pharmacy-black:before {
	 content: "\eaa8";
}
 .icon-thoracic-and-cardiac-surgery-black:before {
	 content: "\eaa9";
}
 .icon-thoracic-surgery-congenital-cardiac-surgery-black:before {
	 content: "\eaaa";
}
 .icon-complex-general-surgical-oncology-black:before {
	 content: "\eaab";
}
 .icon-pediatric-surgery-black:before {
	 content: "\eaac";
}
 .icon-surgery-hospice-and-palliative-medicine-black:before {
	 content: "\eaad";
}
 .icon-surgery-surgery-of-the-hand-black:before {
	 content: "\ea5d";
}
 .icon-surgical-critical-care-black:before {
	 content: "\eaaf";
}
 .icon-vascular-surgery-black:before {
	 content: "\eab0";
}
 .icon-diagnostic-medical-physics-black:before {
	 content: "\ea65";
}
 .icon-diagnostic-radiology-black:before {
	 content: "\ea66";
}
 .icon-interventional-radiology-and-diagnostic-radiology-black:before {
	 content: "\ea67";
}
 .icon-neuroradiology-black:before {
	 content: "\ea68";
}
 .icon-nuclear-medical-physics-black:before {
	 content: "\ea69";
}
 .icon-nuclear-radiology-black:before {
	 content: "\ea6a";
}
 .icon-pediatric-radiology-black:before {
	 content: "\ea6b";
}
 .icon-radiation-oncology-black:before {
	 content: "\ea6c";
}
 .icon-radiology-hospice-and-palliative-medicine-black:before {
	 content: "\ea6d";
}
 .icon-radiology-pain-medicine-black:before {
	 content: "\ea6e";
}
 .icon-therapeutic-medical-physics-black:before {
	 content: "\ea6f";
}
 .icon-addictional-psychiatry-black:before {
	 content: "\ea70";
}
 .icon-child-and-adolescent-psychiatry-black:before {
	 content: "\ea71";
}
 .icon-clinical-neurophisilolgyogy-black:before {
	 content: "\ea72";
}
 .icon-epilepsy-black:before {
	 content: "\ea73";
}
 .icon-forensic-psychiatry-black:before {
	 content: "\ea74";
}
 .icon-geriatric-psychiatry-black:before {
	 content: "\ea75";
}
 .icon-neurodevelopmental-disabilities-black:before {
	 content: "\ea76";
}
 .icon-neurology-black:before {
	 content: "\ea77";
}
 .icon-neurology-with-special-qualification-in-child-neurology-black:before {
	 content: "\ea78";
}
 .icon-consultation-liaison-psychiatry-black:before {
	 content: "\ea79";
}
 .icon-psychiatry-and-neurology-brain-injury-medicine-black:before {
	 content: "\ea7a";
}
 .icon-psychiatry-and-neurology-hospice-and-palliative-medicine-black:before {
	 content: "\ea7b";
}
 .icon-psychiatry-and-neurology-neurocritical-care-black:before {
	 content: "\ea7c";
}
 .icon-psychiatry-and-neurology-neuromuscular-medicine-black:before {
	 content: "\ea7d";
}
 .icon-psychiatry-and-neurology-pain-medicine-black:before {
	 content: "\ea7e";
}
 .icon-psychiatry-and-neurology-sleep-medicine-black:before {
	 content: "\ea7f";
}
 .icon-psychiatry-black:before {
	 content: "\ea80";
}
 .icon-vascular-neurology-black:before {
	 content: "\ea81";
}
 .icon-plastic-surgery-surgery-of-the-hand-black:before {
	 content: "\ea82";
}
 .icon-plastic-surgery-within-the-head-and-neck-black:before {
	 content: "\ea83";
}
 .icon-pediatric-rehabilitation-medicine-black:before {
	 content: "\ea84";
}
 .icon-physical-medicine-and-rehabilitation-brain-injury-medicine-black:before {
	 content: "\ea85";
}
 .icon-physical-medicine-and-rehabilitation-hospice-and-palliative-medicine-black:before {
	 content: "\ea86";
}
 .icon-physical-medicine-and-rehabilitation-neuromuscular-medicine-black:before {
	 content: "\ea87";
}
 .icon-physical-medicine-and-rehabilitation-pain-medicine-black:before {
	 content: "\ea88";
}
 .icon-physical-medicine-and-rehabilitation-sports-medicine-black:before {
	 content: "\ea89";
}
 .icon-spinal-cord-injury-medicine-black:before {
	 content: "\ea8a";
}
 .icon-child-abuse-pediatrics-black:before {
	 content: "\ea8b";
}
 .icon-developmental-behavioral-pediatrics-black:before {
	 content: "\ea8c";
}
 .icon-neonatal-perinatal-medicine-black:before {
	 content: "\ea8d";
}
 .icon-pediatric-cardiology-black:before {
	 content: "\ea8e";
}
 .icon-pediatric-critical-care-medicine-black:before {
	 content: "\ea8f";
}
 .icon-pediatric-emergency-medicine-black:before {
	 content: "\ea90";
}
 .icon-pediatric-endocrinology-black:before {
	 content: "\ea91";
}
 .icon-pediatric-gastroenterology-black:before {
	 content: "\ea92";
}
 .icon-pediatric-hematology-oncology-black:before {
	 content: "\ea93";
}
 .icon-pediatric-hospital-medicine-black:before {
	 content: "\ea94";
}
 .icon-pediatric-infectious-diseases-black:before {
	 content: "\ea95";
}
 .icon-pediatric-nephrology-black:before {
	 content: "\ea96";
}
 .icon-pediatric-pulmonology-black:before {
	 content: "\ea97";
}
 .icon-pediatric-rheumatology-black:before {
	 content: "\ea98";
}
 .icon-pediatric-sleep-medicine-black:before {
	 content: "\ea99";
}
 .icon-pediatric-transplant-hepatology-black:before {
	 content: "\ea9a";
}
 .icon-pediatrics-adolescent-medicine-black:before {
	 content: "\ea9b";
}
 .icon-pediatrics-hospice-and-palliative-medicine-black:before {
	 content: "\ea9c";
}
 .icon-pediatrics-medical-toxicology-black:before {
	 content: "\ea9d";
}
 .icon-pediatrics-sports-medicine-black:before {
	 content: "\ea9e";
}
 .icon-anatomical-pathology-black:before {
	 content: "\ea4a";
}
 .icon-clinical-pathology-black:before {
	 content: "\ea4b";
}
 .icon-cytopathology-black:before {
	 content: "\ea4c";
}
 .icon-dermatopathology-black:before {
	 content: "\ea4d";
}
 .icon-hematopathology-black:before {
	 content: "\ea4e";
}
 .icon-neuropathology-black:before {
	 content: "\ea4f";
}
 .icon-pathology-anatomic-pathology-clinical-black:before {
	 content: "\ea50";
}
 .icon-pathology-blood-banking-transfusion-medicine-black:before {
	 content: "\ea51";
}
 .icon-pathology-chemical-black:before {
	 content: "\ea52";
}
 .icon-pathology-clinical-informatics-black:before {
	 content: "\ea53";
}
 .icon-pathology-forensic-black:before {
	 content: "\ea54";
}
 .icon-pathology-medical-microbiology-black:before {
	 content: "\ea55";
}
 .icon-pathology-molecular-genetic-black:before {
	 content: "\ea56";
}
 .icon-pathology-pediatric-black:before {
	 content: "\ea57";
}
 .icon-complex-pediatric-otolaryngology-black:before {
	 content: "\ea58";
}
 .icon-neurotology-black:before {
	 content: "\ea59";
}
 .icon-otolaryngology-sleep-medicine-black:before {
	 content: "\ea5a";
}
 .icon-orthopaedic-sports-medicine-black:before {
	 content: "\ea5c";
}
 .icon-orthopaedic-surgery-surgery-of-the-hand-black:before {
	 content: "\ea5d";
}
 .icon-ophthalmology-black:before {
	 content: "\ea5e";
}
 .icon-gynecologic-oncology-black:before {
	 content: "\ea5f";
}
 .icon-obstetrics-and-gynecology-critical-care-medicine-black:before {
	 content: "\ea60";
}
 .icon-obstetrics-and-gynecology-female-pelvic-medicine-and-reconstructive-surgery-black:before {
	 content: "\ea61";
}
 .icon-obstetrics-and-gynecology-hospice-and-palliative-medicine-black:before {
	 content: "\ea62";
}
 .icon-obstetrics-and-gynecology-maternal-and-fetal-medicine-black:before {
	 content: "\ea63";
}
 .icon-reproductive-endocrinology-infertility-black:before {
	 content: "\ea64";
}
 .icon-touchhand_black:before {
	 content: "\ea49";
}
 .icon-nuclear-medicine-black:before {
	 content: "\ea2e";
}
 .icon-neurological-surgery-black:before {
	 content: "\ea2f";
}
 .icon-molecular-genetic-pathology-black:before {
	 content: "\ea30";
}
 .icon-medical-biochemical-genetics-black:before {
	 content: "\ea31";
}
 .icon-laboratory-genetics-and-genomics-black:before {
	 content: "\ea32";
}
 .icon-clinical-molecular-genetics-and-genomics-black:before {
	 content: "\ea33";
}
 .icon-clinical-genetics-and-genomics-black:before {
	 content: "\ea34";
}
 .icon-clinical-cytogenetics-and-genomics-black:before {
	 content: "\ea35";
}
 .icon-clinical-biochemical-genetics-black:before {
	 content: "\ea36";
}
 .icon-transplant-hepatology-black:before {
	 content: "\ea37";
}
 .icon-rheumatology-black:before {
	 content: "\ea38";
}
 .icon-pulmonary-disease-black:before {
	 content: "\ea39";
}
 .icon-nephrology-black:before {
	 content: "\ea3a";
}
 .icon-medical-oncology-black:before {
	 content: "\ea3b";
}
 .icon-interventional-cardiology-black:before {
	 content: "\ea3c";
}
 .icon-internal-critical-care-medicine-black:before {
	 content: "\ea3d";
}
 .icon-infectious-disease-black:before {
	 content: "\ea3e";
}
 .icon-hematology-black:before {
	 content: "\ea3f";
}
 .icon-geriatric-medicine-black:before {
	 content: "\ea40";
}
 .icon-gastroenterology-black:before {
	 content: "\ea41";
}
 .icon-endocrinology-diabetes-and-metabolism-black:before {
	 content: "\ea42";
}
 .icon-clinical-cardiac-electrophysiology-black:before {
	 content: "\ea43";
}
 .icon-cardiovascular-disease-black:before {
	 content: "\ea44";
}
 .icon-advanced-heart-failure-and-transplant-cardiology-black:before {
	 content: "\ea45";
}
 .icon-adult-congenital-heart-disease-black:before {
	 content: "\ea46";
}
 .icon-adolescent-medicine-black:before {
	 content: "\ea47";
}
 .icon-family-geriatric-medicine-black:before {
	 content: "\ea48";
}
 .icon-anesthesiology-critical-care-medicine-black:before {
	 content: "\e9f0";
}
 .icon-critical-care-medicine-black:before {
	 content: "\e9f1";
}
 .icon-emergency-medical-services-black:before {
	 content: "\e9f2";
}
 .icon-hospice-and-palliative-medicine-black:before {
	 content: "\e9f3";
}
 .icon-medical-toxicology-black:before {
	 content: "\e9f4";
}
 .icon-neurocritical-care-black:before {
	 content: "\ea1d";
}
 .icon-pain-medicine-black:before {
	 content: "\ea1e";
}
 .icon-sports-medicine-black:before {
	 content: "\ea22";
}
 .icon-undersea-and-hyperbaric-medicine-black:before {
	 content: "\ea23";
}
 .icon-pediatric-dermatology-black:before {
	 content: "\ea25";
}
 .icon-colon-and-rectal-surgery-black:before {
	 content: "\ea26";
}
 .icon-critical-care-medicine-anesthesiology-black:before {
	 content: "\ea27";
}
 .icon-hospice-and-palliative-medicine-anesthesiology-black:before {
	 content: "\ea28";
}
 .icon-neurocritical-care-anesthesiology-black:before {
	 content: "\ea29";
}
 .icon-pain-medicine-anesthesiology-black:before {
	 content: "\ea2a";
}
 .icon-pediatric-anesthesiology-black:before {
	 content: "\ea2b";
}
 .icon-sleep-medicine-black:before {
	 content: "\ea2c";
}
 .icon-allergy-and-immunology-black:before {
	 content: "\ea2d";
}
 .icon-no-results-on-provider-sdk-query .path1:before {
	 content: "\e9ee";
	 color: #e6172c;
}
 .icon-no-results-on-provider-sdk-query .path2:before {
	 content: "\e9ef";
	 color: #000;
}
 .icon-qr-code-activation-successful .path1:before {
	 content: "\e9ec";
	 color: #000;
}
 .icon-qr-code-activation-successful .path2:before {
	 content: "\e9ed";
	 color: #30a039;
}
 .icon-qr-code-activation-black:before {
	 content: "\ea1f";
}
 .icon-settings-black:before {
	 content: "\ea20";
}
 .icon-available-provider-black:before {
	 content: "\e97e";
}
 .icon-ic_battery_85-24px .path1:before {
	 content: "\e986";
	 opacity: 0.401;
	 color: #30a039;
}
 .icon-ic_battery_85-24px .path2:before {
	 content: "\e98d";
	 color: #30a039;
}
 .icon-ambulatory-black:before {
	 content: "\e974";
}
 .icon-bariatrics-black:before {
	 content: "\e976";
}
 .icon-behavioral-health-black:before {
	 content: "\e977";
}
 .icon-cardiology-black:before {
	 content: "\e978";
}
 .icon-critical-care-black:before {
	 content: "\e979";
}
 .icon-dermatology-black:before {
	 content: "\e97a";
}
 .icon-ed-trauma-services-black:before {
	 content: "\e97b";
}
 .icon-general-consult-black:before {
	 content: "\e97d";
}
 .icon-maternal-fetal-health-black:before {
	 content: "\e98f";
}
 .icon-neurology-black1:before {
	 content: "\e980";
}
 .icon-orthopedics-black:before {
	 content: "\e981";
}
 .icon-palliative-care-black:before {
	 content: "\e982";
}
 .icon-pediatrics-black:before {
	 content: "\e983";
}
 .icon-primary-care-black:before {
	 content: "\e984";
}
 .icon-pulmonology-black:before {
	 content: "\e985";
}
 .icon-social-worker-black:before {
	 content: "\e993";
}
 .icon-surgery-black:before {
	 content: "\e987";
}
 .icon-urgent-care-black:before {
	 content: "\e988";
}
 .icon-wound-care-black:before {
	 content: "\e989";
}
 .icon-ascom-mobile-app-chat-messages-black:before {
	 content: "\e98a";
}
 .icon-laptop-website-provider-queue-black:before {
	 content: "\e98b";
}
 .icon-laptop-website-provider-video-call-black:before {
	 content: "\e98c";
}
 .icon-smartphone-app-provider-queue-black:before {
	 content: "\e995";
}
 .icon-smartphone-app-provider-video-call-black:before {
	 content: "\e98e";
}
 .icon-tablet-app-provider-queue-black:before {
	 content: "\ea1a";
}
 .icon-tablet-app-provider-video-call-black:before {
	 content: "\e990";
}
 .icon-laptop-website-patient-intake-form-black:before {
	 content: "\e991";
}
 .icon-laptop-website-patient-video-call-black:before {
	 content: "\e992";
}
 .icon-phone-app-patient-intake-form-black:before {
	 content: "\ea1b";
}
 .icon-phone-app-patient-video-call-black:before {
	 content: "\e994";
}
 .icon-tablet-app-patient-intake-form-black:before {
	 content: "\ea1c";
}
 .icon-tablet-app-patient-video-call-black:before {
	 content: "\e996";
}
 .icon-310-cart-black:before {
	 content: "\e997";
}
 .icon-750-webex-cart-black:before {
	 content: "\e998";
}
 .icon-audio-call-black:before {
	 content: "\e999";
}
 .icon-cart-black:before {
	 content: "\e99a";
}
 .icon-cart-cisco-750-cart-polycom-750-cart-vidyo-black:before {
	 content: "\e99c";
}
 .icon-data-integration-black:before {
	 content: "\e99d";
}
 .icon-desktop-cart-w-software-black:before {
	 content: "\e99e";
}
 .icon-far-end-camera-control-black:before {
	 content: "\e99f";
}
 .icon-full-cart-w-software-black:before {
	 content: "\e9a0";
}
 .icon-full-cart-with-ptz-camera-w-software-black:before {
	 content: "\e9a1";
}
 .icon-kiosk-with-200-black:before {
	 content: "\e9a2";
}
 .icon-laptop-black:before {
	 content: "\e9a3";
}
 .icon-meeting-room-black:before {
	 content: "\e9a4";
}
 .icon-tablet-black:before {
	 content: "\e9a5";
}
 .icon-television-app-black:before {
	 content: "\e9a6";
}
 .icon-television-app-v-2-black:before {
	 content: "\e9a7";
}
 .icon-virtual-meeting-room-black:before {
	 content: "\e9a9";
}
 .icon-cardionics-e-scope-stethoscope-black:before {
	 content: "\e9aa";
}
 .icon-firefly-dermatoscope-black:before {
	 content: "\e9ab";
}
 .icon-firefly-otoscope-black:before {
	 content: "\e9ac";
}
 .icon-horus-scope-1-black:before {
	 content: "\e9ad";
}
 .icon-horus-scope-2-black:before {
	 content: "\e9ae";
}
 .icon-littmann-3200-stethoscope-black:before {
	 content: "\e9af";
}
 .icon-thinklabs-one-stethoscope-black:before {
	 content: "\e9b0";
}
 .icon-webex-cart-black1:before {
	 content: "\e9b1";
}
 .icon-robot-black:before {
	 content: "\e9b2";
}
 .icon-consumer-black:before {
	 content: "\e9b3";
}
 .icon-patient-black:before {
	 content: "\e9b4";
}
 .icon-provider-education-black:before {
	 content: "\e9b5";
}
 .icon-staff-administrator-black:before {
	 content: "\e9b6";
}
 .icon-staff-patient-concierge-black:before {
	 content: "\e9b7";
}
 .icon-staff-provider-black:before {
	 content: "\e9b8";
}
 .icon-system-care-coordinator-black:before {
	 content: "\e9b9";
}
 .icon-system-patient-concierge-black:before {
	 content: "\e9ba";
}
 .icon-system-provider-black:before {
	 content: "\e9bb";
}
 .icon-system-virtualist-provider-black:before {
	 content: "\e9bc";
}
 .icon-affinity-relationships-black:before {
	 content: "\e9bd";
}
 .icon-assign-to-nobody-black:before {
	 content: "\e9be";
}
 .icon-chat-black:before {
	 content: "\e9bf";
}
 .icon-digital-marketing-black:before {
	 content: "\e9c0";
}
 .icon-email-retention-black:before {
	 content: "\e9c1";
}
 .icon-email-retention-campaign-black:before {
	 content: "\e9c2";
}
 .icon-exam-room-black:before {
	 content: "\e9c3";
}
 .icon-implementation-timeline-black:before {
	 content: "\e9c4";
}
 .icon-implementation-workbook-black:before {
	 content: "\e9c5";
}
 .icon-new-patient-black:before {
	 content: "\e9c6";
}
 .icon-other-device-black:before {
	 content: "\e9c7";
}
 .icon-payment-digital-black:before {
	 content: "\e9c8";
}
 .icon-payment-receipt-black:before {
	 content: "\e9c9";
}
 .icon-phone-call-black:before {
	 content: "\e9ca";
}
 .icon-post-medical-encounter-note-black:before {
	 content: "\e9cb";
}
 .icon-search-engine-marketing-black:before {
	 content: "\e9cc";
}
 .icon-search-patient-black:before {
	 content: "\e9cd";
}
 .icon-search-provider-black:before {
	 content: "\e9ce";
}
 .icon-skip-patient-black:before {
	 content: "\e9cf";
}
 .icon-target-black:before {
	 content: "\e9d0";
}
 .icon-testing-black:before {
	 content: "\e9d1";
}
 .icon-video-black:before {
	 content: "\e9d2";
}
 .icon-video-call-black:before {
	 content: "\e9d3";
}
 .icon-accountable-care-organization-black:before {
	 content: "\e9d4";
}
 .icon-ambulatory-black1:before {
	 content: "\e9d5";
}
 .icon-home-black:before {
	 content: "\e9d6";
}
 .icon-hospital-black:before {
	 content: "\e9d7";
}
 .icon-labs-black:before {
	 content: "\e9d8";
}
 .icon-long-term-acute-care-black:before {
	 content: "\e9d9";
}
 .icon-outpatient-clinics-black:before {
	 content: "\e9da";
}
 .icon-physician-practices-black:before {
	 content: "\e9fe";
}
 .icon-primary-care-black1:before {
	 content: "\ea12";
}
 .icon-prison-black:before {
	 content: "\ea13";
}
 .icon-rehab-center-black:before {
	 content: "\ea14";
}
 .icon-school-black:before {
	 content: "\ea15";
}
 .icon-skilled-nursing-black:before {
	 content: "\ea16";
}
 .icon-skilled-nursing-facility-black:before {
	 content: "\ea17";
}
 .icon-transport-black:before {
	 content: "\ea18";
}
 .icon-urgent-care-black1:before {
	 content: "\ea19";
}
 .icon-baseline-signal_wifi_0_bar_lock-24px .path1:before {
	 content: "\ea10";
	 color: #000;
}
 .icon-baseline-signal_wifi_0_bar_lock-24px .path2:before {
	 content: "\ea11";
	 color: #000;
	 opacity: 0.3;
}
 .icon-list-is-complete-black .path1:before {
	 content: "\ea08";
	 color: #000;
}
 .icon-list-is-complete-black .path2:before {
	 content: "\ea09";
	 color: #30a039;
}
 .icon-time-black:before {
	 content: "\ea0a";
}
 .icon-Wifi-problem .path1:before {
	 content: "\ea0b";
	 color: #313336;
}
 .icon-Wifi-problem .path2:before {
	 content: "\ea0c";
	 color: #e6172c;
}
 .icon-Wifi-problem .path3:before {
	 content: "\ea0d";
	 color: #fff;
}
 .icon-baseline-perm_scan_wifi-24px:before {
	 content: "\e9f5";
}
 .icon-baseline-signal_wifi_0_bar-24px:before {
	 content: "\e9f6";
	 opacity: 0.3;
}
 .icon-baseline-signal_wifi_1_bar_lock-24px .path1:before {
	 content: "\e9f7";
	 color: #000;
}
 .icon-baseline-signal_wifi_1_bar_lock-24px .path2:before {
	 content: "\e9f8";
	 color: #000;
	 opacity: 0.3;
}
 .icon-baseline-signal_wifi_1_bar_lock-24px .path3:before {
	 content: "\e9f9";
	 color: #000;
}
 .icon-baseline-signal_wifi_1_bar-24px .path1:before {
	 content: "\e9fa";
	 color: #000;
	 opacity: 0.3;
}
 .icon-baseline-signal_wifi_1_bar-24px .path2:before {
	 content: "\e9fb";
	 color: #000;
}
 .icon-baseline-signal_wifi_2_bar_lock-24px .path1:before {
	 content: "\e9fc";
	 color: #000;
}
 .icon-baseline-signal_wifi_2_bar_lock-24px .path2:before {
	 content: "\e9fd";
	 color: #000;
	 opacity: 0.3;
}
 .icon-baseline-signal_wifi_2_bar_lock-24px .path3:before {
	 content: "\e9ff";
	 color: #000;
}
 .icon-baseline-signal_wifi_2_bar-24px .path1:before {
	 content: "\ea00";
	 color: #000;
	 opacity: 0.3;
}
 .icon-baseline-signal_wifi_2_bar-24px .path2:before {
	 content: "\ea01";
	 color: #000;
}
 .icon-baseline-signal_wifi_3_bar_lock-24px .path1:before {
	 content: "\ea02";
	 color: #000;
	 opacity: 0.3;
}
 .icon-baseline-signal_wifi_3_bar_lock-24px .path2:before {
	 content: "\ea03";
	 color: #000;
}
 .icon-baseline-signal_wifi_3_bar-24px .path1:before {
	 content: "\ea04";
	 color: #000;
	 opacity: 0.3;
}
 .icon-baseline-signal_wifi_3_bar-24px .path2:before {
	 content: "\ea05";
	 color: #000;
}
 .icon-baseline-signal_wifi_4_bar_lock-24px:before {
	 content: "\ea06";
}
 .icon-baseline-signal_wifi_4_bar-24px:before {
	 content: "\ea07";
}
 .icon-call-failed .path1:before {
	 content: "\e9a8";
	 color: #000;
}
 .icon-call-failed .path2:before {
	 content: "\e9db";
	 color: #e6172c;
}
 .icon-low-battery .path1:before {
	 content: "\e9dc";
	 color: #000;
}
 .icon-low-battery .path2:before {
	 content: "\e9dd";
	 color: #ef9c23;
}
 .icon-unable-to-connect-to-a-network .path1:before {
	 content: "\e9e2";
	 color: #000;
}
 .icon-unable-to-connect-to-a-network .path2:before {
	 content: "\e9e3";
	 color: #e6172c;
}
 .icon-unable-to-connect-to-fleet-monitoring .path1:before {
	 content: "\e9e4";
	 color: #000;
}
 .icon-unable-to-connect-to-fleet-monitoring .path2:before {
	 content: "\e9e5";
	 color: #e6172c;
}
 .icon-unable-to-share-peripheral .path1:before {
	 content: "\e9e6";
	 color: #000;
}
 .icon-unable-to-share-peripheral .path2:before {
	 content: "\e9e7";
	 color: #e6172c;
}
 .icon-unable-to-upload-snapshot-to-the-case .path1:before {
	 content: "\e9e8";
	 color: #000;
}
 .icon-unable-to-upload-snapshot-to-the-case .path2:before {
	 content: "\e9e9";
	 color: #e6172c;
}
 .icon-update-failed .path1:before {
	 content: "\e9ea";
	 color: #000;
}
 .icon-update-failed .path2:before {
	 content: "\e9eb";
	 color: #e6172c;
}
 .icon-no-network .path1:before {
	 content: "\e9de";
	 color: #000;
}
 .icon-no-network .path2:before {
	 content: "\e9df";
	 color: #e6172c;
}
 .icon-qr-code-activation-failure .path1:before {
	 content: "\e9e0";
	 color: #000;
}
 .icon-qr-code-activation-failure .path2:before {
	 content: "\e9e1";
	 color: #e6172c;
}
 .icon-view_column_four:before {
	 content: "\e99b";
}
 .icon-view_col_two:before {
	 content: "\e918";
}
 .icon-view_col_diff:before {
	 content: "\e919";
}
 .icon-archive:before {
	 content: "\e916";
}
 .icon-icon_csv:before {
	 content: "\e909";
}
 .icon-icon_mp4:before {
	 content: "\e90a";
}
 .icon-icon_odp:before {
	 content: "\e90b";
}
 .icon-icon_odt:before {
	 content: "\e90c";
}
 .icon-icon_pdf:before {
	 content: "\e90d";
}
 .icon-icon_ppt:before {
	 content: "\e90e";
}
 .icon-icon_rar:before {
	 content: "\e90f";
}
 .icon-icon_rft:before {
	 content: "\e910";
}
 .icon-icon_txt:before {
	 content: "\e911";
}
 .icon-icon_wav:before {
	 content: "\e912";
}
 .icon-icon_word:before {
	 content: "\e913";
}
 .icon-icon_xsl:before {
	 content: "\e914";
}
 .icon-icon_zip:before {
	 content: "\e915";
}
 .icon-pin:before {
	 content: "\e907";
}
 .icon-unpin:before {
	 content: "\e908";
}
 .icon-voice-chat:before {
	 content: "\e906";
}
 .icon-pic-in-pic:before {
	 content: "\e904";
}
 .icon-pic-in-pic-off:before {
	 content: "\e905";
}
 .icon-cam-control:before {
	 content: "\e900";
}
 .icon-cam-exit:before {
	 content: "\e901";
}
 .icon-magn-minus:before {
	 content: "\e902";
}
 .icon-magn-plus:before {
	 content: "\e903";
}
 .icon-ic_battery_charging_0-24px:before {
	 content: "\e924";
	 color: #e6172c;
}
 .icon-ic_battery_charging_5-24px .path1:before {
	 content: "\e926";
	 color: #e6172c;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_5-24px .path2:before {
	 content: "\e927";
	 color: #e6172c;
}
 .icon-ic_battery_charging_10-24px .path1:before {
	 content: "\e928";
	 color: #e6172c;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_10-24px .path2:before {
	 content: "\e929";
	 color: #e6172c;
}
 .icon-ic_battery_charging_15-24px .path1:before {
	 content: "\e92a";
	 color: #ef9c23;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_15-24px .path2:before {
	 content: "\e92b";
	 color: #ef9c23;
}
 .icon-ic_battery_charging_20-24px .path1:before {
	 content: "\e92c";
	 color: #ef9c23;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_20-24px .path2:before {
	 content: "\e92d";
	 color: #ef9c23;
}
 .icon-ic_battery_charging_25-24px .path1:before {
	 content: "\e92e";
	 color: #ef9c23;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_25-24px .path2:before {
	 content: "\e92f";
	 color: #ef9c23;
}
 .icon-ic_battery_charging_30-24px .path1:before {
	 content: "\e930";
	 color: #ef9c23;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_30-24px .path2:before {
	 content: "\e931";
	 color: #ef9c23;
}
 .icon-ic_battery_charging_35-24px .path1:before {
	 content: "\e932";
	 color: #fcd54e;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_35-24px .path2:before {
	 content: "\e933";
	 color: #fcd54e;
}
 .icon-ic_battery_charging_40-24px .path1:before {
	 content: "\e934";
	 color: #fcd54e;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_40-24px .path2:before {
	 content: "\e935";
	 color: #fcd54e;
}
 .icon-ic_battery_charging_45-24px .path1:before {
	 content: "\e936";
	 color: #fcd54e;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_45-24px .path2:before {
	 content: "\e937";
	 color: #fcd54e;
}
 .icon-ic_battery_charging_50-24px .path1:before {
	 content: "\e938";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_50-24px .path2:before {
	 content: "\e939";
	 color: #30a039;
}
 .icon-ic_battery_charging_55-24px .path1:before {
	 content: "\e93a";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_55-24px .path2:before {
	 content: "\e93b";
	 color: #30a039;
}
 .icon-ic_battery_charging_60-24px .path1:before {
	 content: "\e93c";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_60-24px .path2:before {
	 content: "\e93d";
	 color: #30a039;
}
 .icon-ic_battery_charging_65-24px .path1:before {
	 content: "\e93e";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_65-24px .path2:before {
	 content: "\e93f";
	 color: #30a039;
}
 .icon-ic_battery_charging_70-24px .path1:before {
	 content: "\e940";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_70-24px .path2:before {
	 content: "\e941";
	 color: #30a039;
}
 .icon-ic_battery_charging_75-24px .path1:before {
	 content: "\e942";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_75-24px .path2:before {
	 content: "\e943";
	 color: #30a039;
}
 .icon-ic_battery_charging_80-24px .path1:before {
	 content: "\e944";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_80-24px .path2:before {
	 content: "\e945";
	 color: #30a039;
}
 .icon-ic_battery_charging_85-24px .path1:before {
	 content: "\e946";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_85-24px .path2:before {
	 content: "\e947";
	 color: #30a039;
}
 .icon-ic_battery_charging_90-24px .path1:before {
	 content: "\e948";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_90-24px .path2:before {
	 content: "\e949";
	 color: #30a039;
}
 .icon-ic_battery_charging_95-24px .path1:before {
	 content: "\e94a";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_charging_95-24px .path2:before {
	 content: "\e94b";
	 color: #30a039;
}
 .icon-ic_battery_charging_full-24px:before {
	 content: "\e94c";
	 color: #30a039;
}
 .icon-ic_battery_full-24px:before {
	 content: "\e94d";
	 color: #30a039;
}
 .icon-ic_battery_unknown-24px:before {
	 content: "\e94e";
}
 .icon-ic_battery_0-24px:before {
	 content: "\e94f";
	 color: #e6172c;
	 opacity: 0.401;
}
 .icon-ic_battery_5-24px .path1:before {
	 content: "\e950";
	 color: #e6172c;
	 opacity: 0.401;
}
 .icon-ic_battery_5-24px .path2:before {
	 content: "\e951";
	 color: #e6172c;
}
 .icon-ic_battery_10-24px .path1:before {
	 content: "\e952";
	 color: #e6172c;
	 opacity: 0.401;
}
 .icon-ic_battery_10-24px .path2:before {
	 content: "\e953";
	 color: #e6172c;
}
 .icon-ic_battery_15-24px .path1:before {
	 content: "\e954";
	 color: #ef9c23;
	 opacity: 0.401;
}
 .icon-ic_battery_15-24px .path2:before {
	 content: "\e955";
	 color: #ef9c23;
}
 .icon-ic_battery_20-24px .path1:before {
	 content: "\e956";
	 color: #ef9c23;
	 opacity: 0.401;
}
 .icon-ic_battery_20-24px .path2:before {
	 content: "\e957";
	 color: #ef9c23;
}
 .icon-ic_battery_25-24px .path1:before {
	 content: "\e958";
	 color: #ef9c23;
	 opacity: 0.401;
}
 .icon-ic_battery_25-24px .path2:before {
	 content: "\e959";
	 color: #ef9c23;
}
 .icon-ic_battery_30-24px .path1:before {
	 content: "\e95a";
	 color: #ef9c23;
	 opacity: 0.401;
}
 .icon-ic_battery_30-24px .path2:before {
	 content: "\e95b";
	 color: #ef9c23;
}
 .icon-ic_battery_35-24px .path1:before {
	 content: "\e95c";
	 color: #fcd54e;
	 opacity: 0.401;
}
 .icon-ic_battery_35-24px .path2:before {
	 content: "\e95d";
	 color: #fcd54e;
}
 .icon-ic_battery_40-24px .path1:before {
	 content: "\e95e";
	 color: #fcd54e;
	 opacity: 0.401;
}
 .icon-ic_battery_40-24px .path2:before {
	 content: "\e95f";
	 color: #fcd54e;
}
 .icon-ic_battery_45-24px .path1:before {
	 content: "\e960";
	 color: #fcd54e;
	 opacity: 0.401;
}
 .icon-ic_battery_45-24px .path2:before {
	 content: "\e961";
	 color: #fcd54e;
}
 .icon-ic_battery_50-24px .path1:before {
	 content: "\e962";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_50-24px .path2:before {
	 content: "\e963";
	 color: #30a039;
}
 .icon-ic_battery_55-24px .path1:before {
	 content: "\e964";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_55-24px .path2:before {
	 content: "\e965";
	 color: #30a039;
}
 .icon-ic_battery_60-24px .path1:before {
	 content: "\e966";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_60-24px .path2:before {
	 content: "\e967";
	 color: #30a039;
}
 .icon-ic_battery_65-24px .path1:before {
	 content: "\e968";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_65-24px .path2:before {
	 content: "\e969";
	 color: #30a039;
}
 .icon-ic_battery_70-24px .path1:before {
	 content: "\e96a";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_70-24px .path2:before {
	 content: "\e96b";
	 color: #30a039;
}
 .icon-ic_battery_75-24px .path1:before {
	 content: "\e96c";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_75-24px .path2:before {
	 content: "\e96d";
	 color: #30a039;
}
 .icon-ic_battery_80-24px .path1:before {
	 content: "\e96e";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_80-24px .path2:before {
	 content: "\e96f";
	 color: #30a039;
}
 .icon-ic_battery_90-24px .path1:before {
	 content: "\e970";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_90-24px .path2:before {
	 content: "\e971";
	 color: #30a039;
}
 .icon-ic_battery_95-24px .path1:before {
	 content: "\e972";
	 color: #30a039;
	 opacity: 0.401;
}
 .icon-ic_battery_95-24px .path2:before {
	 content: "\e973";
	 color: #30a039;
}
 .icon-check_circle_outline:before {
	 content: "\e91d";
}
 .icon-phone_book:before {
	 content: "\e91e";
}
 .icon-firefly_48px:before {
	 content: "\e91f";
}
 .icon-screenshare-popout:before {
	 content: "\e921";
}
 .icon-horus_48px:before {
	 content: "\e922";
}
 .icon-ic_battery_alert-24px:before {
	 content: "\e923";
}
 .icon-ic_battery_charging_5-24px2:before {
	 content: "\e925";
}
 .icon-ic_videocam_fecc_48px:before {
	 content: "\e975";
}
 .icon-ethernet:before {
	 content: "\e91c";
}
 .icon-ethernet_dc_48px:before {
	 content: "\e920";
}
 .icon-quality_settings:before {
	 content: "\e91b";
}
 .icon-recall:before {
	 content: "\e91a";
}
 .icon-steth:before {
	 content: "\e917";
}
 .icon-baseline-info:before {
	 content: "\ead5";
}
 .icon-outline-info:before {
	 content: "\ead6";
}
