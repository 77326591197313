/* links styles in one/patients/static/shared/css/theme.css */

// Lighten a color
@function tint($color, $percentage) {
  @return mix(#fff, $color, $percentage);
}

// Darken a color
@function shade($color, $percentage) {
  @return mix(#000, $color, $percentage);
}

html{
  font-weight: $fw-normal;
  font-family: $main-font;
  font-size: $fz-normal;
  line-height: 1.75rem;
  color: $gray-dark;
}

// Header Styles
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { font-weight: inherit; }

h1 {
  font-weight: $fw-bold;
  font-size: $fz-h1;
  line-height: 3.5rem;
  margin: 0 0 1.5rem;
}
h2 {
  font-weight: $fw-bold;
  font-size: $fz-h2;
  line-height: 2.75rem;
  margin: 0 0 1.5rem;
}
h3 {
  font-weight: $fw-bold;
  font-size: $fz-h3;
  line-height: 2.5rem;
  margin: 0 0 1.5rem;
}
h4 {
  font-weight: $fw-bold;
  font-size: $fz-h4;
  line-height: 2rem;
  margin: 0 0 1.75rem;
}
h5 {
  font-weight: $fw-bold;
  font-size: $fz-h5;
  line-height: 1.75rem;
  margin: 0 0 1.75rem;
}
h6 {
  font-weight: $fw-bold;
  font-size: $fz-h6;
  line-height: 1.5rem;
  margin: 0 0 1.75rem;
}
p {
  margin: 0 0 1.5rem;
}

// Text Styles
mark   { background-color: $primary; color: $white !important; }
s      { text-decoration: line-through; }
u      { text-decoration: underline; }
em     { font-style: italic; }
strong, b { font-weight: $fw-bold; }
pre    { font-family: 'Cutive Mono', monospace; }

.display {
  font-weight: $fw-light;
  margin: 0 0 1.5rem;
  line-height: 4.75rem;
  font-size: $fz-display;
}
.blockquote {
  font-weight: $fw-normal;
  margin: 0;
  line-height: 2rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  font-weight: $fw-bold;
  margin: 0;
  line-height: 1.25rem;
  font-size: 0.875rem;
}
.cite {
  font-weight: $fw-light;
  line-height: 1.25rem;
  font-size: 0.875rem;
}
.lead {
  font-size: 112.5% !important;
}
.overline, .text-uppercase {
  text-transform: uppercase !important;
}
.small {
  font-weight: $fw-normal !important;
  line-height: 1.25rem !important;
  font-size: $fz-small !important;
}

@function tintLink($color, $percentage) {
  @return mix(#d8d8d8, $color, $percentage);
}
a, a.link {
    &:link {
        text-decoration: none;
        outline: none;
        color: $link-color;
        // Gets rid of tap active state
        -webkit-tap-highlight-color: transparent;
        cursor:pointer;
    }
    &:visited {
        color: $deep-purple;
        &:hover {
            color: $deep-purple;
            text-decoration: underline;
        }
    }
    &:hover {
        color: tintLink($primary, 20%);
        text-decoration: underline;
    }
    &:active {
        color: tintLink($primary, 30%);
        text-decoration: none;
    }
}
a.link {
  text-decoration: none;
  outline: none;
  color: $link-color;
  -webkit-tap-highlight-color: transparent;
  cursor:pointer;
}
a[ng-click], a[href=""]{
    text-decoration: none;
    outline: none;
    color: $link-color;
    -webkit-tap-highlight-color: transparent;
    cursor:pointer;
    &:visited {
        text-decoration: none;
        outline: none;
        color: $link-color;
        &:hover {
            text-decoration: none;
        }
    }
    &:hover {
        color: tintLink($primary, 20%);
        text-decoration: underline;
    }
    &:active {
        color: tintLink($primary, 30%);
        text-decoration: none;
    }
}
a.btn, a.btn-flat {
    &, &:link, &:visited, &:visited:hover, &:hover, &:active {
        text-decoration: none;
        outline: none;
        color: $white;
    }
}

ol, ol:not([class]), ol.ng-scope {
  list-style-type: decimal;
  margin: 1.5rem 0;
  li {
    line-height: 2rem;
  }
  ol, ol:not([class]), ol.ng-scope {
    list-style-type: lower-alpha;
    li {
      list-style-type: lower-alpha;
    }
      ol, ol:not([class]), ol.ng-scope {
      list-style-type: lower-roman;
      li {
        list-style-type: lower-roman;
      }
    }
  }
}
ul, ul:not([class]), ul.ng-scope:not([class]), ul.browser-default, ul.list {
  list-style-type: disc;
  padding-left: 40px;
  margin: 1.5rem 0;
  li {
    list-style-type: disc;
    line-height: 2rem;
  }
  ul, ul:not([class]), ul.ng-scope:not([class]) {
    list-style-type: circle;
    li {
      list-style-type: circle;
    }
    ul, ul:not([class]), ul.ng-scope:not([class]) {
    list-style-type: square;
      li {
        list-style-type: square;
      }
    }
  }
}

ol, ul {
  &.not-list {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    li {
      list-style-type: none !important;
      line-height: inherit;
    }
    ol, ul {
      list-style-type: none;
      padding-left: 0;
      margin: 0;
      li {
        list-style-type: none !important;
        line-height: inherit;
      }
      ol, ul {
        list-style-type: none;
        padding-left: 0;
        margin: 0;
        li {
          list-style-type: none !important;
          line-height: inherit;
        }
      }
    }
  }
}

.fz-display {
  font-size: $fz-display !important;
}
.fz-h1 {
  font-size: $fz-h1 !important;
}
.fz-h2 {
  font-size: $fz-h2 !important;
}
.fz-h3 {
  font-size: $fz-h3 !important;
}
.fz-h4 {
  font-size: $fz-h4 !important;
}
.fz-h5 {
  font-size: $fz-h5 !important;
}
.fz-h6 {
  font-size: $fz-h6 !important;
}
.fz-lead {
  font-size: $fz-lead !important;
}
.fz-normal {
  font-size: $fz-normal !important;
}
.fz-small {
  font-size: $fz-small !important;
}
.fz-xsmall {
  font-size: $fz-xsmall !important;
}

.fw-bold {
  font-weight: $fw-bold !important;
}
.fw-normal {
  font-weight: $fw-normal !important;
}
.fw-light {
  font-weight: $fw-light !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}
