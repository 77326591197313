a.btn, a.btn-flat, a.btn:link, a.btn-flat:link, a.btn:visited, a.btn-flat:visited, a.btn:visited:hover, a.btn-flat:visited:hover, a.btn:hover, a.btn-flat:hover, a.btn:active, a.btn-flat:active {
	 text-decoration: none;
	 outline: none;
	 color: #fff;
}

.btn {
    display: inline-block;
    border-radius: 25px;
    font-size: 20px;
    line-height: 1.6;
    cursor: pointer;
    font-weight: 600;
    min-width: 256px;
    padding: 9px 20px;
    transition: all 0.3s ease;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    white-space: normal;
}
.center-arrow .btn {
    min-width: auto;
}

.btn:active,
.btn.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-small {
    min-width: 184px;
    padding: 7px 20px;
}
.btn-large {
    min-width: 480px;
}

@media (max-width: 767px) {
    .btn {
        min-width: 120px;
    }
}

/* btn primary styles in one/patients/static/shared/css/theme.css */

/* btn danger */
.btn-danger {
    background-color: #e6172c;
}
.btn-danger:hover {
    background-color: #ea3a4c;
    border-color: #ea3a4c;
}
.btn-danger:active,
.btn-danger.active,
.btn-danger:focus {
    background-color: #ec5161;
    border-color: #ec5161;
}
.btn-danger-outline {
    border: 1px solid #e6172c;
    color: #e6172c!important;
    background-color: transparent;
}
.btn-danger-outline:hover {
    color: #fff!important;
    background-color: #ea3a4c;
    border-color: #ea3a4c;
}
.btn-danger:active:hover,
.btn-danger:active:focus,
.btn-danger:active.focus,
.btn-danger.active:hover,
.btn-danger.active:focus,
.btn-danger.active.focus,
.open>.btn-danger.dropdown-toggle:hover,
.open>.btn-danger.dropdown-toggle:focus,
.open>.btn-danger.dropdown-toggle.focus {
    background-color: #ec5161;
    border-color: #ec5161;
}

/* btn warning */
.btn-warning {
    background-color: #e7741c;
}
.btn-warning:hover {
    background-color: #eb893e!important;
    border-color: #eb893e!important;
}
.btn-warning:active,
.btn-warning.active,
.btn-warning:focus {
    background-color: #ed9755;
    border-color: #ed9755;
}
.btn-warning-outline {
    border: 1px solid #e7741c;
    color: #e7741c!important;
    background-color: transparent;
}
.btn-warning-outline:hover {
    color: #fff!important;
    background-color: #eb893e;
    border: 1px solid #eb893e;
}
.btn-warning:active:hover,
.btn-warning:active:focus,
.btn-warning:active.focus,
.btn-warning.active:hover,
.btn-warning.active:focus,
.btn-warning.active.focus,
.open>.btn-warning.dropdown-toggle:hover,
.open>.btn-warning.dropdown-toggle:focus,
.open>.btn-warning.dropdown-toggle.focus {
    background-color: #ed9755;
    border-color: #ed9755;
}

/* btn success */
.btn-success,
.btn-success-large {
    background-color: #30a039;
}
.btn-success:hover,
.btn-success-large:hover {
    background-color: #4fae57;
    border-color: #4fae57;
}
.btn-success:active,
.btn-success.active,
.btn-success:focus {
    background-color: #64b86b;
    border-color: #64b86b;
}
.btn-success-outline {
    border: 1px solid #30a039!important;
    color: #30a039!important;
    background-color: transparent;
}
.btn-success-outline:hover {
    color: #fff!important;
    background-color: #4fae57;
    border: 1px solid #4fae57!important;
}
.btn-success:active:hover,
.btn-success:active:focus,
.btn-success:active.focus,
.btn-success.active:hover,
.btn-success.active:focus,
.btn-success.active.focus,
.open>.btn-success.dropdown-toggle:hover,
.open>.btn-success.dropdown-toggle:focus,
.open>.btn-success.dropdown-toggle.focus {
    background-color: #64b86b;
    border-color: #64b86b;
}

/* btn disabled */
.btn-disabled,
.disabled {
    pointer-events: none;
    background: rgba(0, 0, 0, 0.12) !important;
    box-shadow: none !important;
    color: rgba(0, 0, 0, 0.26) !important;
    cursor: default;
    border: none;
}
