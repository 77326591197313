/*Notifications*/
.noty_body {
	overflow: hidden;
}
.np-flash-dialog {
	position: absolute;
	width: 100%;
	bottom: -50px;
	height: 49px;
	z-index: 100;
	color: $white;
	transition: bottom ease .5s;
	&.static {
	  position: static;
	}
	.wrapper {
	  padding: 1rem 1.5rem;
	}
	p {
	  margin: 0;
	}
	.row {
	  margin-bottom: 0;
	}
	.material-icons {
	  vertical-align: -6px;
	}
  }
  .dialog-active {
	.np-flash-dialog {
	  bottom: 0;
	}
  }
  
  .flash-dialog {
	color: $white;
	padding: 1rem 1.5rem;
	p {
	  margin: 0;
	}
	.row {
	  margin-bottom: 0;
	}
	.material-icons {
	  vertical-align: -6px;
	}
	a, a:hover {
	  text-decoration: none;
	}
  }
  
  //noty
  $noty-primary-color: rgba($gray-dark, .9);
  $noty-default-width: 368px;
  $noty-corner-space: .5rem;
  
  .noty_layout_mixin {
	position: fixed;
	margin: 0;
	padding: 0;
	z-index: 10;
	transform: translateZ(0) scale(1.0, 1.0);
	backface-visibility: hidden;
	-webkit-font-smoothing: subpixel-antialiased;
	filter: blur(0);
	-webkit-filter: blur(0);
	max-width: 90%;
  }
  
  #noty_layout__top {
	@extend .noty_layout_mixin;
	top: 0;
	left: 1rem;
	width: 90%;
  }
  
  #noty_layout__topLeft {
	@extend .noty_layout_mixin;
	top: $noty-corner-space;
	left: $noty-corner-space;
	width: $noty-default-width;
  }
  
  #noty_layout__topCenter {
	@extend .noty_layout_mixin;
	top: .5rem;
	left: 50%;
	width: $noty-default-width;
	transform: translate(calc(-50% - .5px)) translateZ(0) scale(1.0, 1.0);
	-ms-transform: translate(-50%) translateZ(0) scale(1.0, 1.0);
  }
  
  #noty_layout__topRight {
	@extend .noty_layout_mixin;
	top: 60px;
	right: $noty-corner-space;
	width: $noty-default-width;
  }
  
  #noty_layout__bottom {
	@extend .noty_layout_mixin;
	bottom: 0;
	left: 1rem;
	width: 90%;
  }
  
  #noty_layout__bottomLeft {
	@extend .noty_layout_mixin;
	bottom: $noty-corner-space;
	left: $noty-corner-space;
	width: $noty-default-width;
  }
  
  #noty_layout__bottomCenter {
	@extend .noty_layout_mixin;
	bottom: .5rem;
	left: 50%;
	width: 100% !important;
	transform: translate(calc(-50% - .5px)) translateZ(0) scale(1.0, 1.0);
	-ms-transform: translate(-50%) translateZ(0) scale(1.0, 1.0);
	border-radius: 0.25rem;
	z-index: 10001;
	&.wide {
		width: 100%;
		max-width: 800px;
	}
  }
  @media (max-width: 767px) {
	#noty_layout__bottomCenter {
	  width: calc(100% - 1rem);
	  max-width: calc(100% - 1rem);
	  .noty_bar {
		max-width: 100%;
	  }
	}
  }
  
  #noty_layout__bottomRight {
	@extend .noty_layout_mixin;
	bottom: $noty-corner-space;
	right: $noty-corner-space;
	width: $noty-default-width;
  }
  
  #noty_layout__center {
	@extend .noty_layout_mixin;
	top: 50%;
	left: 50%;
	width: $noty-default-width;
	transform: translate(calc(-50% - .5px), calc(-50% - .5px)) translateZ(0) scale(1.0, 1.0);
	-ms-transform: translate(-50%, -50%) translateZ(0) scale(1.0, 1.0);
  }
  
  #noty_layout__centerLeft {
	@extend .noty_layout_mixin;
	top: 50%;
	left: $noty-corner-space;
	width: $noty-default-width;
	transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1.0, 1.0);
	-ms-transform: translate(0, -50%) translateZ(0) scale(1.0, 1.0);
  }
  
  #noty_layout__centerRight {
	@extend .noty_layout_mixin;
	top: 50%;
	right: $noty-corner-space;
	width: $noty-default-width;
	transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1);
	-ms-transform: translate(0, -50%) translateZ(0) scale(1, 1);
  }
  
  .noty_progressbar {
	display: none;
  }
  
  .noty_has_timeout.noty_has_progressbar .noty_progressbar {
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	height: 3px;
	width: 100%;
	background-color: $gray-mid;
	opacity: 0.2;
	filter: alpha(opacity=10)
  }
  .noty_bar {
	-webkit-backface-visibility: hidden;
	-webkit-transform: translate(0, 0) translateZ(0) scale(1.0, 1.0);
	transform: translate(0, 0) scale(1.0, 1.0);
	-webkit-font-smoothing: subpixel-antialiased;
	overflow: hidden;
	position: relative;
	border-radius: .25rem !important;
	margin: 0;
	.noty_body {
	  padding: 1rem 4rem 1rem 2rem !important;
	  display: flex;
	  flex: 1 0 auto;
	  margin: auto;
	  background:rgb(49, 51, 54);
	  color: $white;
	}
	.noty_buttons {
	  padding: .5rem;
	}
	.flash-dialog {
	  padding: .5rem .5rem .5rem 1.5rem;
	  border-radius: 0.25rem;
	  background-color: rgba($gray-dark, .9);
	  margin: auto;
	  min-width: 100%;
	  @media (min-width: 600px) {
		min-width: 370px;
		max-width: 568px;
	  }
	  &.wide {
		@media (min-width: 600px) {
		  min-width: 100%;
		  max-width: 100%;
		}
	  }
	  p {
		margin: 0;
	  }
	  .icon-link {
		padding: 0;
		margin-left: .5rem;
	  }
	  .wrapper {
		@extend .flex;
		@extend .flex-row;
		@extend .items-center;
		@extend .justify-between;
		@extend .flex-wrap;
		max-width: 100%;
		margin: 0;
	  }
	  .wrapper__text {
		@extend .flex;
		@extend .flex-row;
		@extend .flex-auto;
		@extend .flex-wrap;
		max-width: calc(100% - 60px);
	  }
	  .wrapper__actions {
		@extend .flex;
		@extend .flex-row;
		@extend .flex-wrap;
		@extend .items-center;
		@extend .flex-auto;
	  }
	}
  }
  
  .noty_effects_open {
	opacity: 0;
	transform: translate(50%);
	animation: noty_anim_in .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	animation-fill-mode: forwards;
  }
  
  .noty_effects_close {
	animation: noty_anim_out .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	animation-fill-mode: forwards;
  }
  
  .noty_fix_effects_height {
	animation: noty_anim_height 75ms ease-out;
  }
  .noty_close_with_click {
	cursor: pointer;
  }

  .noty_close_button {
	position: absolute;
	top: 6px !important;
	right: 6px !important;
	font-weight: bold;
	width: 48px !important;
	height: 48px !important;
	text-align: center;
	border-radius: 50% !important;
	line-height: 48px !important;
	cursor: pointer;
	transition: all .2s ease-out;
	overflow: hidden;
	color: $white;
	font-size: 26px;
	&:hover {
		background: rgba(255, 255, 255, 0.2) !important;
	}
  }
  
  .noty_modal {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: $black;
	z-index: 10000;
	opacity: .3;
	left: 0;
	top: 0;
  }
  
  .noty_modal.noty_modal_open {
	opacity: 0;
	animation: noty_modal_in .3s ease-out;
  }
  .noty_modal.noty_modal_close {
	animation: noty_modal_out .3s ease-out;
	animation-fill-mode: forwards;
  }
  
  @keyframes noty_modal_in {
	100% {
	  opacity: .3;
	}
  }
  @keyframes noty_modal_out {
	100% {
	  opacity: 0;
	}
  }
  
  @keyframes noty_modal_out {
	100% {
	  opacity: 0;
	}
  }
  
  @keyframes noty_anim_in {
	100% {
	  transform: translate(0);
	  opacity: 1;
	}
  }
  
  @keyframes noty_anim_out {
	100% {
	  transform: translate(50%);
	  opacity: 0;
	}
  }
  
  @keyframes noty_anim_height {
	100% {
	  height: 0;
	}
  }

  .notification-carousel {
	.notifications-carousel__item {
	  height: 3rem;
	  line-height: 34px;
	  display: flex !important;
	  flex-direction: row;
	  align-items: center;
	  justify-content: center;
	  color: $white;
	  font-size: 1.125rem;
	  .btn-flat {
		@extend .btn-primary;
	  }
	}
  }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}