/* Basscss Margin */
 .m-0 {
	 margin: 0 !important;
}
 .m-1 {
	 margin: 0.5rem !important;
}
 .m-2 {
	 margin: 1rem !important;
}
 .m-3 {
	 margin: 2rem !important;
}
 .m-4 {
	 margin: 4rem !important;
}
 .m-3-5 {
	 margin: 3.5rem !important;
}
 .m-t0 {
	 margin-top: 0 !important;
}
 .m-t1 {
	 margin-top: 0.5rem !important;
}
 .m-t15 {
	 margin-top: 1.5rem !important;
}
 .m-t2 {
	 margin-top: 1rem !important;
}
 .m-t3 {
	 margin-top: 2rem !important;
}
 .m-t25 {
	 margin-top: 2.5rem !important;
}
 .m-t35 {
	 margin-top: 3rem !important;
}
 .m-t3-5 {
	 margin-top: 3.5rem !important;
}
 .m-t4 {
	 margin-top: 4rem !important;
}
 .m-r0 {
	 margin-right: 0 !important;
}
 .m-r1 {
	 margin-right: 0.5rem !important;
}
 .m-r15 {
	 margin-right: 1.5rem !important;
}
 .m-r2 {
	 margin-right: 1rem !important;
}
 .m-r3 {
	 margin-right: 2rem !important;
}
 .m-r3-5 {
	 margin-right: 3.5rem !important;
}
 .m-r4 {
	 margin-right: 4rem !important;
}
 .m-l0 {
	 margin-left: 0 !important;
}
 .m-l1 {
	 margin-left: 0.5rem !important;
}
 .m-l15 {
	 margin-left: 1.5rem !important;
}
 .m-l2 {
	 margin-left: 1rem !important;
}
 .m-l3 {
	 margin-left: 2rem !important;
}
 .m-l3-5 {
	 margin-left: 3.5rem !important;
}
 .m-l4 {
	 margin-left: 4rem !important;
}
 .m-b0 {
	 margin-bottom: 0 !important;
}
 .m-b1 {
	 margin-bottom: 0.5rem !important;
}
 .m-b15 {
	 margin-bottom: 1.5rem !important;
}
 .m-b2 {
	 margin-bottom: 1rem !important;
}
 .m-b3 {
	 margin-bottom: 2rem !important;
}
 .m-b25 {
	 margin-bottom: 2.5rem !important;
}
 .m-b35 {
	 margin-bottom: 3rem !important;
}
 .m-b3-5 {
	 margin-bottom: 3.5rem !important;
}
 .m-b4 {
	 margin-bottom: 4rem !important;
}
 .m-x0 {
	 margin-left: 0 !important;
	 margin-right: 0 !important;
}
 .m-x1 {
	 margin-left: 0.5rem !important;
	 margin-right: 0.5rem !important;
}
 .m-x2 {
	 margin-left: 1rem !important;
	 margin-right: 1rem !important;
}
 .m-x3 {
	 margin-left: 2rem !important;
	 margin-right: 2rem !important;
}
 .m-x3-5 {
	 margin-left: 3.5rem !important;
	 margin-right: 3.5rem !important;
}
 .m-x4 {
	 margin-left: 4rem !important;
	 margin-right: 4rem !important;
}
 .m-y0 {
	 margin-top: 0 !important;
	 margin-bottom: 0 !important;
}
 .m-y1 {
	 margin-top: 0.5rem !important;
	 margin-bottom: 0.5rem !important;
}
 .m-y15 {
	 margin-top: 1.5rem !important;
	 margin-bottom: 1.5rem !important;
}
 .m-y2 {
	 margin-top: 1rem !important;
	 margin-bottom: 1rem !important;
}
 .m-y3 {
	 margin-top: 2rem !important;
	 margin-bottom: 2rem !important;
}
 .m-y3-5 {
	 margin-top: 3.5rem !important;
	 margin-bottom: 3.5rem !important;
}
 .m-y4 {
	 margin-top: 4rem !important;
	 margin-bottom: 4rem !important;
}
 .m-tn1 {
	 margin-top: -0.5rem !important;
}
 .m-tn2 {
	 margin-top: -1rem !important;
}
 .m-tn15 {
	 margin-top: -1.5rem !important;
}
 .m-tn3 {
	 margin-top: -2rem !important;
}
 .m-tn25 {
	 margin-top: -2.5rem !important;
}
 .m-tn35 {
	 margin-top: -3rem !important;
}
 .m-tn4 {
	 margin-top: -4rem !important;
}
 .m-xn1 {
	 margin-left: -0.5rem !important;
	 margin-right: -0.5rem !important;
}
 .m-xn15 {
	 margin-left: -1.5rem !important;
	 margin-right: -1.5rem !important;
}
 .m-xn2 {
	 margin-left: -1rem !important;
	 margin-right: -1rem !important;
}
 .m-xn3 {
	 margin-left: -2rem !important;
	 margin-right: -2rem !important;
}
 .m-xn4 {
	 margin-left: -4rem !important;
	 margin-right: -4rem !important;
}
 .m-yn2 {
	 margin-top: -1rem !important;
	 margin-bottom: -1rem !important;
}
 .m-l-auto {
	 margin-left: auto !important;
}
 .m-r-auto {
	 margin-right: auto !important;
}
 .m-x-auto {
	 margin-left: auto !important;
	 margin-right: auto !important;
}
 .m-y-auto {
	 margin-top: auto !important;
	 margin-bottom: auto !important;
}
 .mt2 {
	 margin-top: 2em;
}


/* Basscss Padding */
 .p0 {
	 padding: 0 !important;
}
 .p1 {
	 padding: 0.5rem !important;
}
 .p2 {
	 padding: 1rem !important;
}
 .p25 {
	 padding: 2.5rem !important;
}
 .p3 {
	 padding: 2rem !important;
}
 .p4 {
	 padding: 4rem !important;
}
 .p3-5 {
	 padding: 3.5rem !important;
}
 .pt0 {
	 padding-top: 0 !important;
}
 .pt1 {
	 padding-top: 0.5rem !important;
}
 .pt15 {
	 padding-top: 1.5rem !important;
}
 .pt2 {
	 padding-top: 1rem !important;
}
 .pt3 {
	 padding-top: 2rem !important;
}
 .pt25 {
	 padding-top: 2.5rem !important;
}
 .pt35 {
	 padding-top: 3rem !important;
}
 .pt3-5 {
	 padding-top: 3.5rem !important;
}
 .pt4 {
	 padding-top: 4rem !important;
}
 .pr0 {
	 padding-right: 0 !important;
}
 .pr1 {
	 padding-right: 0.5rem !important;
}
 .pr15 {
	 padding-right: 1.5rem !important;
}
 .pr2 {
	 padding-right: 1rem !important;
}
 .pr3 {
	 padding-right: 2rem !important;
}
 .pr35 {
	 padding-right: 3rem !important;
}
 .pr3-5 {
	 padding-right: 3.5rem !important;
}
 .pr4 {
	 padding-right: 4rem !important;
}
 .pl0 {
	 padding-left: 0 !important;
}
 .pl1 {
	 padding-left: 0.5rem !important;
}
 .pl15 {
	 padding-left: 1.5rem !important;
}
 .pl2 {
	 padding-left: 1rem !important;
}
 .pl3 {
	 padding-left: 2rem !important;
}
 .pl35 {
	 padding-left: 3rem !important;
}
 .pl3-5 {
	 padding-left: 3.5rem !important;
}
 .pl4 {
	 padding-left: 4rem !important;
}
 .pb0 {
	 padding-bottom: 0 !important;
}
 .pb1 {
	 padding-bottom: 0.5rem !important;
}
 .pb15 {
	 padding-bottom: 1.5rem !important;
}
 .pb2 {
	 padding-bottom: 1rem !important;
}
 .pb3 {
	 padding-bottom: 2rem !important;
}
 .pb25 {
	 padding-bottom: 2.5rem !important;
}
 .pb35 {
	 padding-bottom: 3rem !important;
}
 .pb3-5 {
	 padding-bottom: 3.5rem !important;
}
 .pb4 {
	 padding-bottom: 4rem !important;
}
 .px0 {
	 padding-left: 0 !important;
	 padding-right: 0 !important;
}
 .px1 {
	 padding-left: 0.5rem !important;
	 padding-right: 0.5rem !important;
}
 .px15 {
	 padding-left: 1.5rem !important;
	 padding-right: 1.5rem !important;
}
 .px2 {
	 padding-left: 1rem !important;
	 padding-right: 1rem !important;
}
 .px3 {
	 padding-left: 2rem !important;
	 padding-right: 2rem !important;
}
 .px35 {
	 padding-left: 3rem !important;
	 padding-right: 3rem !important;
}
 .px3-5 {
	 padding-left: 3.5rem !important;
	 padding-right: 3.5rem !important;
}
 .px4 {
	 padding-left: 4rem !important;
	 padding-right: 4rem !important;
}
 .py0 {
	 padding-top: 0 !important;
	 padding-bottom: 0 !important;
}
 .py1 {
	 padding-top: 0.5rem !important;
	 padding-bottom: 0.5rem !important;
}
 .py15 {
	 padding-top: 1.5rem !important;
	 padding-bottom: 1.5rem !important;
}
 .py2 {
	 padding-top: 1rem !important;
	 padding-bottom: 1rem !important;
}
 .py3 {
	 padding-top: 2rem !important;
	 padding-bottom: 2rem !important;
}
 .py4 {
	 padding-top: 4rem !important;
	 padding-bottom: 4rem !important;
}


/* Flex */
.flex { display: flex }

.flex-column  { flex-direction: column }

.flex-row  { flex-direction: row }

.flex-wrap    { flex-wrap: wrap }

.items-start    { align-items: flex-start }

.items-end      { align-items: flex-end }

.items-center   { align-items: center }

.items-baseline { align-items: baseline }

.items-stretch  { align-items: stretch }

.self-start    { align-self: flex-start }

.self-end      { align-self: flex-end }

.self-center   { align-self: center }

.self-baseline { align-self: baseline }

.self-stretch  { align-self: stretch }

.justify-start   { justify-content: flex-start }

.justify-end     { justify-content: flex-end }

.justify-center  { justify-content: center }

.justify-between { justify-content: space-between }

.justify-around  { justify-content: space-around }

.content-start   { align-content: flex-start }

.content-end     { align-content: flex-end }

.content-center  { align-content: center }

.content-between { align-content: space-between }

.content-around  { align-content: space-around }

.content-stretch { align-content: stretch }

.flex-auto {
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
}

.flex-1 {
  flex: 1;
}

.flex-regular {
  flex: 1 0 auto;
}

.flex-none {
  flex: 0 0 auto;
}

.order-0 { order: 0 }

.order-1 { order: 1 }

.order-2 { order: 2 }

.order-3 { order: 3 }

.order-last { order: 99999 }

.shrink-0 {
  flex-shrink:0;
}

.v-sub {
	vertical-align: sub;
}

.box-shadow-none {
	box-shadow: none !important;
}