// Google Color Palette defined: http://www.google.com/design/spec/style/color.html

// Lighten a color
@function tint($color, $percentage) {
  @return mix(#fff, $color, $percentage);
}

// Darken a color
@function shade($color, $percentage) {
  @return mix(#000, $color, $percentage);
}

/*Colors*/
$primary: #1774cc;
$primary-hover: tint($primary, 15%);
$primary-focus: tint($primary, 25%);
$gray-background: #f2f2f2;
$gray-light: #e6e6e6;
$gray-mid: #5b6b78;
$gray-dark: #313336;
$success: #30a039;
$success-hover: tint($success, 15%);
$success-focus: tint($success, 25%);
$warning: #e7741c;
$warning-hover: tint($warning, 15%);
$warning-focus: tint($warning, 25%);
$danger: #e6172c;
$danger-hover: tint($danger, 15%);
$danger-focus: tint($danger, 25%);
$white: #fff;
$black: #000;
$pink: #f81b73;
$cherry: #c40934;
$purple: #9585ed;
$deep-purple: #5d51a2;
$indigo: #0469bd;
$light-blue: #25abfd;
$teal: #04a7b0;
$green: #30a039;
$lime :#71df54;
$yellow: #fcd54e;
$orange: #ef9c23;
$blue-grey: #396070;

$blue: $primary;


$shades: (
  "black":        $black,
  "white":        $white,
  "transparent":  transparent,
);

$colors: (
  "primary": $primary,
  "primary-hover": $primary-hover,
  "primary-focus": $primary-focus,
  "gray-background": $gray-background,
  "gray-light": $gray-light,
  "gray-mid": $gray-mid,
  "gray-dark": $gray-dark,
  "success": $success,
  "success-hover": $success-hover,
  "success-focus": $success-focus,
  "warning": $warning,
  "warning-hover": $warning-hover,
  "warning-focus": $warning-focus,
  "danger": $danger,
  "danger-hover": $danger-hover,
  "danger-focus": $danger-focus,
  "black":        $black,
  "white":        $white,
  "transparent":  transparent,
  "pink": $pink,
  "cherry": $cherry,
  "purple": $purple,
  "deep-purple": $deep-purple,
  "indigo": $indigo,
  "light-blue": $light-blue,
  "teal": $teal,
  "green": $green,
  "lime": $lime,
  "yellow": $yellow,
  "orange": $orange,
  "blue-grey": $blue-grey,

  "blue": $primary
);

// 1. Colors
// ==========================================================================

$primary-color: $primary;
$primary-color-dark: $primary-hover;

$secondary-color: $blue-grey;
$success-color: $success;
$error-color: $danger;
$link-color: $primary-color;

$tertiary: $primary;

@each $color, $color_value in $colors {
  .#{$color} {
    background-color: $color_value !important;
  }
  .#{$color}-text {
    color: $color_value !important;
  }
}

@each $color, $color_value in $shades {
  .#{$color} {
    background-color: $color_value !important;
  }
  .#{$color}-text {
    color: $color_value !important;
  }
}

// Gradients
@mixin vertical-gradient($from, $to, $stop: 100%) {
  background-color: $from  !important;
  background: -moz-linear-gradient(top, $from 0%, $to $stop) !important;
  background: -webkit-linear-gradient(top, $from 0%, $to $stop) !important;
  background: -ms-linear-gradient(top, $from 0%, $to $stop) !important;
  background: linear-gradient(to bottom, $from 0%, $to $stop) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 ) !important;
}
@mixin diagonal-gradient($from, $to, $stop: 100%) {
  background-color: $from !important;
  background: -moz-linear-gradient(-45deg, $from 0%, $to $stop) !important;
  background: -webkit-linear-gradient(-45deg, $from 0%, $to $stop) !important;
  background: -ms-linear-gradient(-45deg, $from 0%, $to $stop) !important;
  background: linear-gradient(135deg, $from 0%, $to $stop) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 ) !important;
}
@mixin horizontal-gradient($from, $to, $stop: 100%) {
  background-color: $from !important;
  background: -moz-linear-gradient(left, $from 0%, $to $stop) !important;
  background: -webkit-linear-gradient(left, $from 0%, $to $stop) !important;
  background: -ms-linear-gradient(left, $from 0%, $to $stop) !important;
  background: linear-gradient(to right, $from 0%, $to $stop) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 ) !important;
}

.gradient-vertical-blue {
  @include vertical-gradient($light-blue, $indigo);
}
.gradient-diagonal-blue {
  @include diagonal-gradient($light-blue, $indigo);
}
.gradient-horizontal-blue {
  @include horizontal-gradient($indigo, $light-blue);
}

.gradient-vertical-teal {
  @include vertical-gradient($teal, $blue-grey);
}
.gradient-diagonal-teal {
  @include diagonal-gradient($teal, $blue-grey);
}
.gradient-horizontal-teal {
  @include horizontal-gradient($blue-grey, $teal);
}

.gradient-vertical-green {
  @include vertical-gradient($lime, $green);
}
.gradient-diagonal-green {
  @include diagonal-gradient($lime, $green);
}
.gradient-horizontal-green {
  @include horizontal-gradient($green, $lime);
}

.gradient-vertical-yellow {
  @include vertical-gradient($yellow, $orange);
}
.gradient-diagonal-yellow {
  @include diagonal-gradient($yellow, $orange);
}
.gradient-horizontal-yellow {
  @include horizontal-gradient($orange, $yellow);
}

.gradient-vertical-purple {
  @include vertical-gradient($purple, $deep-purple);
}
.gradient-diagonal-purple {
  @include diagonal-gradient($purple, $deep-purple);
}
.gradient-horizontal-purple {
  @include horizontal-gradient($deep-purple, $purple);
}

.gradient-vertical-pink {
  @include vertical-gradient($pink, $cherry);
}
.gradient-diagonal-pink {
  @include diagonal-gradient($pink, $cherry);
}
.gradient-horizontal-pink {
  @include horizontal-gradient($cherry, $pink);
}

.color-border {
    position: relative;
    &:after {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 0;
        content: "";
        border-left: 4px solid $primary;
    }
    &.primary-border:after         {border-color: $primary;}
    &.gray-dark-border:after       {border-color: $gray-dark;}
    &.gray-mid-border:after        {border-color: $gray-mid;}
    &.gray-light-border:after      {border-color: $gray-light;}
    &.gray-background-border:after {border-color: $gray-background;}
    &.white-border:after           {border-color: $white;}
    &.success-border:after         {border-color: $success;}
    &.warning-border:after         {border-color: $warning;}
    &.danger-border:after          {border-color: $danger;}
    &.indigo-border:after          {border-color: $indigo;}
    &.light-blue-border:after      {border-color: $light-blue;}
    &.blue-grey-border:after       {border-color: $blue-grey;}
    &.teal-border:after            {border-color: $teal;}
    &.green-border:after           {border-color: $green;}
    &.lime-border:after            {border-color: $lime;}
    &.orange-border:after          {border-color: $orange;}
    &.yellow-border:after          {border-color: $yellow;}
    &.purple-border:after          {border-color: $purple;}
    &.deep-purple-border:after     {border-color: $deep-purple;}
    &.pink-border:after            {border-color: $pink;}
    &.cherry-border:after          {border-color: $cherry;}
}